import { Timestamps } from './system';
import { Media } from './media';
import { FeedScope } from './feed';

export namespace Struct {

	export type CategoryKey =
		'events' |
		'updates' |
		'benefits' |
		'volunteer' |
		'resources';

	export type AppearanceType = 'main' | 'blue' | 'green' | 'purple' | 'purple2';

	export interface Category extends Timestamps {
		id: number,
		type: 'category' | 'channel',
		icon?: CategoryKey,
		title: string,
		tenant: null | string,
		tenant_name: string,
		image?: Media.Image,
		is_root: boolean,
		favorite: boolean,
		published: boolean,
		hide_title: boolean,
		notifications_enabled: boolean,
	}

	export interface Comment {
		id: number,
		text: string,
		avatar: string,
		user_id: string,
		deleted: boolean,
		updated: boolean,
		parent_id: number,
		first_name: string,
		updated_at: string,
		created_at: string,
		feed_record_id: string,
	}

	export interface CommentGroup extends Comment {
		replies: Comment[],
	}

	export interface GalleryImage {
		id: number,
		title: string,
		image: Media.Image,
	}

	export interface PollOption {
		id: number,
		poll_item: string,
		ls_answer_id: string,
	}

	export type PollType =
		'web_view_survey' |
		'next_interaction' |
		'post_activation' |
		'in_feed'

	export interface Address {
		id: number,
		city: string,
		room: string,
		region: string,
		street: string,
		country: string,
		building: string,
		geolocation_x: string,
		geolocation_y: string,
	}

	export type TextColor = 'dark' | 'light';

	type NotificationType =
		'new_post_in_favorite_category' |
		'new_comment_to_favorite_post' |
		'comment_to_comment'

	interface Acme {
		'acme-notification': number,
		'acme-feed-record-id': string,
	}

	interface NotificationNewPostData extends Acme {
		'acme-cropped-image': string,
		'acme-feed-record-type': FeedScope.ItemType,
		'acme-notification-type': 'new_post_in_favorite_category',
	}

	interface NotificationNewCommentData extends Acme {
		'acme-icon': string,
		'acme-name': string,
		'acme-comment-id': number,
		'acme-notification-type': 'new_comment_to_favorite_post',
	}

	interface NotificationNewReplyData extends Acme {
		'acme-icon': string,
		'acme-name': string,
		'acme-comment-id': number,
		'acme-parent-comment-id': number,
		'acme-notification-type': 'comment_to_comment',
	}

	interface NotificationCommon {
		id: number,
		data: string,
		user_id: string,
		is_read: boolean,
		created_at: number,
		updated_at: number,
		reference_id: string,
		notification_type: NotificationType,
	}

	interface NotificationNewPost extends NotificationCommon {
		acme_data: NotificationNewPostData,
		notification_type: 'new_post_in_favorite_category',
	}

	interface NotificationNewComment extends NotificationCommon {
		acme_data: NotificationNewCommentData,
		notification_type: 'new_comment_to_favorite_post',
	}

	interface NotificationNewReply extends NotificationCommon {
		acme_data: NotificationNewReplyData,
		notification_type: 'comment_to_comment',
	}

	export type Notification =
		NotificationNewPost |
		NotificationNewComment |
		NotificationNewReply

	export interface Tenant {
		id: number,
		name: string,
		owner_id: string,
		owner_email: string,
		createdAt: string,
		updatedAt: string,
		invite_code: number,
		matrixSpaceID: string,
	}

}
