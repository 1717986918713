import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import { dateUtils, Struct, FeedScope, notification } from '~/core';
import { getPath } from '~/constants';

import { Avatar } from './Avatar';
import { CardImage } from './ui';

export interface NotificationProps {
	data: Struct.Notification,
}

const clickIgnoreTypes: FeedScope.ItemType[] = [];

export const Notification: React.FC<NotificationProps> = (props) => {

	const { data } = props;

	const {
		data: title,
		is_read,
		acme_data,
		created_at,
		notification_type,
	} = data;

	const { push } = useHistory();

	const [
		setNotificationRead,
	] = notification.useSetNotificationReadMutation();

	const path = useMemo<[ string, boolean ] | null>(
		() => {

			const {
				notification_type,
				acme_data: {
					'acme-feed-record-id': id,
					...acme
				},
			} = data;

			const path = getPath('item', { id });

			if (notification_type === 'new_post_in_favorite_category') {

				const type = 'acme-feed-record-type' in acme ?
					acme['acme-feed-record-type'] :
					null;

				if (!type || clickIgnoreTypes.includes(type)) {
					return null;
				}

				return [ path, false ];

			}

			return [ path, true ];

		},
		[ data ]
	);

	const onNotificationClick = useCallback(
		async () => {

			const { id, is_read } = data;

			if (!is_read) {
				await setNotificationRead([ id ]);
			}

			if (!path) {
				return;
			}

			push(path[0], { open_comments: path[1] });

		},
		[ data, path, setNotificationRead, push ]
	);

	const classNames = cn('app-notification', {
		'app-notification_unread': !is_read,
		'app-notification_clickable': !!path
	});

	return (
		<div className={classNames} onClick={onNotificationClick}>
			<div className="app-notification__icon">
				<i className={cn('read-mark', { hidden: is_read })} />
				{notification_type === 'new_post_in_favorite_category' ?
				<CardImage
					src={acme_data?.['acme-cropped-image']}
					size="small"
					hasPlayIcon={acme_data?.['acme-feed-record-type'] === 'audio-record'} /> :
				<Avatar
					src={acme_data?.['acme-icon']}
					name={acme_data?.['acme-name']} />
				}
			</div>
			<div className="app-notification__content">
				<h4 children={title} />
				<p
					title={dateUtils.format(created_at)}
					children={dateUtils.timeAgo(created_at)} />
			</div>
		</div>
	);

}
