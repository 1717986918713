import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { discover, FeedScope, OrderType, Struct } from '~/core';

import {
	Page,
	Section,
	CardImage,
	CardCategory,
	CardSimplified,
	CategoryDropdown,
	RecencyFilter,
	isNotFound,
	NotFound,
} from '../components';

export const Category: React.FC = () => {

	const { id } = useParams<{ id: string }>();

	const [ order, setOrder ] = useState<OrderType>('recent');

	const { data, error, isUninitialized, isLoading } = discover.useReadCategoryQuery({ id: + id, order });

	const renderCategory = useCallback(
		(item: Struct.Category) => <CardCategory key={item.id} data={item} forceLabel />,
		[]
	);

	const renderItem = useCallback(
		(item: FeedScope.Item) => <CardSimplified key={item.id} data={item} />,
		[]
	);

	const recencyFilter = (
		<RecencyFilter
			value={order}
			onChange={setOrder} />
	);

	if (isNotFound(error)) {
		return <NotFound />
	}

	return (
		<Page
			onGoBack
			container="category-container"
			isContentLoading={isUninitialized || isLoading}>
			<div className="category-header">
				{!!data &&
				<CategoryDropdown
					category={data.category} />
				}
				<CardImage
					src={data?.category.image?.url}
					size="big" />
				<h2>{data?.category.title}</h2>
			</div>
			<Section
				data={data?.category.children || []}
				listType="horizontal-slider"
				renderItem={renderCategory} />
			<Section
				data={data?.feed || []}
				renderItem={renderItem}
				headContent={recencyFilter} />
		</Page>
	);

}
