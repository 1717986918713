import { config } from '~/constants';
import { FILE_TYPES, Media, SubtitleType } from '../types';

export const abbrevifyName = (name: string): string =>
	name.split(' ').map((i) => i[0]).join('');

export const ucFirst = (text: string) => {
	return text.charAt(0).toUpperCase() + text.slice(1);
}

const parseURL = (url?: string): URL | null => {

	try {

		if (!url) {
			return null;
		}

		return new URL(url);

	} catch (e) {

		return null;

	}

}

const getUrlName = (url?: string): string => {

	const _url = parseURL(url);

	if (!_url) {
		return '';
	}

	return ['http:','https:'].includes(_url.protocol) ?
		_url.hostname :
		url || '';

}

const isURLValid = (string?: string): boolean => {

	if (!string) {
		return false;
	}

	let url;

	try {

		url = new URL(string);

	} catch {

		return false;

	}

	return url.protocol === 'http:' || url.protocol === 'https:';

}

const isURLWithProtocol = (url: string) => {
	return /^https?:\/\//i.test(url);
}

const isURLLocal = (url: string) => {
	return /^\/static\//i.test(url);
}

const getYoutubeId = (url?: string): string | null => {

	if (!url) {
		return null;
	}

	try {

		const {
			host,
			pathname,
			searchParams,
		} = new URL(url);

		if (/(www.)?youtube.com$/.test(host)) {
			return searchParams.get('v');
		}

		if (/youtu.be/.test(host)) {
			return pathname.slice(1);
		}

		return null;

	} catch {

		return null;

	}

}

export const objectToQuery = (input: Record<string, number | string | undefined | boolean>): string => {

	const q = Object.entries(input).reduce(
		(a, [ key, value ]) => ({
			...a,
			[key]: value?.toString()
		}),
		{}
	);

	return new URLSearchParams(q).toString();

}

const getAssetURL = (src?: string) => {

	if (!src) {
		return '';
	}

	if (
		!/^(\/uploads\/|\/photos\/|\/subtitles\/)/.test(src)
		// stringUtils.url.isLocal(src) ||
		// stringUtils.url.isWithProtocol(src)
	) {
		return src;
	}

	return `${config.assetsURL}${src}`;

}

export const parsePadding = (val: string) => {

	const _val = val.split(' ').map((i) => + i.split('px')[0]);

	let map: number[] = [];

	switch (_val.length) {
		case 1: map = [ _val[0], _val[0], _val[0], _val[0] ];
			break;
		case 2: map = [ _val[0], _val[1], _val[0], _val[1] ];
			break;
		case 3: map = [ _val[0], _val[1], _val[2], _val[1] ];
			break;
		case 4: map = _val;
			break;
	}

	return {
		top: map[0],
		right: map[1],
		bottom: map[2],
		left: map[3],
	};

}

const getURLExtension = (url?: string): string => {

	if (!url) {
		return '';
	}

	const [
		,
		,
		fileExtension = '',
	] = url.match(/([/.\w]+)([.][\w]+)([?][\w./=]+)?/i) || [];

	return fileExtension.substring(1);

}

const getSubtitles = (urls: (string | undefined)[]): Media.Subtitle[] => {

	const subtitles: Media.Subtitle[] = urls
		.filter((url) => !!url)
		.map(
			(url) => {

				let extension = getURLExtension(url) as keyof typeof SubtitleType;
				if (!SubtitleType[extension]) {
					extension = Object.values(SubtitleType)[0] as keyof typeof SubtitleType;
				}

				return {
					uri: getAssetURL(url),
					type: SubtitleType[extension],
					language: 'en',
				};

			},
		);

	return subtitles;

}

const formatHebrew = (text: string) => {

	const hasHebrew = new RegExp('^[\u0590-\u05FF]+$');
	const arr = text.split(' ');

	let sentence = '';

	for (const word of arr) {
		let isHebrew = hasHebrew.test(word);

		if (isHebrew) {
			sentence = word.split('').reverse().join('') + ' ' + sentence;
		}
		else {
			sentence = sentence + word + ' ';
		}
	}

	return text;

}

const isHebrew = (val?: string) => {

	return val ?
		(/[\u0590-\u05FF]/).test(val) :
		false;

}

const reverse = (val: string) => {
	return val.split('').reverse().join('');
}

const parseExtension = (string: string): [ Media.Type, string ] => {
	const regex = new RegExp(`^(${FILE_TYPES.join('|')})\/(.*)$`, 'i');
	const [ , type = '', extension = '' ] = string.match(regex) || [];
	return (FILE_TYPES.includes(type as Media.Type) ? [ type, extension ] : [ 'unknown', '' ]) as [ Media.Type, string ];
}

const isURLExternal = (url?: string) => {

	if (!url) return false;

	return /^((https?|[a-z0-9]{0,50}):\/\/|(mailto|tel|calto|sms):)/.test(url);

}

export const stringUtils = {
	abbrevifyName,
	getSubtitles,
	formatHebrew,
	isHebrew,
	ucFirst,
	parsePadding,
	reverse,
	parseExtension,
	url: {
		parse: parseURL,
		isValid: isURLValid,
		isLocal: isURLLocal,
		isExternal: isURLExternal,
		isWithProtocol: isURLWithProtocol,
		getName: getUrlName,
		getAssetURL,
		getYoutubeId,
		objectToQuery,
	},
};
