import { FormEventHandler, useCallback, useMemo, useRef, useState } from 'react';
import cn from 'classnames';

import { lang } from '~/constants';
import { comment, FormAction, useAuth, useComments } from '~/core';

import { Avatar } from '../Avatar';
import { IconButton } from '../IconButton';
import { Input } from '../Input';
import { Loader } from '../Loader';
import { Modal } from '../Modal';
import { CommentGroup } from './CommentGroup';
import { MoreButton } from './MoreButton';
import { TextButton } from '../TextButton';

export interface ModalCommentsProps {
	feedId?: string,
	onHide: () => void,
	isVisible: boolean,
}

export const ModalComments: React.FC<ModalCommentsProps> = (props) => {

	const { feedId, onHide, isVisible } = props;

	const inputRef = useRef<HTMLInputElement>(null);

	const commentsRef = useRef<HTMLDivElement>(null);

	const { account } = useAuth();

	const { comments, isLoading, more, loadMore, isLoadingMore } = useComments(feedId);

	const [ text, setText ] = useState('');

	const [ formAction, setFormAction ] = useState<FormAction | undefined>(undefined);

	const [
		createComment,
		{ isLoading: isCreating }
	] = comment.useCreateCommentMutation();

	const [
		updateComment,
		{ isLoading: isUpdating }
	] = comment.useUpdateCommentMutation();

	const [
		deleteComment,
		// { isLoading: isDeleting }
	] = comment.useDeleteCommentMutation();

	const onFormAction = useCallback(
		(action: FormAction) => {

			if (!feedId) {
				return;
			}

			if (action.type === 'delete') {
				return void deleteComment({
					id: action.comment.id,
					feed_record_id: feedId,
				});
			}

			if (action.type === 'edit') {
				setText(action.comment.text);
			}

			setFormAction(action);

		},
		[ deleteComment, feedId ]
	);

	const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
		async (e) => {

			e.preventDefault();

			if (!feedId) {
				return;
			}

			if (!text) {
				return void inputRef.current?.focus();
			}

			try {

				const data = await (formAction?.type === 'edit' ?
					updateComment({
						id: formAction.comment.id,
						text,
						feed_record_id: feedId,
					}) :
					createComment({
						text,
						parent_id: formAction?.comment.id,
						feed_record_id: feedId,
					})
				)

				if ('error' in data) {
					// TODO: Error handling
					console.warn(data.error);
					return;
				}

				commentsRef.current?.scrollTo({
					top: formAction?.commentOffset || 0,
					left: 0,
				});

				setText('');

				setFormAction(undefined);

			} catch (e) {
				// TODO: Error handling
				console.warn(e);
			}

		},
		[
			text,
			feedId,
			formAction,
			commentsRef,
			createComment,
			updateComment,
		]
	);

	const [ placeholder, cancelText ] = useMemo(
		() => {

			switch (formAction?.type) {
				case 'edit':
					return [
						lang.commentInputEdit,
						lang.commentCancelEdit
					];
				case 'reply':
					return [
						lang.commentInputReply(formAction.comment.first_name),
						lang.commentCancelReply
					];
				default:
					return [
						lang.commentInputText,
						''
					];
			}

		},
		[ formAction ],
	);

	const Comments = useMemo(
		() => {
			return comments.map((comment) => (
				<CommentGroup
					key={comment.id}
					userId={account.id}
					comment={comment}
					onUserAction={onFormAction} />
			));
		},
		[ comments, account, onFormAction ]
	);

	return (
		<Modal
			onHide={onHide}
			visible={isVisible}
			className="comments-modal">
			<div className="comments-modal__head">
				<p className="comments-modal__head-title">{lang.commentsTitle}</p>
				<IconButton
					icon="cross"
					onClick={onHide} />
			</div>
			<div
				ref={commentsRef}
				className="comments-modal__body">
				<Loader isGlobal isPrimary isLoading={isLoading} />
				{Comments}
				{!comments.length &&
				<p className="comments-modal__body-empty">{lang.commentsEmpty}</p>
				}
				<MoreButton
					hasMore={more}
					onClick={loadMore}
					isLoading={isLoadingMore} />
			</div>
			<form
				onSubmit={onSubmit}
				className={cn('comments-modal__form', { 'comments-modal__form_action': !!formAction })}>
				<Avatar
					src={account.avatar}
					name={`${account.name} ${account.last_name}`} />
				<TextButton
					type="button"
					onClick={() => setFormAction(undefined)}
					children={cancelText}
					isPrimary />
				<Input
					ref={inputRef}
					value={text}
					disabled={isLoading}
					placeholder={placeholder}
					onChangeText={setText} />
				<IconButton
					icon="plane"
					isLoading={isCreating || isUpdating || isLoading} />
			</form>
		</Modal>
	);

}
