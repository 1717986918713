import dayjs from 'dayjs';
import { createEvent, convertTimestampToArray, EventAttributes } from 'ics-transp';

import { FeedScope } from '~/core';

export function downloadFile (name: string, content: string) {

	const file = new File(
		[content],
		name,
		{ type: 'text/calendar', }
	);

	const url = URL.createObjectURL(file);

	const anchor = document.createElement('a');

	anchor.href = url;
	anchor.download = name;

	document.body.appendChild(anchor);
	anchor.click();
	document.body.removeChild(anchor);

	URL.revokeObjectURL(url);

}

export function eventToCalendar (item: FeedScope.ItemEvent) {

	const {
		id: uid,
		object: {
			title,
			address,
			subtitle: description,
			event_date,
			event_end_at,
		},
	} = item;

	try {

		console.log(item);

		const start = convertTimestampToArray(
			dayjs(event_date).unix() * 1000,
			'number'
		);

		const end = convertTimestampToArray(
			dayjs(event_end_at).unix() * 1000,
			'number'
		);

		const location: EventAttributes['location'] = address ?
			[
				address.country,
				address.region,
				address.city,
				address.street,
				address.building,
				address.room,
			].filter((val) => !!val).join(', ') :
			undefined;

		const geo: EventAttributes['geo'] = address ?
			{
				lat: Number(address.geolocation_y) || 0,
				lon: Number(address.geolocation_x) || 0,
			} :
			undefined;

		const event = createEvent({
			description,
			location,
			title,
			start,
			end,
			geo,
			uid,
		});

		if (!event.value) {
			throw new Error(event.error?.message || 'Unable to create calendar file');
		}

		downloadFile('Event.ics', event.value);

	} catch (error) {

		console.error(error);

	}

}

export const Calendar = {
	eventToCalendar,
}
