import Cookies from 'universal-cookie';

const keyName = 'signedOut';
const keyValue = 'yes';

type Action = 'set' | 'unset' | 'check'

const cookies = new Cookies();

export async function hasSignedOut (action: Action = 'check'): Promise<boolean> {

	try {

		switch (action) {
			case 'set':
				cookies.set(keyName, keyValue, { path: '/' });
				return true;
			case 'unset':
				cookies.remove(keyName);
				return true;
			case 'check':
				return keyValue === await cookies.get(keyName);
		}

	} catch {

		return false;

	}

}
