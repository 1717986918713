import { generatePath } from 'react-router-dom';
import { AppRoute, AuthState } from '~/types';
import { Frame } from '../components';

import * as Screens from '../screens';

export const ROUTES = {
	feed: '/',
	item: '/item/:id',
	auth: '/auth/:token?',
	login: '/login',
	library: '/library',
	profile: '/profile',
	register: '/register',
	discover: '/discover',
	category: '/category/:id',
	organizer: '/organizer',
	notifications: '/notifications',
	createWorkspace: '/create-workspace',
};

export const routes: AppRoute[] = [
	{
		path: ROUTES.feed,
		exact: true,
		frame: Frame,
		component: Screens.Feed,
	},
	{
		path: ROUTES.discover,
		frame: Frame,
		component: Screens.Discover,
	},
	{
		path: ROUTES.library,
		frame: Frame,
		component: Screens.Library,
	},
	{
		path: ROUTES.notifications,
		frame: Frame,
		component: Screens.Notifications,
	},
	{
		path: ROUTES.profile,
		frame: Frame,
		component: Screens.Profile,
	},
	{
		path: ROUTES.category,
		frame: Frame,
		component: Screens.Category,
	},
	{
		path: ROUTES.organizer,
		frame: Frame,
		component: Screens.Organizer,
	},
	{
		path: ROUTES.item,
		frame: Frame,
		component: Screens.Item,
	},
	{
		path: ROUTES.auth,
		auth: AuthState.No,
		component: Screens.AuthPage,
	},
	{
		path: ROUTES.login,
		auth: AuthState.No,
		component: Screens.Login,
	},
	{
		path: ROUTES.register,
		auth: AuthState.No,
		component: Screens.Register,
	},
	{
		path: ROUTES.createWorkspace,
		auth: AuthState.Any,
		component: Screens.CreateWorkspace,
	},
];

export type RouteKey = keyof typeof ROUTES;
export type RouteParams = {
	[x: string]: string | number | boolean | undefined;
}

export const getPath = (
	route: RouteKey,
	params: RouteParams = {},
	prependURL?: boolean
) => {

	let url: string = prependURL ?
		window.location.origin :
		'';

	return `${url}${generatePath(ROUTES[route], params)}`;

}

export const menuItems = (logout: () => void) => {
	return [
		[
			null,
			[
				[ ROUTES.feed, 'menuHome' ],
				[ ROUTES.discover, 'menuDiscover' ],
				[ ROUTES.library, 'menuLibrary' ],
				[ ROUTES.notifications, 'menuNotifications' ],
				[ ROUTES.profile, 'menuProfile' ],
			],
		],
		[
			'menuAdmin',
			[
				[ undefined, 'menuAdminContent', true ],
				[ undefined, 'menuAdminUsers', true ],
				[ undefined, 'menuAdminAnalytics', true ],
			],
		],
		[
			null,
			[
				[ logout, 'menuLogout' ],
			],
		],
	] as const;
}
