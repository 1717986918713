import { useCallback, useState } from 'react';
import Cropper, { CropperProps } from 'react-easy-crop';
import Slider from 'react-slider';

import { Area, ImageDetails } from '../../services';
import { IconButton } from '../IconButton';

export interface CropProps {
	image: ImageDetails,
	onComplete?: (area: Area) => void,
}

export const CROP_SIZE = {
	width: 176,
	height: 176,
};

const ZOOM_MIN = 1;
const ZOOM_MAX = 3;
const ZOOM_STEP = 0.01;

const getZoomBoundary = (
	zoom: number,
	val: number
) => {

	let newVal = zoom + val;

	if (newVal < ZOOM_MIN) {
		newVal = ZOOM_MIN;
	}

	if (newVal > ZOOM_MAX) {
		newVal = ZOOM_MAX;
	}

	return newVal;

}

export const Crop: React.FC<CropProps> = (props) => {

	const { image, onComplete } = props;

	const [ zoom, setZoom ] = useState(1);

	const [ crop, setCrop ] = useState({ x: 0, y: 0 });

	const onZoom = useCallback(
		(val: number) => setZoom(zoom => getZoomBoundary(zoom, val)),
		[]
	);

	const onCropComplete = useCallback<
		NonNullable<CropperProps['onCropComplete']>
	>(
		(_, area) => onComplete?.(area),
		[ onComplete ]
	);

	return (
		<div className="app-crop">
			<div className="app-crop__wrap">
				<Cropper
					image={image.url}
					crop={crop}
					zoom={zoom}
					aspect={1}
					showGrid={false}
					cropSize={CROP_SIZE}
					cropShape="round"
					objectFit="vertical-cover"
					onZoomChange={setZoom}
					onCropChange={setCrop}
					onCropComplete={onCropComplete} />
			</div>
			<div className="app-crop__slider-wrap">
				<IconButton
					icon="minus"
					onClick={onZoom.bind(null, -0.1)} />
				<Slider
					min={ZOOM_MIN}
					max={ZOOM_MAX}
					step={ZOOM_STEP}
					value={zoom}
					onChange={setZoom}
					className="app-crop__slider" />
				<IconButton
					icon="plus"
					onClick={onZoom.bind(null, 0.1)} />
			</div>
		</div>
	);

}
