import { useState } from 'react';
import { HTMLIframe } from '~/types';
import { Loader } from './Loader';

export interface IframeProps extends HTMLIframe {}

export const Iframe: React.FC<IframeProps> = (props) => {

	const { ...args } = props;

	const [ isLoaded, setIsLoaded ] = useState(false);

	return (
		<div className="app-iframe">
			<Loader isGlobal isPrimary isLoading={!isLoaded} />
			<iframe {...args} title={args.title} onLoad={() => setIsLoaded(true)} />
		</div>
	);

}
