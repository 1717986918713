import { useCallback, useEffect, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useDebounce } from 'use-debounce';
import { CSSTransition } from 'react-transition-group';

import {
	FeedScope,
	Struct,
	useSearch,
	DiscoverSearchType,
	discoverSearchOptions,
} from '~/core';
import { lang } from '~/constants';

import { Section } from '../containers';
import { Label } from '../Label';
import { CardCategory } from './CardCategory';
import { CardSimplified } from './CardSimplified';
import { Container } from './Container';
import { InputSearch } from './InputSearch';
import { IconButton } from '../IconButton';

export const SearchField: React.FC = () => {

	const [ value, setValue ] = useState('');

	const [ mediaType, setMediaType ] = useState<DiscoverSearchType>('all');

	const [ isVisible, setIsVisible ] = useState(false);

	const [ q ] = useDebounce(value, 400);

	useEffect(
		() => setIsVisible(!!value),
		[ value ]
	);

	const onInputFocus = useCallback(
		() => setIsVisible(!!value),
		[ value ]
	);

	const containerRef = useOnclickOutside(
		() => setIsVisible(false),
	);

	const { data, isFetching } = useSearch({ q, media_type: mediaType });

	const renderCard = useCallback(
		(data: FeedScope.Item) => <CardSimplified key={data.id} data={data} />,
		[]
	);

	const renderChannel = useCallback(
		(data: Struct.Category) => <CardCategory key={data.id} data={data} />,
		[]
	);

	const renderCategory = useCallback(
		(data: Struct.Category) => <CardCategory key={data.id} data={data} />,
		[]
	);

	return (
		<div ref={containerRef} className="app-search-field">
			<div className="app-search-field__input">
				<InputSearch
					value={value}
					onFocus={onInputFocus}
					onChangeText={setValue} />
				<IconButton
					icon="cross"
					onClick={() => setValue('')}
					className={!!value ? 'is-visible' : ''} />
			</div>
			<CSSTransition
				in={isVisible}
				timeout={200}
				mountOnEnter
				unmountOnExit>
				<Container
					hasLoader
					isLoading={isFetching}
					className="app-search-field__list">
					<div className="app-search-field__filters">
						{discoverSearchOptions.map(([ type, text ], i) => (
						<Label
							to={() => setMediaType(type)}
							key={i}
							text={text}
							className={type === mediaType ? 'is-selected' : ''} />
						))}
					</div>
					{(
						!data.feed.length &&
						!data.channels.length &&
						!data.categories.length
					) &&
					<p className="app-search-field__nothing">{lang.searchNothingFound}</p>
					}
					<Section
						data={data.categories}
						listType="horizontal-slider"
						renderItem={renderCategory} />
					<Section
						data={data.channels}
						listType="horizontal-slider"
						renderItem={renderChannel} />
					<Section
						data={data.feed}
						renderItem={renderCard} />
				</Container>
			</CSSTransition>
		</div>
	);

}
