import { api } from '../api';
import { Communication as C } from '../../types';

import { feedDetailed } from './FeedDetailed';

const path = '/feed/comments';

export const comment = api.injectEndpoints({
	endpoints: (builder) => ({
		readComments: builder.query<C.ReadCommentsState, C.ReadCommentsInput>({
			query: ({ id, page = 0 }) => `${path}?feed_record_id=${id}&page=${page}`,
			keepUnusedDataFor: 99999999999999,
			transformResponse: (response: C.ReadCommentsOutput) => {

				const { comments, page, total_pages } = response;

				return {
					comments,
					more: page + 1 < total_pages,
				};

			},
			onQueryStarted: async (args, { dispatch, queryFulfilled }) => {

				try {

					const { data } = await queryFulfilled;

					dispatch(
						comment.util.updateQueryData(
							'readComments',
							{ id: args.id, page: 0 },
							(draft) => {
								draft.comments = args.page === 0 ?
									draft.comments :
									[ ...draft.comments, ...data.comments ];
								draft.more = data.more;
							}
						)
					);

				} catch {}

			},
		}),
		createComment: builder.mutation<C.CreateCommentOutput, C.CreateCommentInput>({
			query: (body) => ({
				url: path,
				body,
				method: 'POST',
			}),
			async onQueryStarted({ feed_record_id }, { dispatch, queryFulfilled }) {

				try {

					const { data } = await queryFulfilled;

					dispatch(
						comment.util.updateQueryData(
							'readComments',
							{ page: 0, id: feed_record_id },
							(comments) => {
								comments.comments.unshift(data.comment);
							}
						)
					);

					dispatch(
						feedDetailed.util.updateQueryData(
							'readFeedDetailed',
							feed_record_id,
							(item) => {
								item.comments_count++;
							}
						)
					);

				} catch {}

			},
		}),
		updateComment: builder.mutation<C.CreateCommentOutput, C.UpdateCommentInput>({
			query: (body) => ({
				url: path,
				body,
				method: 'PUT',
			}),
			async onQueryStarted({ id, feed_record_id }, { dispatch, queryFulfilled }) {

				try {

					const { data } = await queryFulfilled;

					dispatch(
						comment.util.updateQueryData(
							'readComments',
							{ page: 0, id: feed_record_id },
							({ comments }) => {
								const key = comments.findIndex((comment) => comment.id === id);
								comments[key] = data.comment;
							}
						)
					);

				} catch {}

			},
		}),
		deleteComment: builder.mutation<C.DeleteCommentOutput, C.DeleteCommentInput>({
			query: (body) => ({
				url: path,
				body,
				method: 'DELETE',
			}),
			async onQueryStarted({ id, feed_record_id }, { dispatch, queryFulfilled }) {

				try {

					const { data } = await queryFulfilled;

					dispatch(
						comment.util.updateQueryData(
							'readComments',
							{ page: 0, id: feed_record_id },
							({ comments }) => {
								const key = comments.findIndex((comment) => comment.id === id);
								if (data.soft_deleted) {
									comments[key].text = '';
									comments[key].deleted = true;
								} else {
									comments.splice(key, 1);
								}
							}
						)
					);

					dispatch(
						feedDetailed.util.updateQueryData(
							'readFeedDetailed',
							feed_record_id,
							(item) => {
								item.comments_count--;
							}
						)
					);

				} catch {}

			},
		}),
	}),
	overrideExisting: true,
});
