import { useEffect, useMemo, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useEscapeKeydown } from '@radix-ui/react-use-escape-keydown';

import { Struct } from '~/core';
import { Icon, IconButton, Image } from '../../components';

interface GalleryControlProps {
	onClick?: () => void,
	disabled?: boolean,
	direction?: 'prev' | 'next',
}

const GalleryControl: React.FC<GalleryControlProps> = (props) => {

	const {
		onClick,
		disabled,
		direction = 'prev',
	} = props;

	return (
		<button
			onClick={onClick}
			disabled={disabled}
			className={`app-gallery__control control_${direction}`}>
			<Icon name="leftChevron" />
		</button>
	);

}

export interface GalleryProps {
	data: Struct.GalleryImage[],
}

export const Gallery: React.FC<GalleryProps> = (props) => {

	const { data } = props;

	const [ imageKey, setImageKey ] = useState<number | undefined>(undefined);

	const closeModal = () => setImageKey(undefined);

	useEscapeKeydown(closeModal);

	useEffect(() => {

		document.body.style.overflow = imageKey !== undefined ? 'hidden' : 'auto';

		return () => {
			document.body.style.overflow = 'auto';
		}

	}, [ imageKey ]);

	const [ hasPrev, hasNext ] = useMemo(
		() => !data.length || imageKey === undefined ?
			[ false, false ] :
			[
				imageKey !== 0,
				imageKey !== data.length - 1
			] ,
		[ data, imageKey ]
	);

	return (
		<div className="app-gallery">
			{data.map(({ image, title }, i) => (
			<figure
				key={i}
				onClick={() => setImageKey(i)}
				className="app-gallery__item">
				<Image src={image.url} alt={title} width={image.width} height={image.height} />
				<figcaption>{title}</figcaption>
			</figure>
			))}
			<CSSTransition
				in={imageKey !== undefined}
				timeout={200}
				mountOnEnter
				unmountOnExit>
				<div className="app-gallery__modal">
					<IconButton
						icon="minimize"
						onClick={closeModal}
						className="app-gallery__minimize-button" />
					<GalleryControl
						onClick={() => hasPrev && setImageKey(i => i === undefined ? i : i - 1)}
						disabled={!hasPrev}
						direction="prev" />
					<GalleryControl
						onClick={() => hasNext && setImageKey(i => i === undefined ? i : i + 1)}
						disabled={!hasNext}
						direction="next" />
					{imageKey !== undefined &&
					<Image src={data[imageKey].image.url} />
					}
				</div>
			</CSSTransition>
		</div>
	);

}
