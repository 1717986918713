import { toast, ToastOptions } from 'react-toastify';
import { lang } from '~/constants';

const args: ToastOptions = {
	type: 'success',
	position: 'bottom-center',
};

export const Toast = {
	copied: () => toast(lang.toastCopied, args),

	togglePost (val: boolean) {
		toast(lang[`toast${val ? 'Unf' : 'F'}avoritePost`], args);
	},

	toggleCategory (val: boolean) {
		toast(lang[`toast${val ? 'Unf' : 'F'}avoriteCategory`], args);
	},

	toggleCategoryNotification (val: boolean) {
		toast(lang[`toast${val ? 'Unn' : 'N'}otifyCategory`], args);
	},
};
