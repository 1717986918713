import { lang } from '~/constants';
import { Container, Icon } from '../components';

export const Error: React.FC = () => {

	return (
		<div className="page-error">
			<div className="page-error__wrap">
				<Container className="page-error__container">
					<Icon name="emptyHistory" />
					<h1 children={lang.unspecifiedErrorTitle} />
					<p children={lang.unspecifiedErrorText} />
				</Container>
			</div>
		</div>
	);

}
