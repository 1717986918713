import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { stringUtils } from '~/core';
import { Loader, LoaderProps } from '../Loader';

export interface ContainerProps extends PropsWithChildren {
	className?: string,
	hasLoader?: boolean,
	isLoading?: boolean,
	loaderProps?: LoaderProps,
	onClick?: string | (() => void),
}

export const Container: React.FC<ContainerProps> = (props) => {

	const {
		className,
		hasLoader,
		isLoading,
		loaderProps,
		onClick,
		children,
	} = props;

	const args = {
		className: cn('app-container', className, {
			'app-container_clickable': typeof onClick === 'function',
		}),
		children: (
			<>
				{hasLoader &&
				<Loader
					isGlobal
					isPrimary
					isLoading={isLoading}
					{...loaderProps} />
				}
				{children}
			</>
		)
	};

	if (typeof onClick === 'string') {
		return stringUtils.url.isExternal(onClick) ?
			// eslint-disable-next-line jsx-a11y/anchor-has-content
			<a {...args} href={onClick} target="_blank" rel="noreferrer" /> :
			<Link {...args} to={onClick} />
	}

	if (typeof onClick === 'function') {
		return <div {...args} tabIndex={0} role="button" onClick={onClick} />
	}

	return <div {...args} />

}
