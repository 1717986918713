import { useMemo } from 'react';

import { stringUtils } from '../../utils';
import { Media, FeedScope, Struct } from '../../types';
import { isAsset, Transform } from '../helpers';

type UsePageContentType =
	{ type: 'pdf', data: Media.File } |
	{ type: 'page', data: string | FeedScope.Item } |
	{ type: 'webview', data: string }

interface UsePageStateType {
	id: string,
	type?: FeedScope.ItemType,
	title?: string,
	asset?: Media.Item,
	assets: Media.Item[],
	linked: (FeedScope.ItemDetailed | Media.Item)[],
	content?: UsePageContentType,
	categories?: Struct.Category[],
	articleFile?: {
		url: string,
		source: Media.File,
	},
	bannerFile?: {
		url: string,
		source: Media.File,
	},
	image?: {
		url: string,
		source: Media.Image,
		aspectRatio: number,
	},
	video?: {
		video: Media.Video | string,
		poster?: Media.Image,
		feedId: string,
	},
	gallery?: Struct.GalleryImage[],
	webview?: string,
	audio?: {
		audio: Media.Audio,
		feedId: string,
	},
}

export const useFeedPage = (item?: FeedScope.ItemDetailed | Media.Item) => {

	return useMemo<UsePageStateType>(
		() => {

			const asset = isAsset.asset(item) ? { ...item } : undefined;

			if (isAsset.asset(item)) {
				if (isAsset.video(item)) {
					item = Transform.assetVideoToItem(item);
				} else if (isAsset.audio(item)) {
					item = Transform.assetAudioToItem(item);
				} else if (isAsset.image(item)) {
					item = Transform.assetImageToItem(item);
				} else if (isAsset.file(item)) {
					item = Transform.assetFileToItem(item);
				}
			}

			if (!item) {
				return {
					id: '',
					assets: [],
					linked: [],
				};
			}

			const {
				id,
				type,
				object,
				linked_assets,
				linked_feed_items,
				categories,
			} = item;

			const resp: UsePageStateType = {
				id,
				type,
				title: ('content_title' in object && object.content_title) || object.title,
				asset,
				assets: [ ...linked_assets ],
				linked: [ ...linked_feed_items, ...linked_assets ],
				content: 'content' in object && object.content ? {
					type: 'page',
					data: object.content,
				} : undefined,
				categories,
			};

			if ('image' in object && object.image) {
				const img = object.image;
				resp.image = {
					url: img.url,
					source: img,
					aspectRatio: img.width / img.height,
				};
			}

			if (type === 'article' && object.file) {
				resp.assets.push(object.file);
			}

			if (isAsset.asset(asset) && isAsset.file(asset)) {
				resp.content = {
					type: 'pdf',
					data: asset,
				};
			}

			// if ('file' in object && type !== 'article') {
			// 	resp.content = {
			// 		type: 'pdf',
			// 		data: stringUtils.url.getAssetURL(object.file?.url),
			// 	};
			// }

			// TODO: Refactor
			// if (type === 'article' && object.file) {
			// 	const file = object.file;
			// 	resp.articleFile = {
			// 		url: config.assetsURL + file.url,
			// 		source: file,
			// 	};
			// }

			// if (type === 'banner' && object.file) {
			// 	const file = object.file;
			// 	resp.bannerFile = {
			// 		url: config.assetsURL + file.url,
			// 		source: file,
			// 	};
			// }

			if ('video' in object && object.video) {
				resp.content = {
					type: 'page',
					data: object.content || '',
				};
				resp.video = {
					video: object.video,
					poster: 'background_image' in object ? object.background_image : undefined,
					feedId: id,
				};
			}

			if (
				'url' in object &&
				object.url &&
				stringUtils.url.getYoutubeId(object.url)
			) {
				resp.video = {
					video: object.url,
					poster: 'background_image' in object ? object.background_image : undefined,
					feedId: id,
				};
			}

			if (type === 'gallery') {
				resp.content = {
					type: 'page',
					data: object.content || '',
				};
				resp.gallery = object.images;
			}

			if (
				type !== 'video' &&
				'url' in object && object.url &&
				// 'open_in_webview' in object && object.open_in_webview &&
				stringUtils.url.isValid(object.url)
			) {
				resp.content = {
					type: 'webview',
					data: object.url,
				};
			}

			if ('audio' in object && object.audio) {
				resp.content = {
					type: 'page',
					data: object.content || '',
				};
				resp.audio = {
					audio: object.audio,
					feedId: id,
				};
			}

			if (type === 'external-link') {
				const isValidWebView = object.open_in_webview &&
					stringUtils.url.isValid(object.url);
				if (isValidWebView) {
					resp.linked = [];
				}
				resp.content = isValidWebView ? {
					type: 'webview',
					data: object.url,
				} : {
					type: 'page',
					data: item,
				};
			}

			if (type === 'poll' || type === 'event') {
				resp.content = {
					type: 'page',
					data: item,
				};
			}

			if (type === 'banner') {

				if (object.file) {
					resp.content = { type: 'pdf', data: object.file, };
				} else if (object.url && object.open_in_webview && stringUtils.url.isValid(object.url)) {
					resp.content = { type: 'webview', data: object.url, };
				} else {
					resp.title = object.content_title;
					resp.content = { type: 'page', data: object.content || item };
				}

			}

			if (type === 'poll' && object.poll_type === 'web_view_survey') {
				resp.linked = [];
				resp.content = {
					type: 'webview',
					data: object.survey_url,
				};
			}

			return resp;

		},
		[ item ]
	);

}
