import { api } from '../api';
import { Communication as C } from '../../types';
import { config } from '~/constants';

export const tenant = api.injectEndpoints({
	endpoints: (build) => ({
		createTenant: build.mutation<C.CreateTenantOutput, C.CreateTenantInput>({
			query: (body) => ({
				url: '/user/tenant',
				body: JSON.stringify(body),
				method: 'POST',
			}),
		}),
		inviteUser: build.mutation<C.InviteUserOutput, C.InviteUserInput>({
			query: ({ sso_id = config.authProvider, ...body }) => ({
				url: '/user/tenant/invite',
				body: JSON.stringify({
					...body,
					sso_id: `${sso_id}`,
					dest: config.isLocal ? 'local' : undefined
				}),
				method: 'POST',
			}),
		}),
		getCategories: build.query<C.GetCategoriesOutput, void>({
			query: () => ({
				url: '/public/meta/default-tenant-categories',
			}),
		}),
		getTenantNameA: build.query<{ available: boolean, }, string>({
			query: (tenant) => ({
				url: `/public/meta/tenant-name-available?tenant=${tenant}`,
			}),
		}),
	}),
	overrideExisting: true,
});
