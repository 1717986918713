import { PropsWithChildren, useEffect } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { useEscapeKeydown } from '@radix-ui/react-use-escape-keydown';
import { CSSTransition } from 'react-transition-group';

export interface ModalProps extends PropsWithChildren {
	title?: string | string[],
	layer?: number,
	visible?: boolean,
	lockHide?: boolean,
	noPadding?: boolean,
	className?: string | string[],
	onHide?: () => void,
	setLayer?: (layer?: number) => void,
	onExited?: () => void,
}

export const Modal: React.FC<ModalProps> = (props) => {

	const {
		layer = 0,
		visible,
		children,
		className,
		onHide,
		onExited,
	} = props;

	useEscapeKeydown(() => visible && onHide?.());

	useEffect(() => {

		document.body.style.overflow = visible ? 'hidden' : 'auto';

		return () => {
			document.body.style.overflow = 'auto';
		}

	}, [ visible ]);

	const classNames = cn(
		'app-modal',
		Array.isArray(className) ? className[layer] : className,
	);

	return createPortal(
		<CSSTransition
			in={visible}
			timeout={200}
			onExited={onExited}
			mountOnEnter
			unmountOnExit>
			<div
				onClick={(e) => e.stopPropagation()}
				className={classNames}>
				<div
					children={children}
					className="app-modal__item" />
				<div
					onClick={onHide}
					className="app-modal__overlay" />
			</div>
		</CSSTransition>,
		document.querySelector('#over')!
	);

}
