import { lang } from '~/constants';
import { Page, CalendarWrap } from '../components';

export const Organizer: React.FC = () => {

	return (
		<Page
			title={lang.organizerTitle}
			onGoBack>
			<CalendarWrap />
		</Page>
	);

}
