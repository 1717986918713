import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

export type AppError = FetchBaseQueryError | SerializedError;

export const parseError = (error?: AppError) => {

	return error && 'status' in error ?
		{ message: error.data, status: error.status } :
		{ message: '', status: 0 };

}
