import dayjs from 'dayjs';
import { FeedScope } from '~/core';

export interface CardEventProps {
	event: FeedScope.ItemEvent,
}

const formatDate = (startDate: string, endDate: string) => {
	const _endDate = !!endDate && endDate !== '0001-01-01T00:00:00Z' ?
		` - ${dayjs(endDate).format('HH:mm A')}` :
		'';
	return `${dayjs(startDate).format('HH:mm A')}${_endDate}`;
}

export const CardEvent: React.FC<CardEventProps> = (props) => {

	const { event } = props;

	const { title, subtitle, event_date, event_end_at } = event.object;

	return (
		<div className="app-card-event">
			<div className="app-card-event__head">
				<i />
				<p
					className="app-card-event__date"
					children={formatDate(event_date, event_end_at)} />
			</div>
			<div className="app-card-event__body">
				<h4 children={title} />
				<h6 children={subtitle} />
			</div>
		</div>
	);

}
