import Cookies from 'universal-cookie';
import { config } from '~/constants';

const cookies = new Cookies();

export const SessionAdapter = {
	async set (key: string) {
		cookies.set(config.cookieKey, key, { path: '/' });
		return true;
	},
	async unset () {
		cookies.remove(config.cookieKey);
		return true;
	},
	async get () {
		return cookies.get(config.cookieKey);
	},
	getCookiekey () {
		return config.cookieKey;
	},
	getTokenKey () {
		return config.tokenKey;
	},
};
