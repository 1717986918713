import InfiniteScroll from 'react-infinite-scroll-component';

import { lang } from '../../constants';
import { useAuth, useFeed, dateUtils } from '~/core';

import {
	Page,
	Card,
	MoreButton,
	ModalSurveys,
	Empty,
} from '../components';

export const Feed: React.FC = () => {

	const { account } = useAuth();

	const { feed, more: hasMore, loadMore, isLoadingMore, isUninitialized, isLoading } = useFeed();

	const items = feed.map((item) => (
		<Card key={item.id} data={item} />
	));

	const EmptyComponent = (
		<Empty
			icon="emptyHistory"
			text={lang.emptyFeed}
			wrapped />
	);

	return (
		<Page
			title={`${dateUtils.getGreeting()} ${account.name}`}
			search
			className="feed-page"
			isContentLoading={isUninitialized || isLoading}>
			<InfiniteScroll
				next={loadMore}
				loader={null}
				hasMore={hasMore}
				children={items}
				dataLength={items.length} />
			{!items.length && EmptyComponent}
			<MoreButton
				hasMore={hasMore}
				onClick={loadMore}
				className="feed-more"
				isLoading={isLoadingMore}
				showEmptyText={!!items.length} />
			<ModalSurveys />
		</Page>
	);

}
