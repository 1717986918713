import cn from 'classnames';

export interface CounterProps {
	count?: number,
	isProminent?: boolean,
}

const COUNT_LIMIT = 99;

export const Counter: React.FC<CounterProps> = (props) => {

	const { count, isProminent } = props;

	if (!count) {
		return null;
	}

	const children = count > COUNT_LIMIT ?
		`${COUNT_LIMIT}+` :
		count;

	const className = cn('app-counter', {
		'app-counter_prominent': isProminent,
	});

	return <i className={className} children={children} />

}
