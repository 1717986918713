import { DiscoverSearchType } from '../store/helpers';
import { IAccount } from './account';
import { FeedScope } from './feed';
import { Struct } from './structure';
import { AuthTarget, OrderType, WithOrder, WithPages, WithPagination } from './system';

export namespace Communication {

	/* Auth
	 */
	export type ReadAuthOutput = {
		account: IAccount,
	}

	export type UserAuthInput = {
		email: string,
		from?: AuthTarget,
		provider?: number,
		redirect_path?: string,
	}

	export interface UserSignUpOutput {
		user_id: string,
		redirect_link?: string,
	}

	export interface UserSignUpInput {
		from?: AuthTarget,
		email: string,
		provider?: number,
		last_name: string,
		first_name: string,
		invite_code: string,
		redirect_path?: string,
		registration_without_tenant?: boolean,
	}

	export type GuestAuthInput = {
		tenant?: string,
	}

	export type GuestAuthOutput = {
		account: IAccount,
		session_token: string,
	}

	export type ExchangeTokenInput = {
		code: string,
	}

	export interface UploadPhotoInput {
		photo: File,
	}

	export interface UploadPhotoOutput {
		path: string,
	}

	export interface SignOutInput {
		force?: boolean,
	}


	/* Library
	 */
	export type ReadLibraryOutput = {
		total_history_pages: number,
		total_feed_pages: number,
		categories: Struct.Category[],
		history: FeedScope.Item[],
		feed: FeedScope.Item[],
	}

	export type ReadLibraryInput = {
		order: OrderType,
		page_size?: number,
	}

	export type ReadLibraryFeedOutput = {
		feed: FeedScope.Item[],
	}

	export type ReadLibraryHistoryOutput = {
		feed: FeedScope.Item[],
	}

	export type ReadLibraryFeedInput = WithPages<WithOrder>

	export type ReadLibraryHistoryInput = WithPages


	/* Notifications
	 */
	export type ReadNotificationsInput = {
		page?: number,
		include_read?: boolean,
	}

	export type ReadNotificationsOutput = WithPagination<{
		notifications: Struct.Notification[],
	}>

	export type ReadNotificationsState = {
		notifications: Struct.Notification[],
		more: boolean,
	}

	export type SetNotificationReadInput = number[]


	/* Comments
	 */
	export type ReadCommentsOutput = WithPagination<{
		comments: Struct.Comment[],
	}>

	export type ReadCommentsInput = {
		id: string,
		page?: number,
	}

	export type ReadCommentsState = {
		comments: Struct.Comment[],
		more: boolean,
	}

	export interface CreateCommentInput {
		text: string,
		parent_id?: number,
		feed_record_id: string,
	}

	export interface UpdateCommentInput {
		id: number,
		text: string,
		feed_record_id: string,
	}

	export type CreateCommentOutput = {
		comment: Struct.Comment,
	}

	export interface DeleteCommentInput {
		feed_record_id: string,
		id: number,
	}

	export interface DeleteCommentOutput {
		soft_deleted: boolean,
	}


	/* Discover
	 */
	export type ReadDiscoverState = {
		feed: FeedScope.Item[],
		more: boolean,
		channels: Struct.Category[],
		categories: Struct.Category[],
	}

	export type ReadDiscoverInput = WithPages<{}, true>

	export type ReadDiscoverOutput = WithPagination<{
		feed: FeedScope.Item[],
		channels: Struct.Category[],
		categories: Struct.Category[],
	}>

	export type ReadDiscoverSearchInput = {
		q: string,
		page?: number,
		page_size?: number,
		media_type?: DiscoverSearchType,
	}

	export type ReadOrganizerInput = {
		year: number,
		month: number,
	}

	export type ReadOrganizerOutput = {
		events: FeedScope.ItemEvent[],
	}


	/* Category
	 */
	export type ReadCategoryInput = WithOrder<{
		id: number,
	}>

	export type ReadCategoryOutput = WithPagination<{
		feed: FeedScope.Item[],
		category: Struct.Category & {
			children: Struct.Category[],
		},
	}>


	/* Feed
	 */
	export type ReadFeedOutput = WithPagination<{
		feed: FeedScope.Item[],
		pinned: FeedScope.Item[],
	}>

	export type ReadFeedInput = WithPages<{}, true>

	export type VotePollInput = {
		item_id: number,
		feed_record_id: string,
		ls_item_id: string,
	}

	export type ReadFeedState = {
		feed: FeedScope.Item[],
		more: boolean,
	}

	export type ReadRequiredPollsOutput = {
		post_activation_surveys: FeedScope.ItemPoll[],
		next_interaction_surveys: FeedScope.ItemPoll[],
	}


	/* Favorites
	 */
	export type ToggleItemInput = {
		id: string,
		status: boolean,
	}

	export type ToggleCategoryInput = {
		id: number,
		status: boolean,
		category: Struct.Category,
	}

	export type ToggleNotificationInput = {
		id: number,
		status: boolean,
	}


	/* Feed Detailed
	 */
	export type ReadFeedDetailedState = FeedScope.ItemDetailed

	export type ReadFeedDetailedInput = string

	export type ReadFeedDetailedOutput = {
		feed: FeedScope.ItemDetailed,
		favorite: boolean,
	}


	/* Tenant
	 */
	export interface CreateTenantInput {
		tenant: string,
		categories: string[],
	}

	export interface CreateTenantOutput {
		tenant: Struct.Tenant,
	}

	export interface InviteUserInput {
		tenant: string,
		emails: string[],
		sso_id?: string,
		from: AuthTarget,
		dest?: string,
	}

	export interface InviteError {
		email: string,
		error: string,
	}

	export interface InviteUserOutput {
		errors: InviteError[],
	}

	export interface GetCategoriesOutput {
		categories: string[],
	}


}
