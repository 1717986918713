import { useState } from 'react';
import { FieldError } from 'react-hook-form';
import { ClassNamesConfig, GroupBase } from 'react-select';
import ReactSelectCreatable from 'react-select/creatable';
import cn from 'classnames';

import { optifyStrings, Option } from '~/core';

type ReactSelectProps = Parameters<typeof ReactSelectCreatable>[0]

export interface SelectProps extends Omit<ReactSelectProps, 'onCreateOption'> {
	label?: string,
	error?: FieldError,
	onCreateOption?: (value: Option) => void,
}

const classNamesConfig: ClassNamesConfig<unknown, boolean, GroupBase<unknown>> = {
	option: (props) => props.isFocused ? 'focused' : '',
}

export const Select: React.FC<SelectProps> = (props) => {

	const { label, error, options, onChange, onCreateOption, ...args } = props;

	const [ value, setValue ] = useState<Option>();

	return (
		<div className={cn('app-select', { 'app-select_invalid': !!error })}>
			{!!label &&
			<div className="app-select__label" children={label} />
			}
			<ReactSelectCreatable
				{...args}
				value={value}
				options={options}
				isMulti
				onChange={onChange}
				classNames={classNamesConfig}
				isClearable />
			{!!error &&
			<div className="app-select__error-message" children={error.message} />
			}
		</div>
	);

}
