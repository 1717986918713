import { useState, useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';
import { usePopper } from 'react-popper';
import useOnclickOutside from 'react-cool-onclickoutside';
import cn from 'classnames';

import { Icon, IconProps } from './Icon';
import { Button, ButtonProps } from './Button';

interface DropDownButton extends ButtonProps {
	hidden?: boolean,
	hideOnClick?: boolean,
}

export interface DropDownProps {
	icon?: IconProps['name'],
	iconProps?: Omit<IconProps, 'name'>,
	items?: DropDownButton[],
	trigger?: ButtonProps,
	isLight?: boolean,
	isVertical?: boolean,
	isSmallIcon?: boolean,
	disabled?: boolean,
	children?: React.ReactNode,
}

export const DropDown: React.FC<DropDownProps> = (props) => {

	const {
		icon = 'dots',
		iconProps,
		items = [],
		trigger,
		disabled,
		children,
		isLight,
		isVertical,
		isSmallIcon,
	} = props;

	const [ triggerRef, setTriggerRef ] = useState<HTMLButtonElement | null>(null);
	const [ elementRef, setElementRef ] = useState<HTMLDivElement | null>(null);

	const { styles, attributes } = usePopper(triggerRef, elementRef, {
		placement: 'bottom-end',
		modifiers: [{
			name: 'offset',
			options: {
				offset: [ 0, 10 ]
			},
		}],
	});

	// const placement = attributes.popper?.['data-popper-placement'];

	const [ shown, setVisibility ] = useState(false);

	const outsideRef = useOnclickOutside(() => setVisibility(false));

	const open = (e: React.MouseEvent) => {

		e.stopPropagation();

		e.preventDefault();

		setVisibility(v => !v);

	}

	const list = useMemo(() => {

		return items
			.filter(({ hidden }) => !hidden)
			.map(({ onClick, hideOnClick, ...item }, i) => (
				<Button
					key={i}
					{...item}
					isSimple
					disabled={disabled || item.disabled}
					onClick={(e) => {

						e.stopPropagation();

						e.preventDefault();

						onClick?.(e);

						hideOnClick && setVisibility(false);

					}} />
			));

	}, [
		items,
		disabled,
	]);

	const content = children || <div children={list} />

	const className = cn(
		'app-dropdown',
		{
			'app-dropdown_light': isLight,
			'app-dropdown_vertical': isVertical,
			'app-dropdown_small-icon': isSmallIcon,
		}
	);

	return (
		<div
			ref={outsideRef}
			className={className}>
			<button
				ref={setTriggerRef}
				onClick={open}
				disabled={disabled || (!list.length && !children)}
				children={<Icon name={icon} {...iconProps} />}
				className={cn('app-dropdown__trigger', { active: shown })}
				{...trigger} />
			<CSSTransition
				in={shown}
				timeout={200}
				mountOnEnter
				unmountOnExit>
				<div className="app-dropdown__list-wrap">
					<div
						ref={setElementRef}
						style={styles.popper}
						children={content}
						className="app-dropdown__list"
						{...attributes.popper} />
				</div>
			</CSSTransition>
		</div>
	);

}
