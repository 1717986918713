import cn from 'classnames';

import { HTMLButton } from '~/types';
import { Icon, IconProps } from './Icon';
import { Loader } from './Loader';
import { Counter } from './Counter';

export interface IconButtonProps extends HTMLButton {
	icon: IconProps['name'],
	iconProps?: Omit<IconProps, 'name'>,
	counter?: number,
	counterProminent?: boolean,
	hidden?: boolean,
	isSmall?: boolean,
	isStatic?: boolean,
	isLoading?: boolean,
	isLoaderBlack?: boolean,
}

export const predefined: IconProps['name'][] = [
	'star',
	'starActive',
	'share',
	'linked',
	'comment',
];

export const IconButton: React.FC<IconButtonProps> = (props) => {

	const {
		icon,
		iconProps,
		counter,
		counterProminent,
		hidden,
		isSmall,
		isStatic,
		isLoading,
		isLoaderBlack = true,
		...args
	} = props;

	if (hidden) {
		return null;
	}

	const className = cn(
		'app-icon-button',
		args.className,
		{
			'app-icon-button_icon-small': isSmall,
			[`app-icon-button_icon-${icon}`]: predefined.includes(icon),
			[`app-icon-button_icon-loading`]: isLoading,
		},
	);

	const children = (
		<>
			<Loader isBlack={isLoaderBlack} />
			<Icon name={icon} {...iconProps} />
			<Counter count={counter} isProminent={counterProminent} />
		</>
	);

	if (isStatic) {
		return (
			<div
				children={children}
				className={className} />
		);
	}

	return (
		<button
			{...args}
			children={children}
			className={className} />
	);

}
