import { FeedScope } from '../../types';

export const allowedTypes: FeedScope.ItemType[] = [
	'article',
	'banner',
	'gallery',
	'poll',
	'audio-record',
	'external-link',
	'event',
	'video',
];

export const filterFeedItems = (items: FeedScope.Item[]) => items.filter(
	({ type }) => allowedTypes.includes(type)
);
