import { Struct } from '../../types';

export type FormActionType = 'reply' | 'edit' | 'create' | 'delete'

export interface FormAction {
	type: FormActionType,
	comment: Struct.Comment,
	commentOffset?: number,
}

export const groupReplies = (data: Struct.Comment[]): Struct.CommentGroup[] => {

	const replies = {} as Record<number, Struct.Comment[]>;

	const comments = data.filter((comment) => {

		if (comment.parent_id === 0) {
			return true;
		}

		if (!replies[comment.parent_id]) {
			replies[comment.parent_id] = [];
		}

		replies[comment.parent_id].unshift(comment);

		return false;

	});

	return comments.map((comment) => ({
		...comment,
		replies: (replies[comment.id] || []).sort((a, b) => a.id > b.id ? -1 : 1),
	}));

}
