import { Control, Controller, FieldValues, Path, PathValue, RegisterOptions, UnpackNestedValue } from 'react-hook-form';

import { lang } from '../../constants';
import { Input, InputProps } from './Input';

export interface InputCProps<T extends FieldValues> extends InputProps {
	name: Path<T>,
	rules?: RegisterOptions,
	control: Control<T>,
	defaultValue?: UnpackNestedValue<PathValue<T, Path<T>>>,
}

export const InputC = <T extends FieldValues>(props: InputCProps<T>) => {

	const { name, control, ref, defaultValue, value, ...args } = props;

	let { rules = {} } = props;

	if (args.required) {
		rules = {
			required: args.required ?
				lang.registerFieldRequired :
				undefined,
			...rules,
		}
	}

	if (args.type === 'email') {
		rules = {
			validate: (value) => /.+\@.+\..+/.test(value) ?
				true :
				lang.registerEmailValidate,
			...rules,
		}
	}

	return (
		<Controller
			name={name}
			rules={rules}
			render={({ field, fieldState }) => {
				return (
					<Input
						value={field.value || ''}
						onBlur={field.onBlur}
						isInvalid={fieldState.invalid}
						onChangeText={field.onChange}
						errorMessage={fieldState.error?.message || ''}
						{...args} />
				);
			}}
			control={control}
			defaultValue={defaultValue} />
	);

}
