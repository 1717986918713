import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api } from './api';
import { mediaSlice, contextSlice } from './modules';

export const store = configureStore({
	reducer: {
		api: api.reducer,
		media: mediaSlice.reducer,
		context: contextSlice.reducer,
	},
	middleware: (defaults) => defaults()
		.concat(api.middleware),
});

setupListeners(store.dispatch);
