import { useCallback, useEffect, useState } from 'react';
import { emptyAccount } from '../../types';
import { auth } from '../modules';

const { useLazyQuery, useQueryState } = auth.endpoints.readAuth;

export const useAuth = (shouldTrigger?: boolean) => {

	const [ trigger ] = useLazyQuery();

	const { data, error } = useQueryState();

	const [ isAuthorizing, setIsAuthorizing ] = useState(true);

	const request = useCallback(
		async () => {

			if (!shouldTrigger) {
				return setIsAuthorizing(false);
			}

			await trigger();

			setIsAuthorizing(false);

		},
		[ trigger, shouldTrigger ]
	);

	useEffect(
		() => void request(),
		[ request ]
	);

	return {
		account: data?.account || emptyAccount,
		error,
		isAuthorized: !!data?.account,
		isAuthorizing,
	};

}
