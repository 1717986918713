export const SubtitleType = {
	vtt: 'text/vtt',
	srt: 'application/x-subrip',
	ttml: 'application/ttml+xml',
} as const

export const FILE_TYPES = [
	'image',
	'audio',
	'video',
	'application',
	'unknown',
] as const;

export const FILE_TYPE_MAP = {
	image: (ext?: string) => `Image`,
	audio: (ext?: string) => `Audio`,
	video: (ext?: string) => `Video`,
	application: (ext?: string) => ext ? `${ext.toUpperCase()} file` : `Application`,
	unknown: (ext?: string) => `Unknown file type`,
};

export function isAssetCover (src?: string): Media.Type | undefined {
	return (src?.match(/^asset-(.*)$/i))?.[1] as Media.Type | undefined;
}

export namespace Media {

	export type Type = typeof FILE_TYPES[number]

	export interface Common {
		id: number,
		url: string,
		hash: string,
		size: number,
		name: string,
		cdn_id: string,
		caption: string,
		alternative_text?: string,
		created_at: string,
		updated_at: string,
	}

	export interface Thumbnail {
		ext: 'jpg',
		url: string,
		hash: string,
		name: string,
		path: string | null,
		size: number,
		width: number,
		height: number,
	}

	export interface Formats {
		thumbnail?: Thumbnail,
	}

	export interface Image extends Common {
		ext: '.png',
		mime: 'image/png',
		width: number,
		height: number,
		provider: 'local',
	}

	export interface Subtitle {
		uri: string,
		type: typeof SubtitleType[keyof typeof SubtitleType],
		title?: string,
		language: string,
	}

	export interface Video extends Common {
		ext: '.MP4',
		mime: 'video/mp4',
		formats?: Formats,
		language_code?: string,
		text_summary?: string,
		text_description?: string,
		vtt_subtitles_file?: string,
		srt_subtitles_file?: string,
	}

	export interface File extends Common {
		ext: '.pdf',
		mime: 'application/pdf',
	}

	export interface Audio extends Common {
		ext: '.mp3',
		mime: 'audio/mpeg',
		text_summary?: string,
		text_description?: string,
	}

	export type Item =
		Image |
		Video |
		File |
		Audio

}
