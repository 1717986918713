import { useCallback, useMemo, useState } from 'react';

import { lang } from '~/constants';
import { Area, crop, ImageDetails } from '../../services';
import { auth } from '~/core';

import { Avatar } from '../Avatar';
import { Button } from '../Button';
import { IconButton } from '../IconButton';
import { Modal } from '../Modal';
import { PhotoButton } from '../PhotoButton';
import { Crop, CROP_SIZE } from './Crop';

export interface ModalPhotoProps {
	name: string,
	avatar: string,
	onHide: () => void,
	isVisible: boolean,
}

const initialArea = {
	x: 0,
	y: 0,
	width: 0,
	height: 0,
}

export const ModalPhoto: React.FC<ModalPhotoProps> = (props) => {

	const { name, avatar, onHide, isVisible } = props;

	const [ image, setImage ] = useState<ImageDetails>();

	const [ isCreating, setIsCreating ] = useState(false);

	const [ uploadPhoto, { isLoading: isUploading } ] = auth.useUploadPhotoMutation();

	const [ area, setArea ] = useState<Area>(initialArea);

	const isLoading = useMemo(
		() => isCreating || isUploading,
		[ isCreating, isUploading ]
	);

	const _onHide = useCallback(
		() => !isLoading && onHide?.(),
		[ isLoading, onHide ]
	);

	const onExited = useCallback(
		() => {
			setArea(initialArea);
			setImage(undefined);
		},
		[]
	);

	const onSubmit = useCallback(
		async () => {

			if (!image) {
				return;
			}

			setIsCreating(true);

			const photo = await crop.getCroppedImg({
				src: image.url,
				area,
				name: 'photo.jpg',
				dimension: {
					width: CROP_SIZE.width * 2,
					height: CROP_SIZE.height * 2,
				},
			});

			setIsCreating(false);

			if (!photo) {
				return;
			}

			await uploadPhoto({ photo });

			onHide();

		},
		[ image, area, uploadPhoto, onHide ]
	);

	return (
		<Modal
			onHide={_onHide}
			onExited={onExited}
			visible={isVisible}
			className="modal_photo">
			<div className="modal_photo__head">
				<h1>{lang.photoModalTitle}</h1>
				<IconButton
					icon="cross"
					onClick={_onHide} />
			</div>
			<div className="modal_photo__body">
				{!!image ?
				<Crop
					image={image}
					onComplete={setArea} /> :
				<Avatar
					src={avatar}
					name={name}
					size="big"
					isGrey />
				}
			</div>
			{!!image ?
			<Button
				onClick={onSubmit}
				children={lang.photoModalButtonSave}
				isLoading={isLoading} /> :
			<PhotoButton
				children={lang.photoModalButtonUpload}
				onImageDetails={setImage} />
			}
		</Modal>
	);

}
