import {
	PropsWithChildren,
	useCallback,
	useState,
	useMemo,
	useRef,
	useEffect,
} from 'react';
import cn from 'classnames';

import { parsePadding } from '~/core';
import { Icon } from './Icon';

interface SliderListControlProps {
	dir: 0 | 1,
	onClick: (dir: 0 | 1) => void,
	isAllowed: boolean,
}

const directions = [
	[ 'left', 'arrowLeft' ],
	[ 'right', 'arrowRight' ],
] as const;

const SliderListControl: React.FC<SliderListControlProps> = (props) => {

	const { dir, onClick, isAllowed } = props;

	const [ className, icon ] = directions[dir];

	return (
		<button
			onClick={() => onClick(dir)}
			className={cn(`app-slider-list__control ${className}`, { shown: isAllowed })}>
			<i>
				<Icon name={icon} />
			</i>
		</button>
	);

}

export interface SliderListProps extends PropsWithChildren {}

type NodeKey = 'wrap' | 'track'

export const SliderList: React.FC<SliderListProps> = (props) => {

	const { children } = props;

	const wrapRef = useRef<HTMLDivElement>(null);

	const trackRef = useRef<HTMLDivElement>(null);

	const [ width, setWidth ] = useState({
		wrap: 0,
		track: 0,
	});

	const setRefSize = useCallback(
		(node: HTMLDivElement | null) => {

			if (node === null) {
				return;
			}

			const pad = parsePadding(
				window.getComputedStyle(node).getPropertyValue('padding')
			);

			setWidth((val) => ({
				...val,
				[(node.dataset.key as NodeKey)]:
					Math.floor(node.getBoundingClientRect().width - (pad.right + pad.left)),
			}));

		},
		[]
	);

	useEffect(
		() => {

			setRefSize(wrapRef.current);
			setRefSize(trackRef.current);

			function onResize (_ev: UIEvent) {
				if (!window.matchMedia('screen and (max-width: 1408px)').matches) {
					return;
				}
				setRefSize(wrapRef.current);
				setRefSize(trackRef.current);
			}

			window.addEventListener('resize', onResize);

			return () => window.removeEventListener('resize', onResize);

		},
		[ setRefSize ]
	);

	const [ page, setPage ] = useState(0);

	const [ shift, pagesCount ] = useMemo(
		() => {

			const pagesCount = (Math.ceil(width.track / width.wrap) || 0);

			const reminder = (pagesCount * width.wrap) - width.track;

			const pxVal = page * width.wrap - (page === (pagesCount - 1) ? reminder : 0);

			return [
				((pxVal) * 100) / width.track,
				pagesCount - 1
			];

		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[ width, page, children ]
	);

	const [ canGoBack, canGoNext ] = useMemo(
		() => [ page > 0, page < pagesCount ],
		[ page, pagesCount ]
	);

	const onPageChange = useCallback(
		(dir?: 0 | 1) => setPage((p) => dir === 1 ? p + 1 : p - 1),
		[]
	);

	const trackStyle = {
		transform: `translate3d(-${shift}%, 0, 0)`,
	};

	return (
		<div className="app-slider-list">
			<SliderListControl
				dir={0}
				onClick={onPageChange}
				isAllowed={canGoBack} />
			<div
				ref={wrapRef}
				data-key="wrap"
				className="app-slider-list__wrap">
				<div
					ref={trackRef}
					style={trackStyle}
					data-key="track"
					children={children}
					className="app-slider-list__track" />
			</div>
			<SliderListControl
				dir={1}
				onClick={onPageChange}
				isAllowed={canGoNext} />
		</div>
	);

}
