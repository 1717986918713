import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { FeedScope } from '../../types';
import { discover } from '../modules';

const { useLazyQuery, useQueryState } = discover.endpoints.readDiscover;

export const useDiscover = () => {

	const [ trigger ] = useLazyQuery();

	const [ page, setPage ] = useState(0);

	const [ isLoadingMore, setIsLoadingMore ] = useState(false);

	const state = useQueryState({ page: 0 }, {
		selectFromResult: ({ data, ...state }) => ({
			feed: data?.feed || [],
			more: data?.more || false,
			channels: data?.channels || [],
			categories: data?.categories || [],
			...state,
		}),
	});

	useEffect(
		() => void trigger({ page: 0 }, true),
		[ trigger ]
	);

	const refresh = useCallback(
		async () => {

			try {

				if (state.isFetching) {
					return;
				}

				await trigger({ page: 0 }, true);

				setPage(0);

			} catch {}

		},
		[ trigger, state.isFetching ]
	);

	const loadMore = useCallback(
		async () => {

			try {

				if (!state.more || state.isFetching) {
					return;
				}

				setIsLoadingMore(true);

				await trigger({ page: page + 1 }, true);

				setPage(page => page + 1);

			} catch {} finally {

				setIsLoadingMore(false);

			}

		},
		[ trigger, page, state.more, state.isFetching ]
	);

	return {
		...state,
		refresh,
		loadMore,
		isLoadingMore,
	};

	// const shouldReset = useRef(true);

	// const [ page, setPage ] = useState({ page: 0, attempt: 0 });

	// const [ isLoadingMore, setIsLoadingMore ] = useState(false);

	// const [ feed, setFeed ] = useState<FeedScope.Item[]>([]);

	// const [ trigger, result ] = discover.endpoints.readDiscover.useLazyQuery({});

	// useEffect(() => {

	// 	console.log(page);

	// 	trigger({ page: page.page }, true);

	// }, [ page ]);

	// useEffect(() => {

	// 	setIsLoadingMore(false);

	// 	if (!result.isSuccess) {
	// 		return;
	// 	}

	// 	const newData = result.data?.feed || [];

	// 	if (shouldReset.current) {
	// 		shouldReset.current = false;
	// 		setFeed(newData);
	// 		return;
	// 	}

	// 	setFeed((prevFeed) => ([
	// 		...prevFeed,
	// 		...newData,
	// 	]));

	// }, [ result.data ]);

	// const hasMore = useMemo(
	// 	() => {

	// 		const { page, total_pages } = result?.data || {
	// 			page: 0,
	// 			total_pages: 0,
	// 		};

	// 		return page + 1 < total_pages;

	// 	},
	// 	[ result.data ]
	// );

	// const loadMore = useCallback(
	// 	async () => {

	// 		shouldReset.current = false;

	// 		setIsLoadingMore(true);

	// 		setPage(({ page, attempt }) => ({ page: page + 1, attempt: attempt + 1 }));

	// 	},
	// 	[]
	// );

	// const refetch = useCallback(
	// 	() => {

	// 		shouldReset.current = true;

	// 		setPage(({ attempt }) => ({ page: 0, attempt: attempt + 1 }));

	// 	},
	// 	[]
	// );

	// return {
	// 	...result,
	// 	feed,
	// 	refetch,
	// 	hasMore,
	// 	loadMore,
	// 	isLoadingMore,
	// };

}
