import { Media } from './media';
import { Timestamps } from './system';
import { Struct } from './structure';

type FeedItemType =
	'article' |
	'banner' |
	'gallery' |
	'poll' |
	'audio-record' |
	'external-link' |
	'event' |
	'video'

/* Base */
interface FeedItemMeta extends Timestamps {
	id: string,
	type: FeedItemType,
	pinned: boolean,
	favorite: boolean,
	published: boolean,
	shareable: boolean,
	comments_count: number,
	comments_enabled: boolean,
	linked_items_count: number,
	linked_assets_count: number,
}

interface DetailedMeta extends FeedItemMeta {
	categories?: Struct.Category[],
	categories_ids?: number[],
	linked_assets: Media.Item[],
	linked_feed_items: FeedItemDetailed[],
}

/* Base */
interface FeedItemObjectMeta extends Timestamps {
	id: number,
	analyst_title: string,
	feed_record_id: string,
}

interface FeedArticleObject extends FeedItemObjectMeta {
	title: string,
	subtitle: string,
	cropped_image?: Media.Image,
	video?: Media.Video,
	external_video?: boolean,
	external_video_url?: string,
	article_with_video?: boolean,
}

interface FeedBannerObject extends FeedItemObjectMeta {
	title: string,
	subtitle: string,
	icon?: Media.Image,
	gradient?: Struct.AppearanceType,
	text_color: Struct.TextColor,
	hide_title?: boolean,
	button_text: string,
	button_color?: string,
	reverse_title: boolean,
	background_image?: Media.Image,
	content_disposition: 'left' | 'center' | 'right',
	url?: string,
	open_in_webview: boolean,
}

interface FeedGalleryObject extends FeedItemObjectMeta {
	title: string,
	images: Struct.GalleryImage[],
}

interface FeedPollObject extends FeedItemObjectMeta {
	title: string,
	gradient: Struct.AppearanceType,
	poll_type: Struct.PollType,
	poll_items: Struct.PollOption[],
	poll_completed: boolean,
	poll_answer_id: number,
	poll_answer_limesurvey_id: string,
	survey_id: string,
	survey_url: string,
}

interface FeedAudioObject extends FeedItemObjectMeta {
	title: string,
	track_name: string,
	artist_name: string,
	image: Media.Image,
	audio: Media.Audio,
	content: string,
	subtitle: string,
	content_title: string,
	cropped_image: Media.Image,
}

interface FeedLinkObject extends FeedItemObjectMeta {
	url: string,
	host: string,
	title: string,
	cropped_image: Media.Image,
	open_in_webview?: boolean,
}

interface FeedEventObject extends FeedItemObjectMeta {
	title: string,
	subtitle: string,
	icon?: Media.Image,
	gradient: Struct.AppearanceType,
	cropped_image: Media.Image,
	address?: Struct.Address,
	button_text: string,
	button_link: string,
	button_color?: string,
	event_date: string,
	event_end_at: string,
	event_active: boolean,
	expired: boolean,
	url?: string,
	text_color: Struct.TextColor,
	open_in_webview?: boolean,
}

interface FeedVideoObject extends FeedItemObjectMeta {
	title: string,
	content: string,
	url?: string,
	background_image?: Media.Image,
	cropped_image: Media.Image,
	video: Media.Video,
}

interface FeedArticle extends FeedItemMeta {
	type: 'article',
	object: FeedArticleObject,
}

interface FeedBanner extends FeedItemMeta {
	type: 'banner',
	object: FeedBannerObject,
}

interface FeedGallery extends FeedItemMeta {
	type: 'gallery',
	object: FeedGalleryObject,
}

interface FeedPoll extends FeedItemMeta {
	type: 'poll',
	object: FeedPollObject,
}

interface FeedAudio extends FeedItemMeta {
	type: 'audio-record',
	object: FeedAudioObject,
}

interface FeedLink extends FeedItemMeta {
	type: 'external-link',
	object: FeedLinkObject,
}

interface FeedEvent extends FeedItemMeta {
	type: 'event',
	object: FeedEventObject,
}

interface FeedVideo extends FeedItemMeta {
	type: 'video',
	object: FeedVideoObject,
}

type FeedItem =
	FeedArticle |
	FeedBanner |
	FeedGallery |
	FeedPoll |
	FeedAudio |
	FeedLink |
	FeedEvent |
	FeedVideo


/* Detailed
 */
interface FeedDetailedArticle extends DetailedMeta {
	type: 'article',
	object: FeedScope.ItemArticle['object'] & {
		file?: Media.File,
		image?: Media.Image,
		content?: string,
		content_title?: string,
	},
}

interface FeedDetailedBanner extends DetailedMeta {
	type: 'banner',
	object: FeedScope.ItemBanner['object'] & {
		file?: Media.File,
		image?: Media.Image,
		content?: string,
		content_title?: string,
	},
}

interface FeedDetailedGallery extends DetailedMeta {
	type: 'gallery',
	object: FeedScope.ItemGallery['object'] & {
		content?: string,
		content_title?: string,
	},
}

interface FeedDetailedPoll extends DetailedMeta {
	type: 'poll',
	object: FeedScope.ItemPoll['object'] & {},
}

interface FeedDetailedAudio extends DetailedMeta {
	type: 'audio-record',
	object: FeedScope.ItemAudio['object'] & {},
}

interface FeedDetailedLink extends DetailedMeta {
	type: 'external-link',
	object: FeedScope.ItemLink['object'] & {},
}

interface FeedDetailedEvent extends DetailedMeta {
	type: 'event',
	object: FeedScope.ItemEvent['object'] & {},
}

interface FeedDetailedPoll extends DetailedMeta {
	type: 'poll',
	object: FeedScope.ItemPoll['object'] & {},
}

interface FeedDetailedVideo extends DetailedMeta {
	type: 'video',
	object: FeedScope.ItemVideo['object'] & {},
}

type FeedItemDetailed =
	FeedDetailedArticle |
	FeedDetailedBanner |
	FeedDetailedGallery |
	FeedDetailedAudio |
	FeedDetailedLink |
	FeedDetailedEvent |
	FeedDetailedPoll |
	FeedDetailedVideo

export namespace FeedScope {

	export type ItemType = FeedItemType;
	export type ItemMeta = FeedItemMeta;

	export type Item = FeedItem;
	export type ItemArticle = FeedArticle;
	export type ItemBanner = FeedBanner;
	export type ItemGallery = FeedGallery;
	export type ItemPoll = FeedPoll;
	export type ItemAudio = FeedAudio;
	export type ItemLink = FeedLink;
	export type ItemEvent = FeedEvent;
	export type ItemVideo = FeedVideo;

	export type ItemDetailed = FeedItemDetailed;
	export type ItemDetailedArticle = FeedDetailedArticle;
	export type ItemDetailedBanner = FeedDetailedBanner;
	export type ItemDetailedGallery = FeedDetailedGallery;
	export type ItemDetailedAudio = FeedDetailedAudio;
	export type ItemDetailedLink = FeedDetailedLink;
	export type ItemDetailedEvent = FeedDetailedEvent;
	export type ItemDetailedPoll = FeedDetailedPoll;
	export type ItemDetailedVideo = FeedDetailedVideo;

}
