import { api } from '../api';
import { Communication as C } from '../../types';
import { objectToQuery } from '../../utils';

import { filterFeedItems } from '../helpers';
import { feedDetailed } from './FeedDetailed';

export const feed = api.injectEndpoints({
	endpoints: (builder) => ({
		readFeed: builder.query<C.ReadFeedState, C.ReadFeedInput>({
			query: ({ page = 0, page_size = 10 }) => `/feed?${objectToQuery({ page, page_size })}`,
			keepUnusedDataFor: 99999999999999,
			transformResponse: (response: C.ReadFeedOutput) => {

				const { pinned, feed, page, total_pages } = response;

				return {
					feed: filterFeedItems([ ...pinned, ...feed ]),
					more: page + 1 < total_pages,
				};

			},
			onQueryStarted: async (args, { dispatch, queryFulfilled }) => {

				try {

					const { data } = await queryFulfilled;

					dispatch(
						feed.util.updateQueryData(
							'readFeed',
							{ page: 0 },
							(draft) => {
								draft.feed = args.page === 0 ?
									draft.feed :
									[ ...draft.feed, ...data.feed ];
								draft.more = data.more;
							}
						)
					);

				} catch {}

			},
		}),
		votePoll: builder.mutation<void, C.VotePollInput>({
			query: (body) => ({
				url: '/feed/poll/result?poll_type=in_feed',
				body,
				method: 'POST',
			}),
			onQueryStarted: async (args, { dispatch, queryFulfilled }) => {

				try {

					await queryFulfilled;

					dispatch(
						feed.util.updateQueryData(
							'readFeed',
							{ page: 0 },
							({ feed }) => {
								const key = feed.findIndex(({ id }) => id === args.feed_record_id);
								const { type, object } = feed[key];
								if (type !== 'poll') {
									return;
								}
								object.poll_answer_id = args.item_id;
								object.poll_completed = true;
							},
						)
					);

					dispatch(
						feedDetailed.util.updateQueryData(
							'readFeedDetailed',
							args.feed_record_id,
							(draft) => {
								if (!draft || draft.type !== 'poll') {
									return;
								}
								draft.object.poll_answer_id = args.item_id;
								draft.object.poll_completed = true;
							},
						)
					);

				} catch {}

			},
		}),
		readRequiredPolls: builder.query<C.ReadRequiredPollsOutput, void>({
			query: () => ({
				url: '/feed/poll/required',
			}),
		}),
	}),
	overrideExisting: true,
});
