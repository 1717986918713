import { Link } from 'react-router-dom';

import { lang, menuItems } from '~/constants';
import { auth } from '~/core';
import { AsideMenuItem, Image } from '../../components';

import logo from '~/assets/img/logo3.png';

export const AsideMenu: React.FC = () => {

	const [ logout ] = auth.useSignOutMutation();

	const menu = menuItems(() => logout({ force: false }));

	return (
		<div className="aside-menu">
			<Link to="/" className="aside-menu__logo">
				<div className="img-wrap">
					<Image src={logo} alt="Demo" />
				</div>
			</Link>
			{menu.map(([ title, items ], i0) => (
			<div key={i0} className="aside-menu__group">
				{!!title &&
				<h4 className="aside-menu__kicker">{lang[title]}</h4>
				}
				{items.map(([ to, name, disabled ], i1) => (
				<AsideMenuItem
					to={to}
					key={i1}
					icon={name}
					label={lang[name]}
					disabled={disabled}
					exact />
				))}
			</div>
			))}
		</div>
	);

}
