import { useLocalStorage } from '@uidotdev/usehooks';

import { useAuth } from '~/core';
import { AuthLogo, AuthWrapper } from '~/base/components';

import { FormAuth } from './FormAuth';
import { FormCreate } from './FormCreate';
import { FormInvite } from './FormInvite';
import { FormFinish } from './FormFinish';

import {
	STAGES,
	STAGE_TITLE,
	STAGE_STORAGE_KEY,
} from './details';

export const CreateWorkspace: React.FC = () => {

	const { isAuthorizing, account } = useAuth();

	const [ stage, setStage ] = useLocalStorage<typeof STAGES[number]>(
		STAGE_STORAGE_KEY,
		STAGES[0]
	);

	if (isAuthorizing) {
		return null;
	}

	if (!account.id) {
		return <FormAuth />
	}

	return (
		<AuthWrapper
			className="create-workspace"
			agreeByContinue>

			<div className="page-login__head">
				<AuthLogo />
				<h1>{STAGE_TITLE[stage]}</h1>
			</div>

			{stage === STAGES[0] &&
			<FormCreate />
			}

			{stage === STAGES[1] &&
			<FormInvite />
			}

			{stage === STAGES[2] &&
			<FormFinish />
			}

		</AuthWrapper>
	);

}
