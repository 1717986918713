import { useHistory } from 'react-router-dom';

import { getPath, lang } from '~/constants';
import { FeedScope } from '~/core';
import { useItemActions } from '../../services';

import { ModalComments } from './ModalComments';
import { DropDown, DropDownProps } from '../DropDown';

export interface ItemActionsDropdownProps extends DropDownProps {
	item?: FeedScope.Item,
}

export const ItemActionsDropdown: React.FC<ItemActionsDropdownProps> = (props) => {

	const { item, ...args } = props;

	const {
		isSaved,
		isShareable,
		commentsCount,
		commentsEnabled,
		linkedCount,
		onSavedToggle,
		onClipboardCopy,
		isCommentsVisible,
		setCommentsVisibility,
	} = useItemActions(item);

	const history = useHistory();

	args.items = [
		{
			icon: `star${isSaved ? 'Active' : ''}`,
			onClick: onSavedToggle,
			children: lang[isSaved ? 'moreUnsave' : 'moreSave'],
		},
		{
			icon: 'comment',
			hidden: !commentsEnabled,
			counter: commentsCount,
			onClick: () => setCommentsVisibility(true),
			children: lang.moreComment,
			hideOnClick: true,
		},
		{
			icon: 'share',
			hidden: !isShareable,
			onClick: onClipboardCopy,
			children: lang.moreShare,
			hideOnClick: true,
		},
		{
			icon: 'linked',
			hidden: (!linkedCount) ||
				(item?.type === 'poll' && item.object.poll_type === 'web_view_survey') ||
				(item?.type === 'external-link' && item.object.open_in_webview),
			counter: linkedCount,
			onClick: () => item && history.push(getPath('item', { id: item.id }), { linked: true }),
			children: lang.moreLinked,
		},
	];

	return (
		<>
			<DropDown {...args} />
			<ModalComments
				feedId={isCommentsVisible ? item?.id : undefined}
				onHide={() => setCommentsVisibility(false)}
				isVisible={isCommentsVisible} />
		</>
	);

}
