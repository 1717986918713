import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import { App } from './App';
import { store } from '~/core';

import '~/assets/scss/index.scss';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<Provider store={store}>
		<BrowserRouter>
			<QueryParamProvider ReactRouterRoute={Route}>
				<App />
			</QueryParamProvider>
		</BrowserRouter>
	</Provider>
);
