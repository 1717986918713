import { useCallback } from 'react';
import {
	toggleMenu,
	useAppDispatch,
	useAppSelector,
} from '~/core';

export function useContext () {

	const contextState = useAppSelector(({ context }) => context);

	const dispatch = useAppDispatch();

	const menuToggle = useCallback(
		(state: boolean) => dispatch(toggleMenu(state)),
		[ dispatch ]
	);

	return {
		...contextState,
		menuToggle,
	}

}
