import cn from 'classnames';
import { useCallback, useState, ReactEventHandler, useMemo } from 'react';

import { HTMLImage } from '~/types';
import { isAssetCover, stringUtils } from '~/core';
import { Icon } from './Icon';

export interface ImageProps extends HTMLImage {}

export const Image: React.FC<ImageProps> = (props) => {

	const { src, onLoad, className, width, height, ...args } = props;

	const [ isLoaded, setIsLoaded ] = useState(false);

	const _src = useMemo(
		() => stringUtils.url.getAssetURL(src),
		[ src ]
	);

	const _onLoad = useCallback<ReactEventHandler<HTMLImageElement>>(
		(e) => {
			setIsLoaded(true);
			onLoad?.(e);
		},
		[ onLoad ]
	);

	const _className = cn(
		'app-image',
		className,
		{ 'app-image_loaded': isLoaded }
	);

	const assetType = isAssetCover(src);

	if (assetType) {
		return (
			<div className={cn(`app-image app-image_asset-thumbnail asset-${assetType}`)}>
				<div className="asset-icon">
					<Icon name={`${assetType}Asset`} />
				</div>
				<i />
			</div>
		);
	}

	if (width && height) {
		return (
			<div className="app-image_ratio-wrap">
				<img
					src={_src}
					alt=''
					onLoad={_onLoad}
					className={_className}
					{...args} />
				<i style={{ paddingTop: `${((+ height) / (+ width)) * 100}%` }} />
			</div>
		);
	}

	return (
		<img
			src={_src}
			alt=''
			onLoad={_onLoad}
			className={_className}
			{...args} />
	);

}
