import { useEffect } from 'react';
import { addNotification, useAuth } from '~/core';
import { config, routes } from '~/constants';
import { Auth, subscribeNotify } from '~/base';

export const App = () => {

	const auth = useAuth(true);

	useEffect(
		() => {

			if (auth.account.user_type !== 2) {
				return;
			}

			const unsubscribe = subscribeNotify(
				`${config.apiURL}/notifications/live`,
				({ type, payload }) => {
					switch (type) {
						case 'notification':
							addNotification(payload);
								break;
						case 'sync':
							window.location.reload();
								break;
					}
				}
			);

			return () => unsubscribe();

		},
		[ auth ]
	);

	return (
		<Auth routes={routes} {...auth} />
	);

}
