import { ChangeEventHandler, useCallback, useState } from 'react';

import { crop, ImageDetails } from '../services';

import { Button, ButtonProps } from './Button';

export interface PhotoButtonProps extends Omit<ButtonProps, 'type'> {
	type?: 'file',
	accept?: string,
	onFileChange?: (file: File) => void,
	onImageDetails?: (imageDetails: ImageDetails) => void,
}

export const PhotoButton: React.FC<PhotoButtonProps> = (props) => {

	const {
		type = 'file',
		accept = 'image/jpeg,image/png',
		onFileChange,
		onImageDetails,
		...args
	} = props;

	const [ isLoading, setIsLoading ] = useState(false);

	const onChange = useCallback<
		ChangeEventHandler<HTMLInputElement>
	>(
		async (e) => {

			const file = e.target.files?.[0];

			if (!file) {
				return;
			}

			onFileChange?.(file);

			if (onImageDetails) {

				setIsLoading(true);

				const details = await crop.getImageDetails(file);

				onImageDetails(details);

				setIsLoading(false);

			}

		},
		[ onFileChange, onImageDetails ]
	);

	return (
		<div className="app-photo-button">
			<Button isLoading={isLoading} {...args} />
			<input
				type={type}
				accept={accept}
				onChange={onChange} />
		</div>
	);

}
