import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocalStorage } from '@uidotdev/usehooks';

import { Button } from '~/base/components';
import { ROUTES } from '~/constants';

import { STAGES, STAGE_STORAGE_KEY, TENANT_DETAILS_KEY } from './details';

export const FormFinish: React.FC = () => {

	const { replace } = useHistory();

	const [ , setStage ] = useLocalStorage<typeof STAGES[number]>(
		STAGE_STORAGE_KEY,
		STAGES[0]
	);

	const [ , setTenantDetailsJson ] = useLocalStorage(TENANT_DETAILS_KEY, '');

	const onWorkspaceAccess = useCallback(
		() => {

			replace(ROUTES.feed);

			setStage(STAGES[0]);

			setTenantDetailsJson('');

		},
		[]
	);

	return (
		<>
			<p
				children="All invitations have been sent and your workspace was successfully created."
				className="mb42 page-login__sent-message" />
			<Button
				onClick={onWorkspaceAccess}
				children="Access your new workspace" />
		</>
	);

}
