import { api } from '../api';
import { Communication as C, formatOrder } from '../../types';
import { objectToQuery } from '../../utils';

import { getEmptyCategory, filterFeedItems } from '../helpers';

const path = '/feed/discover';

export const discover = api.injectEndpoints({
	endpoints: (builder) => ({
		readDiscover: builder.query<C.ReadDiscoverState, C.ReadDiscoverInput>({
			query: ({ page = 0, page_size = 3 }) => `${path}?${objectToQuery({ page, page_size })}`,
			keepUnusedDataFor: 99999999999999,
			transformResponse: (response: C.ReadDiscoverOutput) => {

				const { feed, channels, categories, page, total_pages } = response;

				return {
					feed: filterFeedItems([ ...feed ]),
					channels,
					categories: [ getEmptyCategory(), ...categories ],
					more: page + 1 < total_pages,
				};

			},
			onQueryStarted: async (args, { dispatch, queryFulfilled }) => {

				try {

					const { data } = await queryFulfilled;

					dispatch(
						discover.util.updateQueryData(
							'readDiscover',
							{ page: 0 },
							(draft) => {
								draft.feed = args.page === 0 ?
									draft.feed :
									[ ...draft.feed, ...data.feed ];
								draft.more = data.more;
							}
						)
					);

				} catch {}

			},
		}),
		readCategory: builder.query<C.ReadCategoryOutput, C.ReadCategoryInput>({
			query: ({ order, ...args }) => {
				return `${path}/category?${objectToQuery({ ...args, ...formatOrder(order) })}`;
			},
		}),
		readDiscoverSearch: builder.query<C.ReadDiscoverOutput, C.ReadDiscoverSearchInput>({
			query: (args) => `${path}/search-v2?${objectToQuery(args)}`,
			transformResponse: (response: C.ReadDiscoverOutput) => {
				return {
					...response,
					feed: filterFeedItems(response.feed),
				};
			},
		}),
		readOrganizer: builder.query<C.ReadOrganizerOutput, C.ReadOrganizerInput>({
			query: (args) => `${path}/organizer/get_events?${objectToQuery(args)}`,
		}),
	}),
	overrideExisting: true,
});
