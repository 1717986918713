import { Controller, FieldValues } from 'react-hook-form';

import { Select, SelectProps } from '../Select';
import { MakeControlled } from './types';

export interface SelectCProps<T extends FieldValues> extends MakeControlled<T>,
	Omit<SelectProps, 'name' | 'value' | 'defaultValue'> {}

export const SelectC = <T extends FieldValues>(props: SelectCProps<T>) => {

	const {
		name,
		rules,
		control,
		defaultValue,
		onCreateOption,
		...args
	} = props;

	return (
		<Controller
			name={name}
			rules={rules}
			render={({ field, fieldState }) => {
				return (
					<Select
						value={field.value}
						error={fieldState.error}
						onChange={field.onChange}
						{...args} />
				);
			}}
			control={control}
			defaultValue={defaultValue} />
	);

}
