import { useCallback, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { theme } from '~/constants';
import { feedDetailed, useFeedPage } from '~/core';
import { scrollTo } from '../services';

import {
	Page,
	isNotFound,
	NotFound,
	PageItem,
	PageLinked,
} from '../components';

export const Item: React.FC = () => {

	const { id } = useParams<{ id: string }>();

	const location = useLocation();

	const { data, error, isUninitialized, isLoading } = feedDetailed.useReadFeedDetailedQuery(id);

	const { content, linked } = useFeedPage(data);

	const isFrame = content?.type !== 'page' && !linked.length;

	const jumpToLinked = useCallback(
		async () => {

			const state = location.state as { linked: boolean } | undefined;

			if (state?.linked && linked.length) {
				await scrollTo(theme.PAGE_LINKED_ELEMENT, theme.PAGE_HEAD_HEIGHT);
				window.history.replaceState({}, document.title);
			}

		},
		[ location.state, linked ]
	);

	useEffect(
		() => void jumpToLinked(),
		[ jumpToLinked ]
	);

	if (isNotFound(error)) {
		return <NotFound />
	}

	return (
		<Page
			onGoBack
			isFixedHeight={isFrame}
			isContentLoading={isUninitialized || isLoading}>
			<PageItem data={data} />
			<PageLinked data={linked} />
		</Page>
	);

}
