import { useMemo } from 'react';
import { Option } from '~/core';
import { Check } from './Check';

export interface RadioGroupProps {
	label?: string,
	value?: Option,
	options?: Option[],
	onChange?: (option: Option) => void,
}

export const RadioGroup: React.FC<RadioGroupProps> = (props) => {

	const { label, value, onChange, options = [] } = props;

	const Options = useMemo(
		() => {

			return options.map((opt, i) => (
				<Check
					key={i}
					label={opt.label}
					isRadio
					isChecked={opt.value === value?.value}
					setIsChecked={onChange?.bind(null, opt)} />
			));

		},
		[ value, options, onChange ]
	);

	return (
		<div className="app-radio-group">
			<p
				children={label}
				className="app-radio-group__label" />
			<div
				children={Options}
				className="app-radio-group__options" />
		</div>
	);

}
