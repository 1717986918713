import { useCallback, useRef, useState } from 'react';

import { FormAction, Struct } from '~/core';
import { lang } from '~/constants';

import { Comment, CommentProps } from './Comment';
import { TextButton } from '../TextButton';

export interface CommentGroupProps extends CommentProps {
	userId: string,
	comment: Struct.CommentGroup,
}

export const CommentGroup: React.FC<CommentGroupProps> = (props) => {

	const {
		userId,
		comment: { replies },
		onUserAction,
	} = props;

	const [ areRepliesVisible, setRepliesVisibility ] = useState(false);

	const groupRef = useRef<HTMLDivElement>(null);

	const _onUserAction = useCallback(
		(action: FormAction) => {

			if (typeof action.commentOffset === 'undefined') {
				action.commentOffset = groupRef.current?.offsetTop;
			}

			onUserAction?.(action);

		},
		[ groupRef, onUserAction ]
	);

	return (
		<div
			ref={groupRef}
			className="app-comment__group">
			<Comment
				{...props}
				onUserAction={_onUserAction} />
			{!!replies.length &&
			<div className="app-comment__replies">
				<TextButton
					icon="reply"
					onClick={() => setRepliesVisibility(val => !val)}
					children={lang.commentRepliesCount(replies.length)}
					className="app-comment__replies-button"
					isPrimary />
				{areRepliesVisible && replies.map((comment) => (
				<Comment
					key={comment.id}
					userId={userId}
					comment={comment}
					onUserAction={_onUserAction}
					isReply />
				))
				}
			</div>
			}
		</div>
	);

}
