import cn from 'classnames';
import { isAssetCover } from '~/core';
import { Icon } from '../Icon';
import { Image, ImageProps } from '../Image';

export interface CardImageProps extends ImageProps {
	size?: 'small' | 'big',
	hasPlayIcon?: boolean,
}

export const CardImage: React.FC<CardImageProps> = (props) => {

	const { size, hasPlayIcon, ...args } = props;

	const assetType = isAssetCover(args.src);

	const classNames = cn('app-card-image', {
		[`app-card-image_${size}`]: !!size,
		[`app-card-image_asset-${assetType}`]: !!assetType,
	});

	return (
		<div className={classNames}>
			{(hasPlayIcon && !assetType) &&
			<div className="play-overlay">
				<Icon name="playBig" />
			</div>
			}
			{assetType ?
			<div className="asset-icon">
				<Icon name={`${assetType}Asset`} />
			</div> :
			<Image {...args} />
			}
		</div>
	);

}
