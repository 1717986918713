import dayjs from 'dayjs';
import dayjsUTC from 'dayjs/plugin/utc';
import dayjsLocalizedFormat from 'dayjs/plugin/localizedFormat';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';

import { lang } from '~/constants';

dayjs.extend(dayjsUTC);
dayjs.extend(dayjsLocalizedFormat);

TimeAgo.setDefaultLocale(en.locale);
TimeAgo.addLocale(en);

const INVALID_DATE = '0001-01-01T00:00:00Z';
const CALENDAR_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

const ta = new TimeAgo('en-US');

const timeAgo = (ts: number | string) =>
	ta.format(new Date(typeof ts === 'string' ? ts : ts * 1000));

const format = (date: number, format: string = 'LLL') =>
	dayjs(date * 1000).format(format);

const parse = (date: string | Date, utc?: boolean) => {

	try {

		if (typeof date === 'string') {
			date = new Date(date);
		}

		return {
			date: utc ? date.getUTCDate() : date.getDate(),
			month: utc ? date.getUTCMonth() : date.getMonth(),
			year: utc ? date.getUTCFullYear() : date.getFullYear(),
		};

	} catch {
		return { date: 0, year: 0, month: 0, };
	}

}

const parseValid = (date?: string): Date | undefined => {

	if (!date || date === INVALID_DATE) {
		return undefined;
	}

	return new Date(date);

}

const getCalendarDate = (date?: string) => {

	const _date = parseValid(date);

	if (!_date) {
		return undefined;
	}

	const __date = dayjs(_date)
		.utc()
		.format(CALENDAR_FORMAT);

	return __date;

}

const compare = (a: Date | Date[], b: Date | Date[]) => {

	const aa = Array.isArray(a) ? parse(a[0], true) : parse(a);
	const bb = Array.isArray(b) ? parse(b[0], true) : parse(b);

	return aa.date === bb.date &&
		aa.month === bb.month &&
		aa.year === bb.year;

}

const stringify = (date: Date, utc?: boolean) => {

	const aa = parse(date, utc);

	return `${aa.month + 1}/${aa.date}/${aa.year}`;

}

const isToday = (date: Date) => compare(date, new Date());

const formatBirthDay = (val: number) => {

	if (!val) {
		return '';
	}

	const { date, month, year } = parse(new Date(val * 1000));

	return `${date} ${lang.months[month]} ${year}`;

}

const getGreeting = () => {

	const hour = (new Date()).getHours();

	if (hour < 5 || hour >= 18) {
		return lang.greetingEvening;
	} else if (hour < 12) {
		return lang.greetingMorning;
	} else if (hour < 18) {
		return lang.greetingAfternoon;
	}

}

const getDateState = (
	date: Date | null,
	dateSelected: Date,
	takenDates: string[],
) => ({
	isToday: date ? isToday(date) : false,
	isSelected: date ? compare(date, dateSelected) : false,
	hasEvents: date ? takenDates.includes(stringify(date)) : false,
});

const formatEventDate = (
	{ start, end }: { start?: Date, end?: Date }
): string => {

	const format = 'M/D/YY, hh:mm A';

	const from = dayjs(start);
	const to = dayjs(end);

	const isSame = to.isSame(from, 'date');

	const result = [
		start ? from.format(format) : null,
		(start && end) ? to.format(isSame ? 'hh:mm A' : format) : null,
	];

	return result.filter(v => !!v).join(' - ');

}

const formatSeconds = (
	time = 0,
	duration = 0,
): [ string, string ] => {

	const _format = (time = 0) => {
		return [
			Math.floor(time / 60).toFixed(0),
			Math.floor(time % 60).toFixed(0).padStart(2, '0'),
		];
	}

	const [ minutes, seconds ] = _format(
		Math.min(Math.max(time, 0), duration)
	);

	const [ minutes2, seconds2 ] = _format(duration);

	return [
		`${minutes}:${seconds}`,
		`${minutes2}:${seconds2}`,
	];

}

export const dateUtils = {
	format,
	parse,
	parseValid,
	getCalendarDate,
	compare,
	timeAgo,
	stringify,
	isToday,
	formatBirthDay,
	getDateState,
	getGreeting,
	formatEventDate,
	formatSeconds,
	months: lang.months,
	days: lang.days,
};
