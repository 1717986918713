import cn from 'classnames';
import { HTMLButton } from '~/types';

import { IconProps } from './Icon';
import { Loader } from './Loader';
import { IconButton } from './IconButton';

export interface ButtonProps extends HTMLButton {
	icon?: IconProps['name'],
	iconProps?: Omit<IconProps, 'name'>,
	iconPosition?: 'left' | 'right',
	counter?: number,
	isRound?: boolean,
	isSimple?: boolean,
	isOutline?: boolean,
	isLoading?: boolean,
	pressThrough?: boolean,
	additionalContent?: React.ReactNode,
}

export const Button: React.FC<ButtonProps> = (props) => {

	const {
		type = 'button',
		icon,
		iconProps,
		iconPosition = 'left',
		counter,
		isRound,
		isSimple,
		isOutline,
		isLoading = false,
		pressThrough,
		className,
		children,
		additionalContent,
		...args
	} = props;

	const classNames = cn(
		'app-button',
		{
			'app-button_round': isRound,
			'app-button_simple': isSimple,
			'app-button_outline': isOutline,
			'app-button_loading': isLoading,
			'app-button_press-through': pressThrough,
			'app-button_icon-right': iconPosition === 'right',
		},
		className,
	);

	const _Icon = icon && (
		<IconButton
			icon={icon}
			iconProps={iconProps}
			counter={counter}
			isStatic
			isLoading={(isSimple || !children) && isLoading}
			isLoaderBlack={!!isSimple} />
	);

	return (
		<button
			type={type}
			disabled={args.disabled || isLoading}
			className={classNames}
			{...args}>
			{(!isSimple && !!children) &&
			<Loader
				isLoading={isLoading}
				isPrimary={isOutline} />
			}
			{!!_Icon && iconPosition === 'left' && _Icon}
			{!!children && <span className="app-button__text">{children}</span>}
			{!!_Icon && iconPosition === 'right' && _Icon}
			{additionalContent}
		</button>
	);

}
