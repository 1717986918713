import { Link, LinkProps } from 'react-router-dom';
import { stringUtils } from '~/core';

export interface SmartLinkProps extends Omit<LinkProps, 'to'> {
	to?: string | (() => void) | null,
	asDiv?: boolean,
}

export const SmartLink: React.FC<SmartLinkProps> = (props) => {

	const { to, asDiv, children, className, ...rest } = props;

	const args = { children, className };

	if (!to) {
		return <div {...args} />
	}

	if (typeof to === 'function') {
		return !asDiv ?
			<button {...args} type="button" onClick={to} /> :
			<div {...args} onClick={to} />
	}

	if (stringUtils.url.isExternal(to)) {
		// eslint-disable-next-line jsx-a11y/anchor-has-content
		return <a {...args} href={to} target="_blank" rel="noreferrer" />
	}

	return <Link {...args} {...rest} to={to} />

}
