export async function scrollTo (to: string, yOffset: number = 0) {

	const item = document.querySelector<HTMLDivElement>(to);

	if (!item || !('offsetTop' in item)) {
		return false;
	}

	setTimeout(() => {
		window.scrollTo(0, item.offsetTop - yOffset);
	}, 10);

	return true;

}
