import { useCallback } from 'react';
import { NavLink, useLocation, NavLinkProps, matchPath } from 'react-router-dom';
import cn from 'classnames';
import { IconButton, IconKey } from '../../components';

export interface AsideMenuItemProps extends Omit<NavLinkProps, 'to'> {
	to?: string | (() => void),
	icon: IconKey,
	label: string,
	counter?: number,
	disabled?: boolean,
}

export const AsideMenuItem: React.FC<AsideMenuItemProps> = (props) => {

	const { to = '', icon, label, counter, disabled, ...args } = props;

	const { pathname } = useLocation();

	const onClick = useCallback(
		(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {

			if (disabled) {
				return;
			}

			if (to && typeof to === 'function') {
				e.preventDefault();
				return void to();
			}

			if (pathname !== to) {
				return;
			}

			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});

		},
		[ pathname, to, disabled ]
	);

	const isActive = useCallback<
		Exclude<NavLinkProps['isActive'], undefined>
	>(
		(params, location) => {

			if (!to || typeof to === 'function') {
				return false;
			}

			if (!params) {

				let path: string[] = [];

				switch (to) {
					case '/':
						path = [];
						break;
					default:
						return false;
				}

				return !!matchPath(location.pathname, { path });

			}

			return !!matchPath(location.pathname, params);

		},
		[ to ]
	);

	return (
		<NavLink
			to={typeof to === 'function' ? '' : to}
			onClick={onClick}
			isActive={isActive}
			className={cn('aside-menu-item', { 'aside-menu-item_disabled': disabled })}
			{...args}>
			<IconButton
				icon={icon}
				counter={counter}
				counterProminent
				isStatic />
			<span className="aside-menu-item__label">{label}</span>
		</NavLink>
	);

}
