import { useCallback, MouseEventHandler } from 'react';
import cn from 'classnames';

export interface CheckProps {
	label?: string,
	isRadio?: boolean,
	isCompact?: boolean,
	isChecked?: boolean,
	setIsChecked?: MouseEventHandler<HTMLDivElement>,
}

export const Check: React.FC<CheckProps> = (props) => {

	const { label, isRadio, isCompact, isChecked, setIsChecked } = props;

	const classNames = cn(
		'app-check',
		{
			'app-check_radio': isRadio,
			'app-check_compact': isCompact,
			'app-check_checked': isChecked,
		},
	);

	const onClick = useCallback<
		MouseEventHandler<HTMLDivElement>
	>(
		(e) => {
			setIsChecked?.(e);
		},
		[ setIsChecked ]
	);

	return (
		<div
			onClick={onClick}
			className="app-check__wrap">
			<div className={classNames}>
				<i />
			</div>
			{!!label &&
			<p
				children={label}
				className="app-check__label" />
			}
		</div>
	);

}
