import { PropsWithChildren } from 'react';
import cn from 'classnames';
import { config, lang } from '~/constants';

import { IconButton } from '../IconButton';

export interface AuthWrapperProps extends PropsWithChildren {
	onSubmit?: React.FormEventHandler<HTMLFormElement>,
	className?: string,
	hasBackButton?: boolean,
	agreeByContinue?: boolean,
	onBackButtonPress?: () => void,
}

export const AuthWrapper: React.FC<AuthWrapperProps> = (props) => {

	const {
		children,
		onSubmit,
		className,
		hasBackButton,
		agreeByContinue,
		onBackButtonPress,
	} = props;

	return (
		<div className={cn('page-login', className)}>
			<div className="page-login__wrap">

				{!!hasBackButton &&
				<IconButton
					icon="arrowBack"
					onClick={onBackButtonPress} />
				}

				<form
					onSubmit={onSubmit}
					className="page-login__container">
					{children}
				</form>

				<p className="page-login__register">
					{!!agreeByContinue && <span>{lang.authByContinuing} </span>}
					<a href={config.policyURL} target="_blank" rel="noreferrer">{lang.authPrivacyPolicy}</a>
					{!!agreeByContinue && <span> {lang.authByContinuingAnd} </span>}
					<a href={config.policyURL} target="_blank" rel="noreferrer">{lang.authTermsConditions}</a>
				</p>

			</div>
		</div>
	);

}
