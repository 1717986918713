import cn from 'classnames';
import { Icon, IconProps } from './Icon';

export interface PlayerButtonProps {
	isBig?: boolean,
	isRewind?: number,
	isPlaying?: boolean,
	className?: string,
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
	onRewind?: (val: number) => void,
}

export const PlayerButton: React.FC<PlayerButtonProps> = (props) => {

	const { isBig, isRewind, isPlaying, className, onClick, onRewind } = props;

	const _isRewind = typeof isRewind !== 'undefined';

	const icon: IconProps['name'] = _isRewind ?
		`rewind${isRewind < 0 ? 'Left' : 'Right'}` :
		`shape${isPlaying ? 'Pause' : 'Play'}`;

	const _onClick = _isRewind ?
		onRewind?.bind(null, isRewind) :
		onClick;

	const classNames = cn(
		'app-player-button',
		className,
		{
			'_big': isBig,
			'_shape': !_isRewind,
			'_play': !_isRewind && !isPlaying,
		},
	);

	return (
		<button onClick={_onClick} className={classNames}>
			<Icon
				name={icon} />
			{_isRewind &&
			<p className="app-player-button__text">{Math.abs(isRewind)}</p>
			}
		</button>
	);

}
