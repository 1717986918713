import { Link, LinkProps } from 'react-router-dom';
import cn from 'classnames';
import { lang } from '~/constants';
import { Icon, IconKey } from './Icon';

export interface LabelProps extends Omit<LinkProps, 'to'> {
	to?: string | (() => void),
	text?: string,
	icon?: IconKey,
	isLive?: boolean,
	isBadge?: boolean,
	className?: string,
}

export const Label: React.FC<LabelProps> = (props) => {

	const { to, text, icon, isLive, isBadge, className } = props;

	const args = {
		className: cn(
			'app-label',
			{
				'app-label_is-live': isLive,
				'app-label_is-badge': isBadge,
			},
			className
		),
	};

	const children = (
		<>
			{isLive && <i className="app-live-dot" />}
			{(!!icon && !isLive) && <Icon className="app-label__icon" name={icon} />}
			<span className="app-label__text">{isLive ? lang.eventLive : text}</span>
		</>
	);

	if (typeof to === 'string') {
		return <Link {...args} to={to} children={children} />
	}

	if (typeof to === 'function') {
		return <button {...args} onClick={to} children={children} />
	}

	return <div {...args} children={children} />

}
