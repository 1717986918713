import { ReactNode, PropsWithChildren } from 'react';
import cn from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MoreButton } from '../ui';
import { SliderList } from '../SliderList';

export interface SectionProps<T> extends PropsWithChildren {
	data?: T[],
	title?: string,
	listType?: 'vertical' | 'horizontal' | 'horizontal-slider',
	renderItem?: (item: T) => ReactNode,
	headContent?: ReactNode,
	hasMore?: boolean,
	isInfinite?: boolean,
	onMoreClick?: () => void,
	isLoadingMore?: boolean,
	isContentEmpty?: boolean,
	contentEmptyComponent?: ReactNode,
}

export const Section = <T,>(props: SectionProps<T>) => {

	const {
		data = [],
		title,
		listType = 'vertical',
		renderItem,
		headContent,
		children,
		hasMore = false,
		isInfinite,
		onMoreClick = () => null,
		isLoadingMore,
		isContentEmpty,
		contentEmptyComponent,
	} = props;

	const content = data.length && renderItem ?
		data.map((item) => renderItem(item)) :
		children;

	const hasContent =
		!!content &&
		(Array.isArray(content) && !!content.length)

	if (!hasContent && !contentEmptyComponent && !title) {
		return null;
	}

	return (
		<div className="app-section">
			{!!(title || headContent) &&
			<div className={cn('app-section__head', { 'has-side-content': !!headContent })}>
				{!!title &&
				<h2
					children={title}
					className="app-section__title" />
				}
				{headContent}
			</div>
			}
			{hasContent ?
			<div className="app-section__body">
				{listType === 'horizontal-slider' ?
				<SliderList children={content} /> :
				isInfinite ?
					<InfiniteScroll
						next={onMoreClick}
						loader={null}
						hasMore={hasMore}
						children={content}
						className={`app-list app-list_${listType}`}
						dataLength={data.length} /> :
					<div
						children={content}
						className={`app-list app-list_${listType}`} />
				}
				<MoreButton
					hasMore={hasMore}
					onClick={onMoreClick}
					isLoading={isLoadingMore} />
			</div> :
			<div
				className="app-section__empty" />
			}
			{(isContentEmpty && contentEmptyComponent) &&
			contentEmptyComponent
			}
		</div>
	);

}
