import cn from 'classnames';

import { HTMLButton } from '~/types';
import { Icon, IconProps } from './Icon';

export interface TextButtonProps extends HTMLButton {
	icon?: IconProps['name'],
	iconProps?: Omit<IconProps, 'name'>,
	isPrimary?: boolean,
}

export const TextButton: React.FC<TextButtonProps> = (props) => {

	const { children, icon, iconProps, isPrimary, ...args } = props;

	const className = cn(
		'app-text-button',
		{
			'app-text-button_primary': isPrimary,
		},
		args.className,
	)

	return (
		<button {...args} className={className}>
			{!!icon && <Icon name={icon} {...iconProps} />}
			<span children={children} />
		</button>
	);

}
