import { useCallback } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { ROUTES, config, lang } from '~/constants';
import logo from '~/assets/img/logo2.png';

import { Button, Image } from '../components';

export const AuthPage: React.FC = () => {

	const { token } = useParams<{ token: string }>();

	const onButtonClick = useCallback(
		() => {
			window.location.href = `${config.deepLinkProtocol}://events/auth/code/${token}`;
		},
		[ token ]
	);

	if (!token) {
		return <Redirect to={ROUTES.login} />
	}

	return (
		<div className="page-login">
			<div className="page-login__wrap">
				<div className="page-login__container">
					<div className="page-login__head">
						<Image src={logo} className="page-login__logo" alt={`${config.name} Logo`} />
						<h1 style={{ marginBottom: 20 }}>{lang.authWelcomeHeader} {config.name}</h1>
						<Button
							onClick={onButtonClick}
							children={`Continue to the ${config.name} app`} />
					</div>
				</div>
			</div>
		</div>
	);

}
