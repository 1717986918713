import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useLocalStorage } from '@uidotdev/usehooks';

import { lang, ROUTES } from '~/constants';
import { Button, InputC, Loader, TextButton } from '~/base/components';
import { tenant } from '~/core';
import { clipboardCopy, errorHandler } from '~/base/services';

import {
	STAGES,
	STAGE_STORAGE_KEY,
	TENANT_DETAILS_KEY,
	parseTenantDetails,
} from './details';

interface InviteWorkspaceInput {
	emails: string,
}

export const FormInvite: React.FC = () => {

	const [ , setStage ] = useLocalStorage<typeof STAGES[number]>(
		STAGE_STORAGE_KEY,
		STAGES[0]
	);

	const [ tenantDetailsJson ] = useLocalStorage(TENANT_DETAILS_KEY, '');

	const [ inviteUser, { isLoading: isInviting } ] = tenant.useInviteUserMutation();

	const tenantDetails = useMemo(
		() => parseTenantDetails(tenantDetailsJson),
		[ tenantDetailsJson ]
	);

	const {
		control,
		formState,
		setError,
		handleSubmit
	} = useForm<InviteWorkspaceInput>({
		mode: 'onChange',
		delayError: 500,
	});

	const onInviteLater = useCallback(
		() => setStage(STAGES[2]),
		[]
	);

	const onCopyLink = useCallback(
		async () => {

			const { origin } = window.location;
			const { code } = tenantDetails;

			const inviteLink = `${origin}${ROUTES.register}?inviteCode=${code}`;

			await clipboardCopy(inviteLink);

			alert('Your tenant invite link has been copied!');

		},
		[ tenantDetails ]
	);

	const onSubmit = useCallback(
		async (form: InviteWorkspaceInput) => {

			const { name } = tenantDetails;

			const emails = form.emails.trim().split(/,\s?/).map((email) => email.trim());

			const result = await inviteUser({
				from: 'web',
				emails,
				tenant: name,
			});

			if ('error' in result) {

				const { message, field } = errorHandler<InviteWorkspaceInput>(result.error);

				field ?
					setError(field, { message, }) :
					alert(message);

				return;
			}

			setStage(STAGES[2]);

		},
		[ inviteUser ]
	);

	return (
		<>
			<Loader
				isGlobal
				isPrimary
				isLoading={isInviting} />
			<InputC
				name="emails"
				label="Add list of emails separated by comma"
				rules={{
					required: lang.registerFieldRequired,
					validate: (val: string) => {

						const emails = val
							.trim()
							.split(/,\s?/)
							.map((i) => i.trim());

						const hasInvalid = emails
							.filter((i) => !!i)
							.filter((i) => !/.+\@.+\..+/.test(i));

						return hasInvalid.length ? `There are an invalid email: ${hasInvalid?.[0]}` : true;

					},
				}}
				control={control}
				placeholder="Ex: John@demo.com, Anna@demo.com" />
			<TextButton
				type="button"
				icon="link"
				onClick={onCopyLink}
				children="Copy invite link"
				className="mb18"
				isPrimary />
			<Button
				onClick={handleSubmit(onSubmit)}
				children="Submit"
				disabled={!formState.isValid || isInviting}
				className="mb18" />
			<TextButton
				type="button"
				onClick={onInviteLater}
				children="Invite later"
				className="invite-later"
				isPrimary />
		</>
	);

}
