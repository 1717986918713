import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params';
import { Link } from 'react-router-dom';
import QRCode from 'react-qr-code';
import Platforn from 'platform';

import { config, getPath, lang } from '~/constants';
import { auth, Communication as C } from '~/core';
import { Button, AuthLogo, InputC, AuthWrapper } from '../components';

const isMobile = [ 'iOS', 'Android' ].includes(Platforn.os?.family || '');

export const Register: React.FC = () => {

	const [{ inviteCode, fromApp }] = useQueryParams({
		inviteCode: StringParam,
		fromApp: BooleanParam,
	});

	const { control, formState, setError, handleSubmit } = useForm<C.UserSignUpInput>({
		mode: 'onChange',
	});

	const [ signUp, { isLoading: isSigningUp } ] = auth.useSignUpMutation();

	const [ sentTo, setSentTo ] = useState('');

	const [ sendTo, setSendTo ] = useState('');

	const submit = useCallback(
		async (form: C.UserSignUpInput) => {

			try {

				const result = await signUp({ from: isMobile ? 'app' : 'web', ...form });

				if ('error' in result) {
					if ('status' in result.error) {
						switch (result.error.status) {
							case 211:
								return setError('invite_code', { message: lang.registerErrorInvalidCode, });
							case 101:
								return setError('email', { message: lang.registerErrorInvalidEmail, });
							default:
								return alert(lang.unspecifiedErrorTitle);
						}
					}
					return;
				}

				setSentTo(form.email);
				setSendTo(result.data.redirect_link || '');

			} catch (e) {
				console.error(e);
			}

		},
		[]
	);

	return (
		<AuthWrapper
			onSubmit={handleSubmit(submit)}
			agreeByContinue={!sentTo}>

			<div className="page-login__head mb18">
				<AuthLogo />
				<h1>{sentTo ? lang.registerTitleSuccess : lang.registerTitle}</h1>
			</div>

			{sentTo ?
			<>
				{isMobile ?
				<>
					{fromApp ?
					<p className="page-login__sent-message mb42">{lang.registerTextSuccess(sentTo)}</p> :
					<>
						<p className="page-login__sent-message mb18">{lang.registerTextSuccessMobile}</p>
						<Button
							onClick={() => window.location.href = sendTo}
							children={lang.registerDownload} />
						<p className="page-login__sent-message">{lang.registerActivate(sentTo)}</p>
					</>
					}
				</> :
				<>
					<p className="page-login__sent-message mb42">{lang.registerTextSuccess(sentTo)}</p>
					<p className="page-login__or">{lang.registerDownloadQR}</p>
					<div className="page-login__qr">
						<QRCode value={sendTo} />
					</div>
					<div className="page-login__continue-web-app">
						<span>{lang.registerContinue} </span>
						<Link to={getPath('login')} children={lang.registerContinueWebApp} />
					</div>
				</>
				}
			</> :
			<>
				<InputC
					type="email"
					name="email"
					label={lang.authLabelEmail}
					control={control}
					required
					className="mb42"
					placeholder={lang.authSampleEmail}
					autoComplete="email" />
				<div className="page-login__grid mb42">
					<InputC
						name="first_name"
						label={lang.registerLabelName}
						control={control}
						required
						autoComplete="given-name" />
					<InputC
						name="last_name"
						label={lang.registerLabelLastName}
						control={control}
						required
						autoComplete="family-name" />
				</div>
				<InputC
					name="invite_code"
					label={lang.registerLabelInviteCode}
					control={control}
					required
					className="mb42"
					defaultValue={inviteCode || ''} />
				<Button
					type="submit"
					disabled={!formState.isValid}
					isLoading={isSigningUp}
					className="mb8"
					children={lang.registerSubmit} />
			</>
			}

		</AuthWrapper>
	);

}
