import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MediaState {
	currentPlayingItem: string | null,
}

const initialState: MediaState = {
	currentPlayingItem: null,
};

export const mediaSlice = createSlice({
	name: 'media',
	reducers: {
		mediaUpdate: (state, { payload }: PayloadAction<string>) => {
			state.currentPlayingItem = payload;
		},
	},
	initialState,
});

export const { mediaUpdate } = mediaSlice.actions;
