import { forwardRef, ChangeEvent, useCallback } from 'react';
import cn from 'classnames';
import { v4 } from 'uuid';
import { HTMLInput } from '~/types';
import { Icon, IconProps } from './Icon';

export interface InputProps extends HTMLInput {
	label?: string,
	icon?: IconProps['name'],
	iconProps?: Omit<IconProps, 'name'>,
	iconPosition?: 'right',
	isFilled?: boolean,
	isInvalid?: boolean,
	errorMessage?: string,
	description?: string,
	onChangeText?: (text: string) => void,
}

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {

	const {
		label,
		icon,
		iconProps,
		iconPosition,
		isFilled,
		isInvalid,
		errorMessage,
		className,
		description,
		onChange,
		onChangeText,
		...args
	} = props;

	const classNames = cn(
		'app-input',
		className,
		{
			'app-input_filled': isFilled,
			'app-input_invalid': isInvalid,
			'app-input_icon': !!icon,
			'app-input_icon-right': iconPosition === 'right',
		},
	);

	if (label && !args.id) {
		args.id = v4();
	}

	const Label = label && (
		<label htmlFor={args.id} className="app-input__label">
			{label}
		</label>
	);

	const Description = description && (
		<p className="app-input__description">{description}</p>
	);

	const ErrorMessage = errorMessage && (
		<p className="app-input__error-message">{errorMessage}</p>
	);

	const onChangeInner = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			onChange?.(e);
			onChangeText?.(e.target.value);
		},
		[ onChange, onChangeText ]
	);

	return (
		<div className={classNames}>
			{Label}
			<div className="app-input__wrap">
				{!!icon &&
				<Icon
					name={icon}
					className="app-input__icon"
					{...iconProps} />
				}
				<input
					ref={ref}
					onChange={onChangeInner}
					className="app-input__field"
					{...args} />
			</div>
			{ErrorMessage}
			{Description}
		</div>
	);

});
