import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { PDFDocumentProxy } from 'pdfjs-dist';

import { Media, stringUtils } from '~/core';

import { Loader } from './Loader';

export interface PDFProps {
	data: Media.File,
}

export const PDF: React.FC<PDFProps> = (props) => {

	const { data } = props;

	const ref = useRef<HTMLDivElement>(null);

	const [ width, setWidth ] = useState(770);

	useEffect(
		() => {

			ref.current &&
				setWidth(ref.current.getBoundingClientRect().width);

			function onResize (_ev: UIEvent) {
				if (!window.matchMedia('screen and (max-width: 1408px)').matches) {
					return;
				}
				ref.current &&
					setWidth(ref.current.getBoundingClientRect().width);
			}

			window.addEventListener('resize', onResize);

			return () => window.removeEventListener('resize', onResize);

		},
		[ ref ]
	);

	const [ isLoading, setIsLoading ] = useState(true);

	const [ pagesCount, setPagesCount ] = useState(0);

	const file = useMemo(
		() => stringUtils.url.getAssetURL(data.url),
		[ data ]
	);

	const onLoadSuccess = useCallback(
		({ _pdfInfo }: PDFDocumentProxy) => {
			setPagesCount(_pdfInfo.numPages);
			setIsLoading(false);
		},
		[]
	);

	const Pages = new Array(pagesCount).fill(null).map((_, i) => (
		<Page
			key={i}
			width={width}
			loading=""
			pageNumber={i + 1}
			renderTextLayer={false} />
	));

	return (
		<div ref={ref} className="app-pdf">
			<Loader
				isGlobal
				isPrimary
				isLoading={isLoading} />
			<Document
				file={file}
				loading=""
				children={Pages}
				onLoadSuccess={onLoadSuccess} />
		</div>
	);

}
