import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import { lang, ROUTES } from '~/constants';
import { auth } from '~/core';
import { errorHandler } from '~/base/services';
import {
	AuthLogo,
	AuthWrapper,
	Button,
	InputC,
	RadioGroup,
} from '~/base/components';

const authOptions = [
	{ value: 'yes', label: 'Yes' },
	{ value: 'no', label: 'No' },
]

interface AuthForm {
	email: string,
	first_name?: string,
	last_name?: string,
}

export const FormAuth: React.FC = () => {

	const [ signIn, { isLoading: isSigningIn } ] = auth.useUserAuthMutation();

	const [ signUp, { isLoading: isSigningUp } ] = auth.useSignUpMutation();

	const [ sentTo, setSentTo ] = useState('');

	const [ isRegistered, setIsRegistered ] = useState(authOptions[0]);

	const { control, formState, handleSubmit, setError } = useForm<AuthForm>({
		mode: 'onChange',
	});

	const onSubmit = useCallback(
		async (form: AuthForm) => {

			const isSignedUp = isRegistered.value === 'yes';

			const { email, last_name = '', first_name = '', } = form;

			const input = {
				from: 'web',
				email,
				redirect_path: ROUTES.createWorkspace,
			} as const

			if (isSignedUp) {

				const res = await signIn({ ...input });

				if ('error' in res) {

					const { message, field } = errorHandler<AuthForm>(res.error);

					field ?
						setError(field, { message, }) :
						alert(message);

					return;
				}

				setSentTo(email);

			} else {

				const res = await signUp({
					...input,
					last_name,
					first_name,
					invite_code: '',
				});

				if ('error' in res) {

					const { message, field } = errorHandler<AuthForm>(res.error, {
						101: [ lang.registerErrorInvalidEmail, 'email' ],
					});

					field ?
						setError(field, { message, }) :
						alert(message);

					return;
				}

				setSentTo(email);

			}

		},
		[ isRegistered, signIn, signUp ]
	);

	return (
		<AuthWrapper
			className="create-workspace"
			agreeByContinue={!sentTo}>

			<div className="page-login__head">
				<AuthLogo />
				<h1>{sentTo ? 'Thank you!' : 'Happy to have you on board!'}</h1>
			</div>

			{!sentTo &&
			<>
				<RadioGroup
					label="Are you a registered Aimcast user?"
					value={isRegistered}
					options={authOptions}
					onChange={setIsRegistered} />
				<InputC
					name="email"
					type="email"
					label="Email"
					control={control}
					required
					autoFocus
					className="mb42"
					placeholder="email@work-email.com" />
				{isRegistered.value === 'no' &&
				<div className="page-login__grid mb18">
					<InputC
						name="first_name"
						label="Name"
						control={control}
						required
						autoComplete="given-name" />
					<InputC
						name="last_name"
						label="Last name"
						control={control}
						required
						autoComplete="family-name" />
				</div>
				}
				<Button
					type="submit"
					disabled={!formState.isValid}
					onClick={handleSubmit(onSubmit)}
					children="Continue"
					isLoading={isSigningIn || isSigningUp} />
			</>
			}

			{!!sentTo &&
			<div className="page-login__sent-message">You will receive confirmation link at <b>{sentTo}</b>.<br /> Follow the instructions to continue the registration.</div>
			}

		</AuthWrapper>
	);

}
