import { useCallback, useState } from 'react';

import { useAuth, dateUtils } from '~/core';
import { config, lang } from '~/constants';
import { Page, Avatar, Button, Check, Icon, ModalPhoto } from '../components';

const useNotifies = () => {

	const [ isGranted, setIsGranted ] = useState(Notification.permission === 'granted');

	const isPresented = 'Notification' in window;

	const requestNotifies = useCallback(
		async () => {

			try {

				const permission = await Notification.requestPermission();

				if (permission !== 'granted') {
					return;
				}

				setIsGranted(true);

			} catch {}

		},
		[]
	);

	return {
		isGranted,
		isPresented,
		requestNotifies,
	};

}

export const Profile: React.FC = () => {

	const { account } = useAuth();

	const notifies = useNotifies();

	const [ photoModal, setPhotoModal ] = useState(false);

	const pushesCheck = (
		<Check
			// isChecked={notifies.isGranted}
			isChecked={false}
			setIsChecked={notifies.requestNotifies} />
	);

	const emailsCheck = <Check />

	const birthdayText = dateUtils.formatBirthDay(account.birthday);

	const {
		name,
		last_name,
		user_type,
		email,
		position,
		position_title,
	} = account;

	const _name = `${name} ${last_name}`.trimEnd();

	return (
		<Page
			title={lang.titleProfile}
			container="profile-container">
			<div className="profile-head">
				<Avatar
					src={account.avatar}
					size="big"
					name={_name}
					isGrey={!account.avatar}
					onIconClick={setPhotoModal.bind(null, true)} />
				<div className="user-content">
					<h2>{_name}</h2>
					{!!position &&
					<p className="user-param">{position}</p>
					}
					{!!position_title &&
					<p className="user-param">{position_title}</p>
					}
					{user_type !== 1 &&
					<p className="user-param">{email}</p>
					}
					{!!birthdayText &&
					<div className="user-birthday">
						<Icon name="cake" />
						<p className="user-param">{birthdayText}</p>
					</div>
					}
				</div>
			</div>
			<div className="profile-body">
				<h3>{lang.profileSettingsTitle}</h3>
				<div className="profile-actions">
					{notifies.isPresented &&
					<Button
						icon="menuNotifications"
						isSimple
						disabled
						children={lang.profileSettingPushes}
						additionalContent={pushesCheck} />
					}
					<Button
						icon="mail"
						isSimple
						disabled
						children={lang.profileSettingEmails}
						additionalContent={emailsCheck} />
					<Button
						icon="questionMark"
						isSimple
						onClick={() => window.open(config.supportURL, '_blank')}
						children={lang.profileSettingHelp} />
					<Button
						icon="exclamationMark"
						isSimple
						onClick={() => window.open(config.policyURL, '_blank')}
						children={lang.profileSettingPrivacy} />
				</div>
			</div>
			<ModalPhoto
				name={_name}
				avatar={account.avatar}
				onHide={setPhotoModal.bind(null, false)}
				isVisible={photoModal} />
		</Page>
	);

}
