import { api } from '../api';
import { Communication as C, Struct } from '../../types';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

import { feed } from './Feed';
import { feedDetailed } from './FeedDetailed';
import { library } from './Library';
import { discover } from './Discover';

const updateFeedCache = (
	id: string,
	state: boolean,
	dispatch: ThunkDispatch<any, any, AnyAction>
) => {

	dispatch(
		feed.util.updateQueryData(
			'readFeed',
			{ page: 0 },
			(draft) => {
				const key = draft.feed.findIndex(item => item.id === id);
				if (!draft.feed[key]) {
					return;
				}
				draft.feed[key].favorite = state;
			}
		)
	);

	dispatch(
		feedDetailed.util.updateQueryData(
			'readFeedDetailed',
			id,
			(draft) => {
				draft.favorite = state;
			}
		)
	);

}

const updateCategoryFavoriteState = (
	id: number,
	status: boolean,
	category: Struct.Category,
	dispatch: ThunkDispatch<any, any, AnyAction>
) => {

	dispatch(
		discover.util.updateQueryData(
			'readCategory',
			{ id, order: 'recent', },
			({ category }) => {
				category.favorite = status;
			}
		)
	);

	dispatch(
		library.util.updateQueryData(
			'readLibrary',
			{ order: 'recent', },
			({ categories }) => {
				const key = categories.findIndex(({ id }) => id === category.id);
				if (key === -1) {
					categories.push(category);
				} else {
					categories.splice(key, 1);
				}
			}
		)
	);

}

const updateCategoryNotificationState = (
	id: number,
	status: boolean,
	dispatch: ThunkDispatch<any, any, AnyAction>
) => {

	dispatch(
		discover.util.updateQueryData(
			'readCategory',
			{ id, order: 'recent' },
			({ category }) => {
				category.notifications_enabled = status;
			}
		)
	);

	dispatch(
		library.util.updateQueryData(
			'readLibrary',
			{ order: 'recent', },
			({ categories }) => {
				const key = categories.findIndex((category) => id === category.id);
				categories[key].notifications_enabled = status;
			}
		)
	);

}

export const favorite = api.injectEndpoints({
	endpoints: (builder) => ({
		toggleItem: builder.mutation<void, C.ToggleItemInput>({
			query: ({ id, status }) => ({
				url: '/feed/favorites',
				body: { id },
				method: status ? 'POST' : 'DELETE',
			}),
			onCacheEntryAdded: ({ id, status }, { dispatch }) => {
				updateFeedCache(id, status, dispatch);
			},
			invalidatesTags: ['LibraryContent'],
		}),
		toggleCategory: builder.mutation<void, C.ToggleCategoryInput>({
			query: ({ id, status }) => ({
				url: '/feed/subscriptions',
				body: { id },
				method: status ? 'POST' : 'DELETE',
			}),
			onCacheEntryAdded: ({ id, status, category }, { dispatch }) => {
				updateCategoryFavoriteState(id, status, category, dispatch);
			},
		}),
		toggleNotification: builder.mutation<void, C.ToggleNotificationInput>({
			query: (body) => ({
				url: '/feed/subscriptions/notification',
				body,
				method: 'POST',
			}),
			onCacheEntryAdded: ({ id, status }, { dispatch }) => {
				updateCategoryNotificationState(id, status, dispatch);
			},
		}),
	}),
	overrideExisting: true,
});
