import { Struct } from '../../types';
import { store } from '../store';
import { notification } from '../modules';

export const addNotification = (data: Struct.Notification) => {

	store.dispatch(
		notification.util.updateQueryData(
			'readNotifications',
			{ page: 0 },
			(draft) => void draft.notifications.unshift(data)
		)
	);

}

export const isNotification = (val: any): val is Struct.Notification => {

	const requiredkeys = [
		'id',
		'data',
		'user_id',
		'is_read',
		'acme_data',
		'created_at',
		'updated_at',
		'reference_id',
		'notification_type',
	];

	for (const key in requiredkeys) {
		if (typeof val[requiredkeys[key]] === 'undefined') {
			return false;
		}
	}

	return true;

}

