import cn from 'classnames';
import { lang } from '~/constants';
import { Media } from '~/core';
import { Label } from '../Label';

export interface CardTextProps {
	title: string,
	subtitle?: string,
	isLink?: string,
	isLower?: boolean,
	isAudio?: boolean,
	isHidden?: boolean,
	isReversed?: boolean,
	assetLabel?: string,
}

export const CardText: React.FC<CardTextProps> = (props) => {

	const {
		title,
		subtitle,
		isLink,
		isLower,
		isAudio,
		isHidden,
		assetLabel,
	} = props;

	const classNames = cn(
		'app-card-text',
		{
			'app-card-text_lower': isLower,
			'app-card-text_hidden': isHidden,
		}
	);

	return (
		<div className={classNames}>
			{!!isLink &&
			<div className="app-card-text__link">{isLink}</div>
			}
			{(!!isAudio && !assetLabel) &&
			<Label
				text={lang.audio}
				icon="headphones"
				isBadge />
			}
			{!!assetLabel &&
			<Label
				text={assetLabel}
				isBadge />
			}
			{!isHidden && <p className="app-card-text__title">{title}</p>}
			{(!!subtitle && !isHidden) && <p className="app-card-text__subtitle">{subtitle}</p>}
		</div>
	);

}
