import { useCallback, useEffect, useMemo, useState } from 'react';
import { FeedScope, OrderType, Struct } from '../../types';
import { library } from '../modules';

interface UseLibraryProps {
	order: OrderType,
}

interface DataType {
	feed: FeedScope.Item[],
	history: FeedScope.Item[],
	categories: Struct.Category[],
}

const { readLibrary, readLibraryFeed, readLibraryHistory } = library.endpoints;

export const useLibrary = ({ order }: UseLibraryProps) => {

	const [ data, setData ] = useState<DataType>({
		feed: [],
		history: [],
		categories: [],
	});

	const [ totalPages, setTotalPages ] = useState({
		feed: 0,
		history: 0,
	});

	const [ currentPages, setCurrentPages ] = useState({
		feed: 0,
		history: 0,
	});

	const [ trigger, result ] = readLibrary.useLazyQuery();

	const { isContentEmpty, isContentLoading } = readLibrary.useQueryState({ order }, {
		selectFromResult: (state) => {
			const isContentLoading = state.isUninitialized || state.isLoading;
			return {
				isContentEmpty: {
					categories: !isContentLoading && !state.data?.categories.length,
					history: !isContentLoading && !state.data?.history.length,
					feed: !isContentLoading && !state.data?.feed.length,
				},
				isContentLoading,
				...state,
			};
		},
	});

	const [ triggerFeed ] = readLibraryFeed.useLazyQuery();

	const [ triggerHistory ] = readLibraryHistory.useLazyQuery();

	const [ isRefetching, setIsRefetching ] = useState(false);

	const [ isFetching, setIsFetching ] = useState({
		feed: false,
		history: false,
	});

	const isMoreAvailable = useMemo(
		() => {
			return {
				feed: currentPages.feed + 1 < totalPages.feed,
				history: currentPages.history + 1 < totalPages.history,
			};
		},
		[ currentPages, totalPages ]
	);

	useEffect(() => {

		trigger({ order }, true);

	}, [ order ]);

	useEffect(() => {

		if (!result.isSuccess) {
			return;
		}

		const {
			feed,
			history,
			categories,
			total_feed_pages: fp,
			total_history_pages: hp,
		} = result.data;

		setData({ feed, history, categories });

		setTotalPages({ feed: fp, history: hp, });

		setCurrentPages({ feed: 0, history: 0, });

	}, [ result.fulfilledTimeStamp ]);

	const refetch = useCallback(
		async () => {

			setIsRefetching(true);

			try {

				await trigger({ order }, true).unwrap();

				setIsRefetching(false);

			} catch (e) {

				console.error(e);

			}

		},
		[ order ]
	);

	const fetchMore = useCallback(
		async (target: 'feed' | 'history') => {

			const fn = target === 'feed' ?
				triggerFeed :
				triggerHistory;

			setIsFetching((val) => ({
				...val,
				[target]: true,
			}));

			try {

				const { feed } = await fn({
					page: currentPages[target] + 1,
					order,
				}).unwrap();

				setData((val) => ({
					...val,
					[target]: [ ...val[target], ...feed ],
				}));

				setCurrentPages((val) => ({
					...val,
					[target]: val[target] + 1,
				}));

				setIsFetching((val) => ({
					...val,
					[target]: false,
				}));

			} catch (e) {

				console.error(e);

			}

		},
		[ order, currentPages ]
	);

	return {
		data,
		isFetching,
		isRefetching,
		isMoreAvailable,
		refetch,
		fetchMore,
		isContentEmpty,
		isContentLoading,
	};

}
