import {
	useMemo,
	useState,
	useEffect,
	useCallback,
} from 'react';
import { YouTubePlayer } from 'react-youtube';

import {
	stringUtils,
	mediaUpdate,
	useAppDispatch,
	useAppSelector,
} from '~/core';

interface UseMediaYTProps {
	player: YouTubePlayer,
	video: string,
	feedId: string,
}

export const useMediaYT = (
	props: UseMediaYTProps
) => {

	const { player, video, feedId } = props;

	const [ isPlaying, setIsPlaying ] = useState(false);

	const [ isStarted, setIsStarted ] = useState(false);

	const mediaState = useAppSelector(({ media }) => media);

	const dispatch = useAppDispatch();

	useEffect(
		() => {

			const { currentPlayingItem } = mediaState;

			if (
				currentPlayingItem &&
				currentPlayingItem !== feedId
			) {

				player?.pauseVideo();

				setIsStarted(false);

				setIsPlaying(false);

			}

		},
		[ player, feedId, mediaState ]
	);

	useEffect(() => {

		if (!player) {
			return;
		}

		if (isPlaying) {

			player.playVideo();

			setIsStarted(true);

		} else {

			player.pauseVideo();

		}

	}, [ player, isPlaying ]);

	const src = useMemo(
		() => stringUtils.url.getYoutubeId(video),
		[ video ]
	);

	const togglePlaying = useCallback(
		(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

			e.preventDefault();
			e.stopPropagation();

			setIsPlaying((val) => !val);

			dispatch(mediaUpdate(feedId));

		},
		[ player ]
	);

	return {
		src,
		isStarted,
		isPlaying,
		togglePlaying,
	};

}
