import { lang } from '~/constants';
import { FeedScope, isAsset, Media } from '~/core';
import { Card } from './Card';
import { PageItem } from './PageItem';

export interface PageLinkedProps {
	data: (FeedScope.ItemDetailed | Media.Item)[],
}

export const PageLinked: React.FC<PageLinkedProps> = (props) => {

	const { data } = props;

	if (!data.length) {
		return null;
	}

	const singleMode =
		data.length > 1 ||
		data.length === 1 && !isAsset.asset(data[0]) &&
			data[0].type === 'external-link' ||
			isAsset.asset(data[0]) && !isAsset.supported(data[0]);

	return (
		<div id="linked" className="app-linked">
			{singleMode ?
			<>
				<h3>{lang.linkedCheckAlso}</h3>
				{data.map((data, i) => (
				<Card key={i} data={data} />
				))}
			</> :
			<PageItem data={data[0]} />
			}
		</div>
	);

}
