import cn from 'classnames';

export interface LoaderProps {
	isGlobal?: boolean,
	isLoading?: boolean,
	isPrimary?: boolean,
	isBlack?: boolean,
}

export const Loader: React.FC<LoaderProps> = (props) => {

	const { isGlobal, isLoading, isPrimary, isBlack } = props;

	const className = cn(
		'app-loader',
		{
			'app-loader_global': isGlobal,
			'app-loader_loading': isLoading,
			'app-loader_primary': isPrimary,
			'app-loader_black': isBlack,
		},
	);

	return <i className={className} />

}
