import { useCallback, useState, MouseEventHandler } from 'react';
import { useLocation } from 'react-router-dom';

import { config, getPath } from '~/constants';
import { FeedScope, favorite } from '~/core';
import { Toast, clipboardCopy } from '../services';

export function useItemActions (item?: FeedScope.Item) {

	const [
		toggleFavoriteState,
		// { isLoading: isTogglingFavorite }
	] = favorite.useToggleItemMutation();

	const { state } = useLocation<{ open_comments?: boolean }>();

	if (state?.open_comments) {
		window.history.replaceState({}, document.title);
	}

	const onSavedToggle = useCallback(
		async () => {

			if (!item) {
				return null;
			}

			toggleFavoriteState({
				id: item.id,
				status: !item.favorite,
			});

			Toast.togglePost(item.favorite);

		},
		[ item, toggleFavoriteState ]
	);

	const [ isCommentsVisible, setCommentsVisibility ] = useState(state?.open_comments || false);

	const onClipboardCopy = useCallback<
		MouseEventHandler<HTMLButtonElement>
	>(
		async (e) => {

			if (!item) {
				return;
			}

			if (e.altKey && config.isDebug) {
				clipboardCopy(JSON.stringify(item, null, ' '));
				return void console.log('Current item data:', item);
			}

			await clipboardCopy(
				getPath('item', { id: item.id }, true)
			);

			Toast.copied();

		},
		[ item ]
	);

	return {
		isSaved: item?.favorite,
		isShareable: item?.shareable,
		commentsCount: item?.comments_count || 0,
		commentsEnabled: item?.comments_enabled,
		linkedCount: (item?.linked_items_count || 0) + (item?.linked_assets_count || 0),
		onSavedToggle,
		onClipboardCopy,
		isCommentsVisible,
		setCommentsVisibility
	};

}
