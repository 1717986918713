import { Link } from 'react-router-dom';

import { getPath } from '~/constants';
import { Struct } from '~/core';

import { Image } from '../Image';
import { Label } from '../Label';

export interface CardCategoryProps {
	data: Struct.Category,
	forceLabel?: boolean,
}

export const CardCategory: React.FC<CardCategoryProps> = (props) => {

	const { data, forceLabel } = props;

	const to = data.id !== 0 ?
		getPath('category', { id: data.id }) :
		getPath('organizer');

	if (!forceLabel && data.type === 'channel') {
		return (
			<Link to={to} className="app-card-channel">
				<div className="app-card-channel__title">
					<h4>{data.title}</h4>
				</div>
				<Image src={data.image?.url} />
			</Link>
		);
	}

	return (
		<Label to={to} text={data.title} />
	);

}
