import {
	useMemo,
	useState,
	useEffect,
	useCallback,
	RefObject,
	MouseEventHandler,
} from 'react';

import {
	dateUtils,
	stringUtils,
	mediaUpdate,
	useAppDispatch,
	useAppSelector,
} from '~/core';

interface UseMediaProps<T> {
	ref: RefObject<T>,
	url?: string,
	feedId: string,
}

export const useMedia = <T extends HTMLVideoElement | HTMLAudioElement>(
	props: UseMediaProps<T>
) => {

	const { ref, url, feedId } = props;

	const mediaState = useAppSelector(({ media }) => media);

	const dispatch = useAppDispatch();

	const [ isStarted, setIsStarted ] = useState(false);

	const [ isPlaying, setIsPlaying ] = useState(false);

	const [ isMuted, setIsMuted ] = useState(false);

	const [ playbackRate, setPlaybackRate ] = useState(1);

	const [ duration, setDuration ] = useState(0);

	const [ dimensions, setDimensions ] = useState({
		width: 0,
		ratio: 0,
		height: 0,
	});

	const [ currentTime, setCurrentTime ] = useState(0);

	const formattedValue = useMemo(
		() => dateUtils.formatSeconds(currentTime, duration),
		[ currentTime, duration ]
	);

	const percentValue = useMemo(
		() => ((currentTime / duration) * 100) || 0,
		[ duration, currentTime ]
	);

	const onSeekTo = useCallback(
		(value: number) => {

			if (!ref.current) {
				return;
			}

			ref.current.currentTime = ((value * duration) / 100) || 0;

		},
		[ ref, duration ]
	);

	const onRewind = useCallback(
		(value: number) => {

			if (!ref.current) {
				return;
			}

			ref.current.currentTime = ref.current.currentTime + value || 0;

		},
		[ ref ]
	);

	const onLoadedMetadata = useCallback(
		() => {

			const element = ref.current;

			if (!element) {
				return;
			}

			setDuration(element.duration || 0);

			if ('videoWidth' in element) {

				const {
					videoWidth: width = 1,
					videoHeight: height = 1,
				} = element;

				setDimensions({
					width,
					ratio: height / width,
					height,
				});
			}

		},
		[ ref ]
	);

	const onTimeUpdate = useCallback(
		() => setCurrentTime(ref.current?.currentTime || 0),
		[ ref ]
	);

	const onPlay = useCallback(
		() => {

			setIsPlaying(true);

			setIsStarted(true);

		},
		[]
	);

	const onPause = useCallback(
		() => {

			setIsPlaying(false);

		},
		[]
	);

	const onEnded = useCallback(
		() => {

			setIsStarted(false);

			setIsPlaying(false);

		},
		[]
	);

	const openFullScreen = useCallback(
		() => {

			const video = ref.current;

			if (video?.requestFullscreen) {
				video?.requestFullscreen();
			} else if ((video as any).webkitRequestFullscreen) {
				(video as any).webkitRequestFullscreen();
			}

		},
		[ ref ]
	);

	const togglePlaying = useCallback<
		MouseEventHandler<HTMLButtonElement>
	>(
		(e) => {

			e.preventDefault();
			e.stopPropagation();

			if (!ref.current) {
				return;
			}

			if (!isStarted) {
				setIsStarted(true);
			}

			ref.current[isPlaying ? 'pause' : 'play']();

			dispatch(mediaUpdate(feedId));

			setIsPlaying((val) => !val);

		},
		[ ref, feedId, isStarted, isPlaying, dispatch ]
	);

	const toggleMuted = useCallback(
		() => {

			if (!ref.current) {
				return;
			}

			ref.current.muted = !isMuted;

			setIsMuted((val) => !val);

		},
		[ ref, isMuted ]
	);

	const togglePlaybackRate = useCallback(
		() => {

			const rates = [1,1.5,2];

			setPlaybackRate((rate) => {
				const i = rates.indexOf(rate);
				return rates[i < rates.length - 1 ? i + 1 : 0];
			});

		},
		[]
	);

	useEffect(
		() => {

			if (!ref.current) {
				return;
			}

			ref.current.playbackRate = playbackRate;

		},
		[ ref, playbackRate ]
	);

	useEffect(
		() => {

			const { currentPlayingItem } = mediaState;

			if (
				currentPlayingItem &&
				currentPlayingItem !== feedId
			) {

				ref.current?.pause();

				setIsStarted(false);

				setIsPlaying(false);

			}

		},
		[ ref, feedId, mediaState ]
	);

	const [ src, isHsl ] = useMemo(
		() => [
			stringUtils.url.getAssetURL(url),
			/.m3u8$/i.test(url || '')
		],
		[ url ]
	);

	return {
		src,
		isHsl,
		isStarted,
		isPlaying,
		isMuted,
		playbackRate,
		duration,
		dimensions,
		currentTime,
		percentValue,
		formattedValue,
		onSeekTo,
		onRewind,
		togglePlaying,
		toggleMuted,
		openFullScreen,
		setPlaybackRate,
		togglePlaybackRate,
		callbacks: {
			onPlay,
			onPause,
			onEnded,
			onTimeUpdate,
			onLoadedMetadata,
		},
	};

}
