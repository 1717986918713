import { useMemo, useEffect } from 'react';

import { stringUtils, feed, useAppDispatch } from '~/core';
import { Iframe, Modal } from '../../components';

export const ModalSurveys: React.FC = () => {

	const dispatch = useAppDispatch();

	const { surveys } = feed.endpoints.readRequiredPolls.useQueryState(undefined, {
		selectFromResult: ({ data }, ...state) => ({
			surveys: [
				...(data?.post_activation_surveys || []),
				...(data?.next_interaction_surveys || []),
			],
			...state,
		}),
	});

	const currentSurvey = useMemo(
		() => surveys[0] || null,
		[ surveys ]
	);

	useEffect(
		() => {

			if (!currentSurvey) {
				return;
			}

			const listener = (e: MessageEvent<string>) => {

				try {

					if (typeof e.data !== 'string') {
						return;
					}

					const { poll_type, feed_record_id } = currentSurvey.object;

					const { result } = JSON.parse(e.data) as { result: boolean };

					if (
						!result ||
						poll_type === 'in_feed' ||
						poll_type === 'web_view_survey'
					) {
						return;
					}

					dispatch(
						feed.util.updateQueryData(
							'readRequiredPolls',
							undefined,
							(draft) => {
								const key = `${poll_type}_surveys` as const;
								const index = draft[key].findIndex(({ id }) => id === feed_record_id);
								draft[key].splice(index, 1);
							},
						)
					);

				} catch {
					console.warn('Unable to parse frame message');
				}

			}

			window.addEventListener('message', listener);

			return () => window.removeEventListener('message', listener);

		},
		[ currentSurvey ]
	);

	if (!surveys.length) {
		return null;
	}

	if (!stringUtils.url.isValid(currentSurvey.object.survey_url)) {
		return null;
	}

	return (
		<Modal visible={!!currentSurvey} className="modal_surveys">
			<Iframe
				src={currentSurvey.object.survey_url} />
		</Modal>
	);

}
