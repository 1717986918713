import { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import cn from 'classnames';

import { useContext } from '~/core';
import { ROUTES } from '~/constants';
import { AsideMenu, CalendarWrap } from '../../components';

import 'react-toastify/dist/ReactToastify.css';

export const Frame: React.FC<PropsWithChildren> = (props) => {

	const { children } = props;

	const { pathname } = useLocation();

	const { isMenuOpen, menuToggle } = useContext();

	useEffect(() => {

		menuToggle(false);

		window.scrollTo(0, 0);

	}, [ pathname, menuToggle ]);

	return (
		<>
			<div className={cn('aside', { shown: isMenuOpen })}>
				<div className="aside-content">
					<AsideMenu />
				</div>
				<div
					onClick={menuToggle.bind(null, false)}
					className="menu-switch" />
			</div>
			<div className="main">
				<div className="main-content">
					<div className="main-body">
						{children}
					</div>
					<div className="main-aside">
						<div className="main-aside__content">
							{(pathname !== ROUTES.organizer) &&
							<CalendarWrap />
							}
						</div>
					</div>
				</div>
			</div>
			<ToastContainer />
		</>
	);

}
