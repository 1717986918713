import cn from 'classnames';
import { useCallback, useRef } from 'react';

import { lang } from '~/constants';
import { Struct, FormAction, dateUtils } from '~/core';

import { Avatar } from '../Avatar';
import { IconButton } from '../IconButton';
import { TextButton } from '../TextButton';

export interface CommentProps {
	userId: string,
	comment: Struct.Comment,
	isReply?: boolean,
	onUserAction?: (action: FormAction) => void,
}

export const Comment: React.FC<CommentProps> = (props) => {

	const { userId, comment, isReply, onUserAction } = props;

	const commentRef = useRef<HTMLDivElement>(null);

	const {
		text,
		avatar,
		user_id,
		updated,
		deleted,
		first_name,
		created_at,
	} = comment;

	const classNames = cn('app-comment', {
		'app-comment_reply': isReply,
		'app-comment_deleted': deleted,
	});

	const _onUserAction = useCallback(
		(type: FormAction['type']) => {

			const commentOffset = commentRef.current?.offsetTop;

			onUserAction?.({ type, comment, commentOffset });

		},
		[ onUserAction, comment, commentRef ]
	);

	return (
		<div
			ref={commentRef}
			className={classNames}>
			<div className="app-comment__avatar">
				<Avatar
					src={avatar}
					name={first_name}
					size={isReply ? 'small' : undefined} />
			</div>
			<div className="app-comment__content">
				<div className="app-comment__title">
					<p className="app-comment__name">{first_name}</p>
					<p className="app-comment__date">{dateUtils.timeAgo(created_at)}</p>
					{(user_id === userId && !deleted) &&
					<div className="app-comment__actions">
						<IconButton
							icon="pen"
							isSmall
							onClick={_onUserAction.bind(null, 'edit')} />
						<IconButton
							icon="bin"
							isSmall
							onClick={_onUserAction.bind(null, 'delete')} />
					</div>
					}
				</div>
				<p className="app-comment__text">
					{deleted ? lang.commentDeleted : text}
					{!deleted && updated ? <span className="edit-mark">{lang.commentEdited}</span> : ''}
				</p>
				{!isReply &&
				<TextButton
					onClick={_onUserAction.bind(null, 'reply')}
					children={lang.commentReply}
					isPrimary />
				}
			</div>
		</div>
	);

}
