import { MouseEventHandler, useCallback } from 'react';

import { favorite, Struct } from '~/core';
import { config, getPath, lang } from '~/constants';
import { Toast, clipboardCopy } from '../../services';

import { Check } from '../Check';
import { DropDown, DropDownProps } from '../DropDown';

export interface CategoryDropdownProps extends DropDownProps {
	category: Struct.Category,
}

export const CategoryDropdown: React.FC<CategoryDropdownProps> = (props) => {

	const { category, ...args } = props;

	const [
		toggleFavoriteState,
		// { isLoading: isTogglingFavorite }
	] = favorite.useToggleCategoryMutation();

	const [
		toggleNotificationState,
		// { isLoading: isTogglingNotification }
	] = favorite.useToggleNotificationMutation();

	const onFavoriteToggle = useCallback(
		async () => {

			await toggleFavoriteState({
				id: category.id,
				status: !category.favorite,
				category,
			});

			Toast.toggleCategory(category.favorite);

		},
		[ toggleFavoriteState, category ]
	);

	const onNotifyToggle = useCallback<
		MouseEventHandler<HTMLButtonElement | HTMLDivElement>
	>(
		async (e) => {

			e.stopPropagation();

			await toggleNotificationState({
				id: category.id,
				status: !category.notifications_enabled,
			});

			Toast.toggleCategoryNotification(category.notifications_enabled);

		},
		[ toggleNotificationState, category ]
	);

	const onShare = useCallback<
		MouseEventHandler<HTMLButtonElement>
	>(
		async (e) => {

			if (e.altKey && config.isDebug) {
				clipboardCopy(JSON.stringify(category, null, ' '));
				return void console.log('Current category data:', category);
			}

			await clipboardCopy(
				getPath('category', { id: category.id }, true),
			);

			Toast.copied();

		},
		[ category ]
	);

	const notifyToggle = (
		<Check
			isCompact
			isChecked={category.notifications_enabled}
			setIsChecked={onNotifyToggle} />
	);

	args.items = [
		{
			icon: `star${category.favorite ? 'Active' : ''}`,
			onClick: onFavoriteToggle,
			children: lang[category.favorite ? 'moreUnsave' : 'moreSave'],
			// isLoading: isTogglingFavorite,
		},
		{
			icon: 'menuNotifications',
			onClick: onNotifyToggle,
			children: lang.moreNotifications,
			// isLoading: isTogglingNotification,
			additionalContent: notifyToggle,
		},
		{
			icon: 'share',
			onClick: onShare,
			children: lang.moreShare,
		},
	];

	return <DropDown {...args} isVertical />

}
