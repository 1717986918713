import { Icon, IconProps } from './Icon';

export interface EmptyProps {
	text?: string,
	icon?: IconProps['name'],
	wrapped?: boolean,
}

export const Empty: React.FC<EmptyProps> = (props) => {

	const { text, icon, wrapped } = props;

	const children = (
		<div className="app-empty">
			{!!icon && <Icon name={icon} />}
			{!!text && <p className="app-empty__text" children={text} />}
		</div>
	);

	if (!wrapped) {
		return children;
	}

	return (
		<div className="app-container">
			<div className="app-section">
				<div
					children={children}
					className="app-section__body" />
			</div>
		</div>
	)

}
