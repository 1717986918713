import { api } from '../api';
import { Communication as C } from '../../types';
import { objectToQuery } from '../../utils';

export const notification = api.injectEndpoints({
	endpoints: (build) => ({
		readNotifications: build.query<C.ReadNotificationsState, C.ReadNotificationsInput>({
			query: ({ page = 0, include_read = true }) => ({
				url: `/notifications/filter?${objectToQuery({ page, include_read })}`,
			}),
			keepUnusedDataFor: 99999999999999,
			transformResponse: (response: C.ReadNotificationsOutput) => {

				const { notifications, page, total_pages } = response;

				return {
					notifications,
					more: page + 1 < total_pages,
				};

			},
			onQueryStarted: async (args, { dispatch, queryFulfilled }) => {

				try {

					const { data } = await queryFulfilled;

					dispatch(
						notification.util.updateQueryData(
							'readNotifications',
							{ page: 0 },
							(draft) => {
								draft.notifications = args.page === 0 ?
									draft.notifications :
									[ ...draft.notifications, ...data.notifications ];
								draft.more = data.more;
							}
						)
					);

				} catch {}

			},
		}),
		setNotificationRead: build.mutation<void, C.SetNotificationReadInput>({
			query: (ids) => ({
				url: `/notifications/set_read`,
				body: { ids },
				method: 'PUT',
			}),
			onQueryStarted: async (ids, { queryFulfilled, dispatch }) => {

				try {

					await queryFulfilled;

					dispatch(
						notification.util.updateQueryData(
							'readNotifications',
							{ page: 0 },
							(draft) => {
								for (const id in ids) {
									const key = draft.notifications.findIndex((item) => item.id === ids[id]);
									if (draft.notifications[key]) {
										draft.notifications[key].is_read = true;
									}
								}
							}
						)
					);

				} catch {}

			},
		}),
	}),
	overrideExisting: true,
});
