import { PropsWithChildren } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import cn from 'classnames';

import { useContext } from '~/core';
import { ROUTES } from '~/constants';
import { Container, IconButton, SearchField } from '../../components';

export interface PageProps extends PropsWithChildren {
	title?: string,
	search?: boolean,
	onGoBack?: boolean | (() => void),
	container?: boolean | string,
	className?: string,
	isFixedHead?: boolean,
	isFixedHeight?: boolean,
	isContentLoading?: boolean,
}

export const Page: React.FC<PageProps> = (props) => {

	const {
		title,
		search,
		onGoBack,
		container,
		className,
		children,
		isFixedHead = true,
		isFixedHeight,
		isContentLoading,
	} = props;

	const { key } = useLocation();

	const { push, goBack } = useHistory();

	const { menuToggle } = useContext();

	const classNames = cn('app-page', className, {
		'app-page_fixed-head': isFixedHead,
		'app-page_fixed-height': isFixedHeight,
	});

	return (
		<div className={classNames}>
			<div className="app-page__head">
				<div className="app-page__title">
					<IconButton
						icon="menu"
						onClick={menuToggle.bind(null, true)}
						className="menu-trigger" />
					{!!onGoBack &&
					<IconButton
						icon="arrowBack"
						onClick={() => typeof onGoBack === 'function' ? onGoBack() : key ? goBack() : push(ROUTES.feed)} />
					}
					{!!title && <h1 children={title} />}
				</div>
				{search && <SearchField />}
			</div>
			<div className="app-page__body">
				{isContentLoading ?
				<Container hasLoader isLoading className="app-container_full-height" /> :
				<>
				{!!container ?
				<Container
					children={children}
					className={typeof container === 'string' ? container : undefined} /> :
				children
				}
				</>
				}
			</div>
		</div>
	);

}
