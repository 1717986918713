import { useRef } from 'react';
import Slider from 'react-slider';

import { Media } from '~/core';
import { lang } from '~/constants';
import { useMedia } from '../services';

import { TextButton } from './TextButton';
import { PlayerButton } from './PlayerButton';


export interface AudioProps {
	audio: Media.Audio,
	feedId: string,
}

export const Audio: React.FC<AudioProps> = (props) => {

	const { audio, feedId } = props;

	const audioRef = useRef<HTMLAudioElement>(null);

	const {
		src,
		isPlaying,
		playbackRate,
		percentValue,
		formattedValue: [ timePassed, timeLeft ],
		callbacks,
		onSeekTo,
		onRewind,
		togglePlaying,
		togglePlaybackRate,
	} = useMedia({
		ref: audioRef,
		url: audio.url,
		feedId,
	});

	return (
		<div className="app-audio">
			<div className="app-audio__controls">
				<div className="app-audio__slider">
					<p className="app-audio__time">{timePassed}</p>
					<Slider
						value={percentValue}
						onChange={onSeekTo}
						className="app-slider is_audio" />
					<p className="app-audio__time">{timeLeft}</p>
				</div>
				<div className="app-audio__controls-playback">
					<PlayerButton
						onRewind={onRewind}
						isRewind={-15} />
					<PlayerButton
						isBig
						onClick={togglePlaying}
						isPlaying={isPlaying} />
					<PlayerButton
						onRewind={onRewind}
						isRewind={15} />
				</div>
				<div className="app-audio__controls-misc">
					<TextButton onClick={togglePlaybackRate}>{playbackRate}X</TextButton>
					<TextButton icon="transcript" disabled>{lang.audioTranscript}</TextButton>
				</div>
			</div>
			<audio ref={audioRef} src={src} {...callbacks} />
		</div>
	);

}
