export const lang = {

	/* Aside menu items
	 */
	menuHome: 'Home',
	menuDiscover: 'Discover',
	menuLibrary: 'My Library',
	menuNotifications: 'Notifications',
	menuProfile: 'Profile',
	menuLogout: 'Log Out',
	menuAdmin: 'Admin area',
	menuAdminContent: 'Content',
	menuAdminUsers: 'Users',
	menuAdminAnalytics: 'Analytics',


	/* Screens
	 */
	authOr: 'or',
	authContinueEmail: 'Continue with email',
	authContinueGuest: 'Continue as guest',
	authSampleEmail: 'john.smith@work-email.com',
	authCheckMail: 'Check your email for an access link',
	authLabelEmail: 'Email',
	authTermsLabel: 'Privacy & Terms',
	authWelcomeHeader: 'Welcome to',
	authCheckMailText: (email: string) => <>If we recognize your address, you will receive an access link at <b>{email}</b>. Follow the instructions to access the platform.</>,
	authDontHaveAccount: 'Don\'t have an account?',
	authClickHereToRegister: 'Click here to Register.',

	authPrivacyPolicy: 'Privacy Policy',
	authTermsConditions: 'Terms & Conditions',
	authByContinuing: 'By continuing you agree with our',
	authByContinuingAnd: 'and',

	registerTitle: 'Happy to have you on board!',
	registerTitleSuccess: 'Thank you for registering!',
	registerTextSuccess: (email: string) => <>You will receive an access link at <b>{email}</b>. Follow the instructions to access the platform.</>,
	registerTextSuccessMobile: 'Next, install the Aimcast App:',
	registerActivate: (email: string) => <>Then, activate your account using the link you received at <b>{email}</b>.</>,
	registerDownload: 'Go to App store',
	registerDownloadQR: 'Download mobile app here',
	registerContinue: 'Or continue to the',
	registerContinueWebApp: 'web app',
	registerErrorInvalidCode: 'Invalid invite code',
	registerErrorInvalidEmail: 'Email address is already in use',

	registerLabelName: 'Name',
	registerLabelLastName: 'Last name',
	registerLabelInviteCode: 'Invite code',
	registerSubmit: 'Submit',
	registerFieldRequired: 'This field is required',
	registerEmailValidate: 'Please enter a valid email',

	titleDiscover: 'Discover',
	titleLibrary: 'My Library',
	titleNotifications: 'Notifications',
	titleProfile: 'Profile',

	months: [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	],

	days: [
		'Mon',
		'Tue',
		'Wed',
		'Thu',
		'Fri',
		'Sat',
		'Sun',
	],

	organizerTitle: 'Organizer',

	greetingEvening: 'Good evening',
	greetingMorning: 'Good morning',
	greetingAfternoon: 'Good afternoon',

	discoverChannels: 'Channels',
	discoverCategories: 'Categories',
	discoverRecents: 'Recently Added',

	profileSettingsTitle: 'Profile Settings',
	profileSettingPushes: 'Web Push Notifications',
	profileSettingEmails: 'Email Notifications',
	profileSettingHelp: 'Get Help',
	profileSettingPrivacy: 'Privacy Policy',

	libraryFavorite: 'Favorite topics',
	librarySaved: 'Saved content',
	libraryHistory: 'My History',

	searchAll: 'All',
	searchVideos: 'Videos',
	searchAudio: 'Audio',
	searchImages: 'Images',

	eventJoin: 'Join',
	eventAddCalendar: 'Add to calendar',

	emptyFavorites: 'Choose your favorite topics.',
	emptyHistory: 'You haven\'t opened any content yet.',
	emptySaved: 'Save content you want to explore later.',

	unspecifiedError: 'An unspecified error has occurred, please try again later',
	unspecifiedErrorTitle: 'Oops! Something seems wrong',
	unspecifiedErrorText: 'An unspecified error has occurred, please\n try again later',
	emptyFeed: 'You\'re all caught up with the newest content',
	emptyNotifications: 'You have not received any notifications yet',
	cardTakeSurvey: 'Take survey',


	/* Components
	 */
	eventLive: 'Live',
	recencyRecent: 'Most recent',
	recencyOldest: 'Oldest',
	recencyAlphabetical: 'Alphabetical',
	audioTranscript: 'Transcript',


	/* UI Components
	 */
	audio: 'audio',
	noEvents: 'No events on this date',
	pollSubmit: 'Submit',
	pollSubmiting: 'Loading...',
	linkedCheckAlso: 'Check also',
	searchNothingFound: 'Nothing found for your request',
	itemMediaDescription: 'Description',

	moreSave: 'Save',
	moreShare: 'Share',
	moreUnsave: 'Unsave',
	moreLinked: 'Linked Content',
	moreComment: 'Comment',
	moreNotifications: 'Notifications',

	commentReply: 'Reply',
	commentsTitle: 'Comments',
	commentsEmpty: 'No comments yet',
	commentEdited: '(edited)',
	commentDeleted: 'The comment has been removed',
	commentInputEdit: 'Edit comment...',
	commentInputText: 'Add comment...',
	commentCancelEdit: 'Cancel edit',
	commentCancelReply: 'Cancel reply',
	commentInputReply: (name: string) => `Replying to ${name}...`,
	commentRepliesCount: (value: number) => `${value} Repl${value === 1 ? 'y' : 'ies'}`,

	searchPlaceholder: 'Search...',

	photoModalTitle: 'Profile picture',
	photoModalButtonSave: 'Save image',
	photoModalButtonUpload: 'Upload image',

	showMore: 'Show more',
	loadingMore: 'Loading more...',
	showMoreCatchUpOn: 'You\'re all caught up with the newest content',

	buttonGoBack: 'Go back',
	buttonExploreFeed: 'Explore feed',
	itemNotFoundTitle: 'The requested resource is not found',
	itemNotFoundMessage: 'Unfortunately, we could not find the resource with the given parameters',


	/* Services
	 */
	toastCopied: 'Copied to clipboard',
	toastFavoritePost: 'We added this post to your Library',
	toastUnfavoritePost: 'We removed this post from your Library',
	toastFavoriteCategory: 'We added this category to your Library',
	toastUnfavoriteCategory: 'We removed this category from your Library',
	toastNotifyCategory: 'We turned on notifications for this category',
	toastUnnotifyCategory: 'We turned off notifications for this category',

	tenantCreationNameError: 'Tenant with this name already exist',

};
