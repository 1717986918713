import heic2any from 'heic2any';

export type Area = {
	width: number,
	height: number,
	x: number,
	y: number
}

const createImage = (url: string) => new Promise<HTMLImageElement>((resolve, reject) => {

	const image = new Image();

	image.addEventListener('load', () => resolve(image));
	image.addEventListener('error', (error) => reject(error));

	image.setAttribute('crossOrigin', 'anonymous');

	image.src = url;

});

export type ImageDetails = {
	url: string,
	width: number,
	height: number,
	ratio: number,
}

const getImageDetails = (file: File) => new Promise<ImageDetails>((resolve, reject) => {

	const reader = new FileReader();

	reader.addEventListener('load', async () => {

		if (!reader.result || typeof reader.result !== 'string') {
			return reject('Unable to open image: invalid file type or exceeding file size (0)');
		}

		try {

			const { width, height } = await createImage(reader.result);

			resolve({
				url: reader.result,
				ratio: height / width,
				width,
				height,
			});

		} catch (e) {

			reject('Unable to open image: invalid file type or exceeding file size (1)');

		}

	});

	reader.readAsDataURL(file);

});

interface Args {
	src: string,
	area: Area,
	name: string,
	type?: 'image/jpeg',
	dimension?: Record<'width' | 'height', number>
}

const getCroppedImg = async (args: Args): Promise<File | null> => {

	const {
		src,
		area,
		name,
		type = 'image/jpeg',
		dimension,
	} = args;

	const image = await createImage(src);
	const canvas = document.createElement('canvas');
	const context = canvas.getContext('2d');

	if (!context) {
		return null;
	}

	canvas.width = dimension?.width || area.width;
	canvas.height = dimension?.height || area.height;

	context.drawImage(
		image,
		area.x,
		area.y,
		area.width,
		area.height,
		0,
		0,
		dimension?.width || area.width,
		dimension?.height || area.height,
	);

	return new Promise((resolve, reject) => {

		canvas.toBlob(
			(blob) => {

				if (!blob) {
					return reject();
				}

				resolve(
					new File([ blob ], name, { type })
				);

			},
			'image/jpeg',
			1
		);

	});

}

const saveBlob = (data: string | File, name: string, type: string) => {

	const a = document.createElement('a');

	document.body.appendChild(a);

	a.style.display = 'none';

	const blob = typeof data === 'string' ?
		new Blob(
			[ data ],
			{ type }
		) :
		data;

	const href = window.URL.createObjectURL(blob);

	a.href = href;

	a.download = name;

	a.click();

	window.URL.revokeObjectURL(href);

}

const normalizePhoto = async (file: File, quality: number): Promise<File> => {

	if (file.type !== 'image/heic') {
		return file;
	}

	const blob = await heic2any({
		blob: file,
		toType: 'image/jpeg',
		quality,
	});

	return new File([ Array.isArray(blob) ? blob[0] : blob ], file.name);

}

export const crop = {
	createImage,
	getImageDetails,
	getCroppedImg,
	saveBlob,
	normalizePhoto,
};
