import cn from 'classnames';

import { lang, theme } from '~/constants';
import { FeedScope } from '~/core';
import { scrollTo, useItemActions } from '../../services';

import { ModalComments } from './ModalComments';
import { IconButton } from '../IconButton';

export interface ItemActionsProps {
	item?: FeedScope.Item,
	className?: string,
}

export const ItemActions: React.FC<ItemActionsProps> = (props) => {

	const { item, className } = props;

	const {
		isSaved,
		isShareable,
		commentsCount,
		commentsEnabled,
		linkedCount,
		onSavedToggle,
		onClipboardCopy,
		isCommentsVisible,
		setCommentsVisibility,
	} = useItemActions(item);

	if (!item) {
		return null;
	}

	return (
		<div className={cn('app-item__actions', className)}>
			<IconButton
				icon={`star${isSaved ? 'Active' : ''}`}
				title={lang[isSaved ? 'moreUnsave' : 'moreSave']}
				onClick={onSavedToggle} />
			<IconButton
				icon="comment"
				title={lang.moreShare}
				counter={commentsCount}
				onClick={() => setCommentsVisibility(true)}
				disabled={!commentsEnabled} />
			<IconButton
				icon="share"
				title={lang.moreComment}
				onClick={onClipboardCopy}
				disabled={!isShareable} />
			<IconButton
				icon="linked"
				title={lang.moreLinked}
				hidden={!linkedCount}
				onClick={() => scrollTo(theme.PAGE_LINKED_ELEMENT, theme.PAGE_HEAD_HEIGHT)}
				counter={linkedCount} />
			<ModalComments
				feedId={isCommentsVisible ? item.id : undefined}
				onHide={() => setCommentsVisibility(false)}
				isVisible={isCommentsVisible} />
		</div>
	);

}
