import { FC, PropsWithChildren } from 'react';
import { RouteProps } from 'react-router-dom';

// YES IS DEFAULT
export enum AuthState {
	No,
	Yes,
	Any,
}

export interface AppRoute extends RouteProps {
	path: string,
	auth?: AuthState,
	frame?: FC<PropsWithChildren>,
	component: FC<PropsWithChildren>,
}
