import { useCallback, useState } from 'react';
import cn from 'classnames';

import { lang } from '~/constants';
import { feed, Struct } from '~/core';

import { Button } from '../Button';
import { CardPollOption } from './CardPollOption';

export interface CardPollProps {
	feedId: string,
	option?: number,
	options: Struct.PollOption[],
}

export const CardPoll: React.FC<CardPollProps> = (props) => {

	const { feedId, option = 0, options } = props;

	const [ selectedOption, setSelectedOption ] = useState<Struct.PollOption | null>(null);

	const [ vote, { isLoading: isVoting } ] = feed.useVotePollMutation();

	const onOptionSelect = useCallback(
		(option: Struct.PollOption) => setSelectedOption(option),
		[]
	);

	const onPollSubmit = useCallback(
		async () => {

			if (!selectedOption) {
				return;
			}

			const { id, ls_answer_id } = selectedOption;

			const response = await vote({
				item_id: id,
				feed_record_id: feedId,
				ls_item_id: ls_answer_id,
			});

			console.log({ response });

			// if ('error' in response) {
			// 	return handleError('common');
			// }

			setSelectedOption(null);

		},
		[ vote, feedId, selectedOption ]
	);

	const classNames = cn(
		'app-card-poll',
		{ 'app-card-poll_completed': option !== 0 }
	);

	return (
		<div className={classNames}>
			{options.map((_option, i) => (
			<CardPollOption
				key={i}
				option={_option}
				onClick={() => setSelectedOption(_option)}
				disabled={selectedOption?.id !== _option.id && option !== 0}
				isSelected={selectedOption?.id === _option.id || option === _option.id}
				onOptionSelect={onOptionSelect} />
			))}
			{option === 0 &&
			<Button
				onClick={onPollSubmit}
				disabled={isVoting}
				children={lang[`pollSubmit${isVoting ? 'ing' : ''}`]}
				className="app-card__button" />
			}
		</div>
	);

}
