import { useCallback } from 'react';
import cn from 'classnames';

import { Struct } from '~/core';
import { HTMLButton } from '~/types';

export interface CardPollOptionProps extends HTMLButton {
	option: Struct.PollOption,
	isSelected: boolean,
	onOptionSelect: (id: Struct.PollOption) => void,
}

export const CardPollOption: React.FC<CardPollOptionProps> = (props) => {

	const { option, onClick, isSelected, onOptionSelect, ...button } = props;

	const onSelect = useCallback(
		() => void onOptionSelect(option),
		[ onOptionSelect, option ]
	);

	const classNames = cn(
		'app-card-poll__option',
		{
			'app-card-poll__option_selected': isSelected,
		}
	);

	return (
		<button
			{...button}
			onClick={onSelect}
			className={classNames}>
			<p children={option.poll_item} />
		</button>
	);

}
