import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import { FeedScope, isAsset, Media, stringUtils, Transform, useFeedItem } from '~/core';

import { Container } from './Container';
import { ItemActionsDropdown } from './ItemActionsDropdown';
import { CardImage } from './CardImage';
import { CardText } from './CardText';
import { CardBackground } from './CardBackground';
import { Image } from '../Image';
import { Button } from '../Button';
import { Label } from '../Label';
import { CardPoll } from './CardPoll';
import { CardVideo } from './CardVideo';
import { Modal } from '../Modal';
import { PageItem } from './PageItem';

export interface CardProps {
	data: FeedScope.Item | Media.Item,
	hideDrop?: boolean,
}

export const Card: React.FC<CardProps> = (props) => {

	const { data, hideDrop, } = props;

	const {
		type,
		image,
		dropDown,
		title,
		titleLower,
		titleHidden,
		isLink,
		isAudio,
		onClick: onCardClick,
		subtitle,
		icon,
		button,
		buttonAction,
		bgDark,
		bgColor,
		bgImage,
		hasRatio,
		event,
		poll,
	} = useFeedItem(data);

	const [ modal, setModal ] = useState(false);

	const className = cn('app-card', {
		[`app-card_${type}`]: type,
		[`app-card_${bgColor}-bg`]: !!bgColor,
		'app-card_has-bg': !!bgImage,
		'app-card_has-bg-dark': bgDark,
		'app-card_has-image': !!image,
		'app-card_has-ratio': hasRatio === 1,
		'app-card_has-big-ratio': hasRatio === 2,
	});

	const history = useHistory();

	const onButtonClick = useCallback<
		React.MouseEventHandler<HTMLButtonElement>
	>(
		(e) => {
			e.preventDefault();
			e.stopPropagation();
			return typeof buttonAction === 'string' ?
				(stringUtils.url.isExternal(buttonAction) ?
					window.open(buttonAction, '_blank') :
					history.push(buttonAction)) :
				buttonAction?.();
		},
		[ buttonAction, history ]
	);

	const AssetModal = isAsset.asset(data) ? (
		<Modal
			onHide={setModal.bind(null, false)}
			visible={modal}>
			<PageItem
				data={data}
				onModalClose={setModal.bind(null, false)} />
		</Modal>
	) : null;

	const onClick = isAsset.asset(data) ?
		isAsset.supported(data) ?
			setModal.bind(null, true) :
			undefined :
		onCardClick;

	if (!isAsset.asset(data) && data.type === 'video') {
		return (
			<>
				{AssetModal}
				<CardVideo data={data} onClick={onClick} />
			</>
		);
	} else if (isAsset.asset(data) && isAsset.video(data)) {
		return (
			<>
				{AssetModal}
				<CardVideo data={Transform.assetVideoToItem(data)} onClick={onClick} hideDrop />
			</>
		);
	}

	return (
		<Container onClick={onClick} className={className}>

			{(!!dropDown && !hideDrop) &&
				!isAsset.asset(data) &&
			<ItemActionsDropdown
				item={data}
				isLight={dropDown === 'light'} />
			}

			{!!event &&
			<>
				{!!event.isActive && <Label isLive />}
				{(!!event.date && !event.isActive) && <div className="app-card__event-date">{event.date}</div>}
			</>
			}

			{!!image && <CardImage src={image.url} hasPlayIcon={isAudio} />}

			<div className="app-card__content">
				{!!icon &&
				<Image src={icon.url} />
				}
				<CardText
					title={title || ''}
					subtitle={subtitle}
					isLink={isLink}
					isLower={titleLower}
					isAudio={isAudio}
					isHidden={titleHidden}
					assetLabel={isAsset.asset(data) ? isAsset.getLabel(data)[2] : undefined} />
				{!!button &&
				<Button
					onClick={onButtonClick}
					children={button}
					className="app-card__button"
					pressThrough={!buttonAction} />
				}
				{!!poll &&
				<CardPoll {...poll} />
				}
			</div>

			<CardBackground
				src={bgImage?.url}
				hideOverlay={titleHidden || ['gallery'].includes(type)} />

			{AssetModal}

		</Container>
	);

}
