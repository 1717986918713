import { useCallback, useState } from 'react';

import { useLibrary, FeedScope, OrderType, Struct } from '~/core';
import { lang } from '~/constants';

import {
	Page,
	Section,
	CardCategory,
	CardSimplified,
	RecencyFilter,
	Empty,
} from '../components';

export const Library: React.FC = () => {

	const [ order, setOrder ] = useState<OrderType>('recent');

	const {
		data,
		isMoreAvailable,
		isFetching,
		fetchMore,
		isContentEmpty,
		isContentLoading,
	} = useLibrary({ order });

	const renderCategories = useCallback(
		(item: Struct.Category) => <CardCategory key={item.id} data={item} forceLabel />,
		[]
	);

	const renderItems = useCallback(
		(item: FeedScope.Item) => <CardSimplified key={item.id} data={item} />,
		[]
	);

	const recencyFilter = isContentEmpty.feed ? null : (
		<RecencyFilter
			value={order}
			onChange={setOrder} />
	);

	const EmptyCategories = (
		<Empty
			icon="emptyFavorites"
			text={lang.emptyFavorites} />
	);

	const EmptySaved = (
		<Empty
			icon="emptySaved"
			text={lang.emptySaved} />
	);

	const EmptyHistory = (
		<Empty
			icon="emptyHistory"
			text={lang.emptyHistory} />
	);

	return (
		<Page
			title={lang.titleLibrary}
			container
			isContentLoading={isContentLoading}>
			<Section
				data={data.categories}
				title={lang.libraryFavorite}
				listType="horizontal-slider"
				renderItem={renderCategories}
				isContentEmpty={isContentEmpty.categories}
				contentEmptyComponent={EmptyCategories} />
			<Section
				data={data.feed}
				title={lang.librarySaved}
				renderItem={renderItems}
				headContent={recencyFilter}
				hasMore={isMoreAvailable.feed}
				onMoreClick={() => fetchMore('feed')}
				isLoadingMore={isFetching.feed}
				isContentEmpty={isContentEmpty.feed}
				contentEmptyComponent={EmptySaved} />
			<Section
				data={data.history}
				title={lang.libraryHistory}
				renderItem={renderItems}
				hasMore={isMoreAvailable.history}
				onMoreClick={() => fetchMore('history')}
				isLoadingMore={isFetching.history}
				isContentEmpty={isContentEmpty.history}
				contentEmptyComponent={EmptyHistory} />
		</Page>
	);

}
