import { useCallback, useEffect, useMemo, useState } from 'react';
import { FeedScope, Struct, Communication as C } from '../../types';
import { discover } from '../modules';

type DataState = {
	feed: FeedScope.Item[],
	channels: Struct.Category[],
	categories: Struct.Category[],
	page: number,
	total_pages: number,
}

type RunRequestArgs = {
	q: string,
	page: number,
	refresh?: boolean,
	media_type?: C.ReadDiscoverSearchInput['media_type'],
}

export const useSearch = ({ q, media_type }: C.ReadDiscoverSearchInput) => {

	const [ store, setStore ] = useState<Record<number, DataState>>({});

	const [ state, setState ] = useState({
		page: 0,
		total_pages: 0,
	});

	const [ trigger, result ] = discover.useLazyReadDiscoverSearchQuery();

	const data = useMemo(
		() => {

			const _data: {
				feed: FeedScope.Item[],
				channels: Struct.Category[],
				categories: Struct.Category[],
			} = {
				feed: [],
				channels: [],
				categories: [],
			};

			for (const i in store) {
				_data.feed = _data.feed.concat(store[i].feed);
				_data.channels = _data.channels.concat(store[i].channels);
				_data.categories = _data.categories.concat(store[i].categories);
			}

			return _data;

		},
		[ store ]
	);

	const hasMore = useMemo(
		() => state.page + 1 < state.total_pages,
		[ state ]
	);

	const runRequest = useCallback(
		async ({ refresh, ...args }: RunRequestArgs) => {

			const { data } = await trigger(args);

			if (!data) {
				return;
			}

			const { feed, page, channels, categories, total_pages } = data;

			setState({ page, total_pages });

			setStore(
				(val) => ({
					...(refresh ? {} : val),
					[page]: { feed, channels, categories, page, total_pages }
				})
			);

		},
		[]
	);

	const refresh = useCallback(
		() => {

			runRequest({ refresh: true, page: 0, q, media_type });

		},
		[ runRequest, q, media_type ]
	);

	const loadMore = useCallback(
		() => {

			if (!hasMore) {
				return;
			}

			runRequest({ page: state.page + 1, q, media_type });

		},
		[ runRequest, hasMore, state.page, q, media_type ]
	);

	useEffect(
		() => {

			if (!q) {
				return;
			}

			runRequest({ refresh: true, page: 0, q, media_type, })

		},
		[ runRequest, q, media_type ]
	);

	return {
		data,
		refresh,
		loadMore,
		isFetching: result.isFetching,
	};

}
