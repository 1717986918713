import { AppConfig } from '~/core';

const {
	REACT_APP_URL_WEB: webURL,
	REACT_APP_URL_API: apiURL,
	REACT_APP_URL_AUTH: authURL,
	REACT_APP_URL_ASSETS: assetsURL,
} = process.env;

export const config: AppConfig = {
	name: 'FISLL Academy',
	webURL: `https://${webURL}`,
	apiURL: `https://${apiURL}/api/v1`,
	authURL: `https://${authURL}/api/v1`,
	assetsURL: `https://${assetsURL}`,
	isDebug: apiURL?.indexOf('v2-stage') !== -1,
	isLocal: window.location.host.indexOf('local') !== -1,
	appId: '1670299642',
	teamId: '7PRA9DP4XX',
	tokenKey: 'x-access-token',
	cookieKey: '@session_id',
	policyURL: 'https://aimcast.com/policy',
	supportURL: 'https://team.fisll.com/support',
	userAgent: 'aimcast-fisll-app',
	guestTenant: 'FISLL_GUEST',
	authProvider: 4,
	deepLinkProtocol: 'aimcastv2fisll',
};
