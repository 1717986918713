import { lang } from '~/constants';

export const discoverSearchType = {
	all: lang.searchAll,
	videos: lang.searchVideos,
	audio: lang.searchAudio,
	images: lang.searchImages,
}

export type DiscoverSearchType = keyof typeof discoverSearchType;

export const discoverSearchOptions = Object.entries(discoverSearchType) as [
	DiscoverSearchType,
	string
][];
