export type SystemResponse = {
	status: boolean,
	http_status_code: number,
	response_message: string,
	response_internal_code: number,
}

export type WithStatus<T extends {} = {}> = T & {
	status_response?: SystemResponse
}

export type WithPagination<T extends {} = {}> = T & {
	page: number,
	total_pages: number,
}

/**
 * Creates state object type (Pass true as a second argument to add hasMore flag)
 */
export type WithState<T, I extends Boolean = true> = {
	data: T[],
} & (
	I extends true ? { hasMore: boolean } : {}
)

/**
 * Wraps object with default pagination properties (Pass true as a second argument to make page optional)
 */
export type WithPages<T extends {} = {}, I extends Boolean = false> = T & (
	I extends true ? { page?: number } : { page: number }
) & {
	page_size?: number,
}


/**
 * Wraps object with default ordering properties
 */
export type WithOrder<T extends {} = {}> = T & {
	order: OrderType,
}

export type Timestamps = {
	created_at: string,
	updated_at: string,
	published_at: string,
}

export const orderTypeMap = {
	recent: ['favorites_at','desc'],
	oldest: ['favorites_at','asc'],
	alphabetical: ['alphabetical_search','asc'],
};

export type AuthTarget = 'app' | 'web'

export type OrderType = keyof typeof orderTypeMap;

export function formatOrder (type: OrderType, asString?: false): { order: string, order_field: string }
export function formatOrder (type: OrderType, asString?: true): string
export function formatOrder (type: OrderType, asString = false): any {
	const [ order_field, order ] = orderTypeMap[type];
	if (asString) {
		return `order=${order}&order_field=${order_field}`;
	}
	return { order, order_field };
}

export const PAGE_SIZE = 3;

export const hasMorePages = (page: number, totalPages: number) => page + 1 < totalPages;

export type OptionKey = string | number

export type Option<T extends OptionKey = string> = {
	value: T,
	label: string,
}

export const optify = <T extends { id: OptionKey }, C extends OptionKey>(
	data: T[],
	textKey: keyof T
): Option<C>[] => {

	const options = data.map(
		(item) => ({
			value: item.id,
			label: `${item[textKey]}`,
		})
	);

	return options as Option<C>[];

}

export const optifyStrings = (string: string[]): Option[] => {

	const options = string.map(
		(label) => ({
			value: label.toLowerCase(),
			label,
		})
	);

	return options;

}
