import { useCallback } from 'react';
import cn from 'classnames';

import { FeedScope, isAsset, Media, Struct, useFeedPage } from '~/core';
import { lang } from '~/constants';

import { Section } from '../containers';
import { Container } from './Container';
import { Card } from './Card';
import { CardCategory } from './CardCategory';
import { ItemActionsDropdown } from './ItemActionsDropdown';
import { ItemActions } from './ItemActions';
import { Image } from '../Image';
import { Video } from '../Video';
import { PDF } from '../PDF';
import { Iframe } from '../Iframe';
import { Gallery } from './Gallery';
import { Audio } from '../Audio';
import { IconButton } from '../IconButton';

export interface PageItemProps {
	data?: FeedScope.ItemDetailed | Media.Item,
	onModalClose?: () => void,
}

export const PageItem: React.FC<PageItemProps> = (props) => {

	const { data, onModalClose } = props;

	const {
		type,
		image,
		video,
		audio,
		title,
		linked,
		gallery,
		content,
		categories,
	} = useFeedPage(data);

	const isFrame = content?.type !== 'page';

	const container = cn('app-item', {
		[`app-item_${type}`]: !!type,
		'app-container_frame': isFrame,
		'app-container_has-linked': !!linked.length,
	});

	const renderLabel = useCallback(
		(item: Struct.Category) => <CardCategory key={item.id} data={item} forceLabel />,
		[]
	);

	return (
		<Container
			className={container}>

			{!!image &&
			<Image src={image.url} alt={title} />
			}

			{!!video &&
			<Video {...video} />
			}

			<Section
				data={isFrame ? [] : categories}
				title={title}
				listType="horizontal-slider"
				renderItem={renderLabel}
				headContent={
					<>
						{isFrame &&
						!isAsset.asset(data) &&
						<ItemActionsDropdown
							item={data}
							isVertical />
						}
						{!!onModalClose &&
						<IconButton
							icon={'cross'}
							onClick={onModalClose} />
						}
					</>
				} />

			{(!isFrame &&
				!isAsset.asset(data)) &&
			<ItemActions
				item={data}
				className={cn({
					'top-padding': !categories?.length,
					'bottom-padding': !content
				})} />
			}

			{!!content &&
			<div className="app-item__content">

				{content.type === 'page' &&
				<>
					{(type !== 'article' && (audio || video) &&
						typeof content.data === 'string' && content.data) &&
					<p className="app-item__description-label">{lang.itemMediaDescription}</p>
					}
					{typeof content.data === 'string' ?
					<p children={content.data} /> :
					<Card data={content.data} hideDrop />
					}
				</>
				}

				{content.type === 'webview' &&
				<Iframe src={content.data} />
				}

				{content.type === 'pdf' &&
				<PDF data={content.data} />
				}

			</div>
			}

			{!!audio &&
			<Audio {...audio} />
			}

			{!!gallery &&
			<Gallery data={gallery} />
			}

		</Container>
	);

}
