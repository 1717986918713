import { useMemo, useState } from 'react';

import { lang } from '~/constants';
import { dateUtils, truthyFilter, FeedScope, discover } from '~/core';

import { Calendar, CalendarDate } from '../Calendar';
import { CardEvent } from './CardEvent';
import { Container } from './Container';

const initialDate: CalendarDate = {
	cal: new Date(),
	sel: new Date(),
};

const filterDates = ({ object }: FeedScope.ItemEvent) => {
	try {
		return new Date(object.event_date);
	} catch (e) {
		console.warn(`Invalid date: ${object.event_date}`);
	}
}

export const CalendarWrap: React.FC = () => {

	const [ date, setDate ] = useState(initialDate);

	const { events, isFetching } = discover.useReadOrganizerQuery({
		year: dateUtils.parse(date.cal).year,
		month: dateUtils.parse(date.cal).month + 1,
	}, {
		selectFromResult: (result) => ({
			...result,
			events: result.data?.events || [],
		}),
	});

	const takenDates = useMemo(
		() => events.map(filterDates).filter(truthyFilter),
		[ events ]
	);

	const dayEvents = useMemo(
		() => events.filter(({ object }) => (
			dateUtils.compare(
				date.sel,
				new Date(object.event_date)
			)
		)),
		[ date.sel, events ],
	);

	return (
		<Container
			hasLoader
			isLoading={isFetching}
			className="app-calendar__wrap">
			<Calendar
				date={date}
				takenDates={takenDates}
				onDateChange={setDate} />
			<div className="app-calendar__list">
				{dayEvents.length === 0 &&
				<p className="app-calendar_list-empty" children={lang.noEvents} />
				}
				{dayEvents.map((event) => (
				<CardEvent key={event.id} event={event} />
				))}
			</div>
		</Container>
	);

}
