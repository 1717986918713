import { stringUtils } from '~/core';

export interface CardBackgroundProps {
	src?: string,
	hideOverlay?: boolean,
}

export const CardBackground: React.FC<CardBackgroundProps> = (props) => {

	const { src, hideOverlay } = props;

	if (!src) {
		return null;
	}

	return (
		<div className="app-card-background">
			{!hideOverlay &&
			<i
				className="overlay" />
			}
			<i
				className="image"
				style={{ backgroundImage: `url(${stringUtils.url.getAssetURL(src)})` }} />
		</div>
	);

}
