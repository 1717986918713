import {
	EventSourcePolyfill as EventSource,
	EventListener,
	MessageEvent,
} from 'event-source-polyfill';

import { Struct, isNotification } from '~/core';
import { SessionAdapter } from './session';

type SubscribeEvent = {
	type: 'notification',
	payload: Struct.Notification,
} |
{
	type: 'sync',
	payload: {
		profile_id: string,
	}
}

type SubscribePayload = {
	type: 'notification',
	object: Struct.Notification,
} | {
	type: 'user_profile_access_sync',
	object: {
		profile_id: string,
		force_sync: boolean,
	},
}

type SubscribeCallback = (evt: SubscribeEvent) => void

export const subscribeNotify = (
	url: string,
	callback: SubscribeCallback
) => {

	let source: EventSource | undefined;

	const listener: EventListener = (event) => {

		switch (event.type) {
			case 'message':

				const message = event as MessageEvent;

				if (!message.data) {
					return;
				}

				const { type, object } = JSON.parse(message.data) as SubscribePayload;

				switch (type) {
					case 'notification':
						isNotification(object) &&
							callback({ type: 'notification', payload: object, });
							break;
					case 'user_profile_access_sync':
						callback({ type: 'sync', payload: object, })
							break;
				}

				break;

			default:
				break;
		}

	}

	const subscribe = async () => {

		const sid = await SessionAdapter.get();

		if (!sid || sid.includes('GUEST')) {
			return;
		}

		source = new EventSource(url, {
			heartbeatTimeout: 180 * 5000,
			headers: {
				[SessionAdapter.getTokenKey()]: sid
			}
		});

		source.addEventListener('open', listener);
		source.addEventListener('error', listener);
		source.addEventListener('message', listener);

	}

	const unsubscribe = () => {

		if (!source) {
			return;
		}

		source.removeEventListener('open', listener);
		source.removeEventListener('error', listener);
		source.removeEventListener('message', listener);
		source.close();

	}

	subscribe();

	return unsubscribe;

}
