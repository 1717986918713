import { Option } from '../types';

function isAdded <T extends Option<any>>(arr: T[], opt: T): boolean {

	return arr.filter(({ value }) => value === opt.value).length > 0;

}

function addOrRemove <T extends Option<any>>(arr: T[], opt: T): T[] {

	if (!isAdded(arr, opt)) {
		return [ ...arr, opt ];
	}

	return arr.filter(({ value }) => value !== opt.value);

}

const chunkify = <T,>(arr: T[], size: number) => {

	const chunks = [];

	for (let i = 0; i < arr.length; i += size) {
		chunks.push(arr.slice(i, i + size));
	}

	return chunks;

}


export const arrayUtils = {
	isAdded,
	addOrRemove,
	chunkify,
};
