import { useMemo } from 'react';
import { matchPath, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AppRoute, AuthState } from '~/types';

import { AppError, parseError } from '~/core';
import { Error } from '../../screens';

export interface AuthProps {
	routes: AppRoute[],
	error?: AppError,
	isAuthorized: boolean,
	isAuthorizing: boolean,
}

export const Auth: React.FC<AuthProps> = (props) => {

	const { routes, error, isAuthorized, isAuthorizing } = props;

	const { pathname } = useLocation();

	const _routes = useMemo<AppRoute[]>(
		() => {

			if (error && parseError(error).status !== 201) {
				return [{ path: '/', component: Error }];
			}

			if (isAuthorizing) {
				return [];
			}

			return routes.filter(
				({ auth = AuthState.Yes }) =>
					(auth === AuthState.Any) ||
					(auth === AuthState.Yes && isAuthorized) ||
					(auth === AuthState.No && !isAuthorized)
			);

		},
		[ routes, error, isAuthorized, isAuthorizing ]
	);

	const route = useMemo<AppRoute | null>(
		() => {

			return _routes.filter(
				(data) => matchPath(pathname, data)
			)[0] || null;

		},
		[ _routes, pathname ]
	);

	const children = (
		<Switch>
			{_routes.map((route, i) => (
			<Route key={i} {...route} />
			))}
			{_routes[0] && <Redirect to={_routes[0].path as string} />}
		</Switch>
	);

	return route?.frame ?
		<route.frame children={children} /> :
		children;

}
