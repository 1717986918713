import { useHistory, useLocation } from 'react-router-dom';

import { lang, ROUTES } from '~/constants';
import { AppError, parseError } from '~/core';
import { Button } from '../Button';

import { Page } from '../containers';
import { Icon } from '../Icon';

export const isNotFound = (e?: AppError) => {
	const { status } = parseError(e);
	return status === 300 || status === 900;
}

export const NotFound: React.FC = () => {

	const { key } = useLocation();

	const { goBack, push } = useHistory();

	return (
		<Page
			onGoBack
			container="container-not-found">
			<Icon name="emptyHistory" />
			<h1 children={lang.itemNotFoundTitle} />
			<p children={lang.itemNotFoundMessage} />
			{key ?
			<Button onClick={goBack}>{lang.buttonGoBack}</Button> :
			<Button onClick={() => push(ROUTES.feed)}>{lang.buttonExploreFeed}</Button>
			}
		</Page>
	);

}
