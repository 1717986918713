import { api } from '../api';
import { Communication as C } from '../../types';
import { config } from '~/constants';
import { SessionAdapter, DeviceAdapter, hasSignedOut } from '~/base';
import { objectToQuery } from '../../utils';

import { feed } from './Feed';
import { AppError, parseError } from '../helpers';

export const auth = api.injectEndpoints({
	endpoints: (builder) => ({
		readAuth: builder.query<C.ReadAuthOutput, void>({
			query: () => '/user/accounts',
			onQueryStarted: async (_, { queryFulfilled }) => {

				try {
					await queryFulfilled;
				} catch (e) {
					if (parseError(e as AppError).status === 201) {
						await SessionAdapter.unset();
					}
				}

			},
		}),
		userAuth: builder.mutation<void, C.UserAuthInput>({
			query: ({ redirect_path, provider = config.authProvider, from = 'app', ...body }) => ({
				url: `${config.authURL}/process/auth-request?${objectToQuery({
					...body,
					redirect_path: redirect_path ?
						encodeURIComponent(redirect_path) :
						undefined,
					provider,
					from,
					dest: config.isLocal ? 'local' : undefined
				})}`,
				method: 'POST',
			}),
		}),
		signUp: builder.mutation<C.UserSignUpOutput, C.UserSignUpInput>({
			query: ({
				registration_without_tenant,
				redirect_path,
				provider = config.authProvider,
				from = 'app',
				...body
			}) => ({
				url: '/user/accounts/self-registration',
				body: JSON.stringify({
					...body,
					from,
					dest: config.isLocal ? 'local' : undefined,
					sso_id: `${provider}`,
					invite_code: body.invite_code ?
						Number(body.invite_code) :
						undefined,
					redirect_path: redirect_path ?
						encodeURIComponent(redirect_path) :
						undefined,
					registration_without_tenant: !body.invite_code,
				}),
				method: 'POST',
			}),
		}),
		guestAuth: builder.mutation<C.GuestAuthOutput, C.GuestAuthInput>({
			query: ({ tenant = config.guestTenant }) => ({
				url: `/user/${tenant ? 'custom-' : ''}guest`,
				body: {
					uuid: DeviceAdapter.getUniqueId(),
					tenant,
				},
				method: 'POST',
			}),
			onQueryStarted: async (_, { dispatch, queryFulfilled }) => {

				try {

					const { data } = await queryFulfilled;

					await SessionAdapter.set(data.session_token);

					await dispatch(
						feed.endpoints.readRequiredPolls.initiate()
					);

					dispatch(
						auth.endpoints.readAuth.initiate()
					);

				} catch (e) {
					console.warn('Guest Auth Error:', e);
				}

			},
		}),
		signOut: builder.mutation<void, C.SignOutInput>({
			query: () => ({
				url: '/user/sessions',
				method: 'DELETE',
			}),
			onQueryStarted: async ({ force }, { dispatch, queryFulfilled }) => {

				try {

					await queryFulfilled;

					await SessionAdapter.unset();

					await hasSignedOut(force ? 'unset' : 'set');

					dispatch(
						api.util.resetApiState()
					);

				} catch (e) {
					console.warn('Sign Out Error:', e);
				}

			},
		}),
		exchangeToken: builder.mutation<C.GuestAuthOutput, C.ExchangeTokenInput>({
			query: (body) => ({
				url: '/user/auth/exchange',
				body,
				method: 'POST',
			}),
			onQueryStarted: async (_, { dispatch, queryFulfilled }) => {

				try {

					const { data } = await queryFulfilled;

					await SessionAdapter.set(data.session_token);

					await dispatch(
						feed.endpoints.readRequiredPolls.initiate()
					);

					dispatch(
						auth.endpoints.readAuth.initiate()
					);

				} catch (e) {
					console.warn('User Auth Error:', e);
				}

			},
		}),
		uploadPhoto: builder.mutation<C.UploadPhotoOutput, C.UploadPhotoInput>({
			query: ({ photo }) => {
				const body: FormData = new FormData();
							body.append('photo', photo);
				return {
					url: '/user/upload-photo',
					body,
					method: 'POST',
				};
			},
			onQueryStarted: async (_, { dispatch, queryFulfilled }) => {

				try {

					const { data } = await queryFulfilled;

					dispatch(
						auth.util.updateQueryData(
							'readAuth',
							undefined,
							({ account }) => {
								account.avatar = data.path;
							},
						)
					);

				} catch (e) {

					console.warn('An error occured:', e);

				}

			},
		}),
	}),
	overrideExisting: true,
});
