import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './query';

export const api = createApi({
	tagTypes: ['LibraryContent'],
	baseQuery,
	endpoints: () => ({}),
	// refetchOnFocus: true,
	// refetchOnReconnect: true,
});
