import { FeedScope, Media, Struct } from '../../types';
import { isAsset } from './typeguards';

const common = {
	id: 0,
	created_at: '',
	updated_at: '',
}

export function getEmptyCategory (): Struct.Category {
	return {
		icon: 'benefits',
		type: 'category',
		title: 'Calendar',
		is_root: true,
		favorite: false,
		published: true,
		tenant: null,
		tenant_name: '',
		published_at: '',
		hide_title: false,
		notifications_enabled: false,
		...common,
	}
}

export function getEmptyImage (url: string = ''): Media.Image {
	return {
		url,
		ext: '.png',
		width: 0,
		height: 0,
		hash: '',
		mime: 'image/png',
		name: '',
		size: 0,
		cdn_id: '',
		caption: '',
		provider: 'local',
		alternative_text: '',
		...common,
	};
}

export function getEmptyVideo (id: number = 0, url: string, poster: string = '', title: string = ''): FeedScope.ItemVideo['object'] {
	return {
		title,
		video: {
			...getEmptyImage(),
			url,
			ext: '.MP4',
			mime: 'video/mp4',
		},
		content: '',
		published_at: '',
		analyst_title: '',
		cropped_image: { ...getEmptyImage(), },
		feed_record_id: `asset-video-${id}`,
		background_image: { ...getEmptyImage(poster), },
		...common,
	};
}

const commonProperties = {
	id: '',
	pinned: false,
	favorite: false,
	shareable: false,
	published: true,
	updated_at: '',
	created_at: '',
	published_at: '',
	comments_count: 0,
	comments_enabled: false,
	linked_items_count: 0,
	linked_assets_count: 0,
}

const linkedProperties = {
	linked_assets: [],
	linked_feed_items: [],
}

export function getEmptyItem (): FeedScope.ItemDetailed {
	return {
		...commonProperties,
		...linkedProperties,
		type: 'article',
		object: {
			id: 0,
			title: '',
			subtitle: '',
			created_at: '',
			updated_at: '',
			published_at: '',
			analyst_title: '',
			feed_record_id: '',
		},
	};
}

function assetVideoToCard (asset: Media.Video): FeedScope.ItemVideo {
	return {
		type: 'video',
		object: getEmptyVideo(asset.id, asset.url, asset.formats?.thumbnail?.url, asset.name),
		...commonProperties,
	};
}

function assetAudioToCard (asset: Media.Audio): FeedScope.ItemAudio {
	return {
		type: 'audio-record',
		object: {
			id: 0,
			analyst_title: '',
			created_at: '',
			published_at: '',
			updated_at: '',
			image: {
				...getEmptyImage('asset-image'),
				width: 1,
				height: 1,
			},
			cropped_image: {
				...getEmptyImage('asset-image'),
			},
			audio: asset,
			title: asset.name,
			content: asset.text_description || '',
			subtitle: asset.text_description || '',
			track_name: '',
			artist_name: '',
			content_title: '',
			feed_record_id: `asset-audio-${asset.id}`,
		},
		...commonProperties,
	};
}

function assetImageToCard (asset: Media.Image): FeedScope.ItemGallery {
	return {
		type: 'gallery',
		object: {
			id: 0,
			title: asset.name,
			images: [{
				id: 0,
				image: asset,
				title: asset.name,
			}],
			created_at: '',
			updated_at: '',
			published_at: '',
			analyst_title: '',
			feed_record_id: `asset-image-${asset.id}`,
		},
		...commonProperties,
	};
}

function assetFileToCard (asset: Media.File): FeedScope.ItemArticle {
	return {
		type: 'article',
		object: {
			id: 0,
			title: asset.name,
			cropped_image: {
				...getEmptyImage(`asset-${isAsset.supported(asset) ? 'application' : 'unknown'}`),
			},
			subtitle: '',
			created_at: '',
			updated_at: '',
			published_at: '',
			analyst_title: '',
			feed_record_id: `asset-file-${asset.id}`,
		},
		...commonProperties,
	};
}

function assetVideoToItem (asset: Media.Video): FeedScope.ItemDetailedVideo {
	return {
		...assetVideoToCard(asset),
		...linkedProperties,
	};
}

function assetAudioToItem (asset: Media.Audio): FeedScope.ItemDetailedAudio {
	return {
		...assetAudioToCard(asset),
		...linkedProperties,
	};
}

function assetImageToItem (asset: Media.Image): FeedScope.ItemDetailedGallery {
	return {
		...assetImageToCard(asset),
		...linkedProperties,
	};
}

function assetFileToItem (asset: Media.File): FeedScope.ItemDetailedArticle {
	return {
		...assetFileToCard(asset),
		object: {
			...assetFileToCard(asset).object,
			file: asset,
		},
		...linkedProperties,
	};
}

function assetToItem (asset: Media.Item): FeedScope.ItemDetailed {
	if (isAsset.video(asset)) {
		return assetVideoToItem(asset);
	} else if (isAsset.audio(asset)) {
		return assetAudioToItem(asset);
	} else if (isAsset.image(asset)) {
		return assetImageToItem(asset);
	} else if (isAsset.file(asset)) {
		return assetFileToItem(asset);
	}
	return {
		...getEmptyItem(),
	};
}

export const Transform = {
	assetVideoToCard,
	assetVideoToItem,

	assetAudioToCard,
	assetAudioToItem,

	assetImageToCard,
	assetImageToItem,

	assetFileToCard,
	assetFileToItem,

	assetToItem,
}

