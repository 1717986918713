import { api } from '../api';
import { Communication as C, formatOrder } from '../../types';
import { objectToQuery } from '../../utils';

import { filterFeedItems } from '../helpers';

export const library = api.injectEndpoints({
	endpoints: (builder) => ({
		readLibrary: builder.query<C.ReadLibraryOutput, C.ReadLibraryInput>({
			query: ({ page_size = 3, order }) => {
				return `/feed/library?${objectToQuery({ page_size, ...formatOrder(order) })}`;
			},
			transformResponse: (response: C.ReadLibraryOutput) => {
				return {
					...response,
					history: filterFeedItems(response.history),
					feed: filterFeedItems(response.feed),
				};
			},
			providesTags: ['LibraryContent'],
		}),
		readLibraryFeed: builder.query<C.ReadLibraryFeedOutput, C.ReadLibraryFeedInput>({
			query: ({ page, page_size = 3, order }) => {
				return `/feed/favorites?${objectToQuery({ page, page_size, ...formatOrder(order) })}`;
			},
		}),
		readLibraryHistory: builder.query<C.ReadLibraryHistoryOutput, C.ReadLibraryHistoryInput>({
			query: ({ page, page_size = 3 }) => {
				return `/feed/history?${objectToQuery({ page, page_size })}`;
			},
		}),
	}),
	overrideExisting: true,
});
