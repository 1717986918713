export const STAGE_STORAGE_KEY = 'workspace-create-stage';
export const STAGES = [
	'INITIAL',
	'INVITES',
	'FINISHED',
] as const

export const STAGE_TITLE = {
	INITIAL: 'Create your workspace',
	INVITES: 'Invite colleagues by email',
	FINISHED: 'Great job, your workspace is ready!',
} as const

export const TENANT_DETAILS_KEY = 'tenant-details';

export function parseTenantDetails (json: string) {

	try {

		const data = JSON.parse(json) as {
			name: string,
			code: string,
		}

		if (!data.name || !data.code) {
			throw new Error();
		}

		return data

	} catch (e) {
		return {
			name: '',
			code: '',
		}
	}

}
