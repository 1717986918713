import { useCallback, useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import { comment } from '../modules';
import { groupReplies } from '../helpers';

const { useLazyQuery, useQueryState } = comment.endpoints.readComments;

export const useComments = (id?: string) => {

	const [ trigger ] = useLazyQuery();

	const [ page, setPage ] = useState(0);

	const [ isLoadingMore, setIsLoadingMore ] = useState(false);

	const state = useQueryState(
		id ? { id, page: 0 } : skipToken,
		{
			selectFromResult: ({ data, ...state }) => ({
				comments: groupReplies(data?.comments || []),
				more: data?.more || false,
				...state,
			}),
		}
	);

	useEffect(
		() => {

			if (!id) {
				return;
			}

			void trigger({ id, page: 0 }, true);

		},
		[ id, trigger ]
	);

	const refresh = useCallback(
		async () => {

			if (!id) {
				return;
			}

			try {

				if (state.isFetching) {
					return;
				}

				await trigger({ id, page: 0 }, true);

				setPage(0);

			} catch {}

		},
		[ trigger, id, state.isFetching ]
	);

	const loadMore = useCallback(
		async () => {

			if (!id) {
				return;
			}

			try {

				if (!state.more || state.isFetching) {
					return;
				}

				setIsLoadingMore(true);

				await trigger({ id, page: page + 1 }, true);

				setPage(page => page + 1);

			} catch {} finally {

				setIsLoadingMore(false);

			}

		},
		[ trigger, id, page, state.more, state.isFetching ]
	);

	return {
		...state,
		refresh,
		loadMore,
		isLoadingMore,
	};

}
