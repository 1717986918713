export interface IAccount {
	id: string,
	name: string,
	email: string,
	alias: string,
	phone: string,
	avatar: string,
	tenant: string,
	groups: string,
	gender: string,
	blocked: boolean,
	birthday: number,
	position: string,
	full_name: string,
	last_name: string,
	last_seen: number,
	user_type: number,
	created_at: number,
	updated_at: number,
	middle_name: string,
	position_title: string,
	profile_completed: boolean,
	first_time_logged_in: boolean,
}

export const emptyAccount: IAccount = {
	id: '',
	name: '',
	email: '',
	alias: '',
	phone: '',
	avatar: '',
	tenant: '',
	groups: '',
	gender: '',
	blocked: false,
	birthday: 0,
	position: '',
	full_name: '',
	last_name: '',
	last_seen: 0,
	user_type: 0,
	created_at: 0,
	updated_at: 0,
	middle_name: '',
	position_title: '',
	profile_completed: false,
	first_time_logged_in: false,
};
