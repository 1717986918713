import { FeedScope } from '~/core';

import { Video } from '../Video';
import { SmartLink } from '../SmartLink';
import { ItemActionsDropdown } from './ItemActionsDropdown';

export interface CardVideoProps {
	data: FeedScope.ItemVideo,
	onClick?: string | (() => void),
	hideDrop?: boolean,
}

export const CardVideo: React.FC<CardVideoProps> = (props) => {

	const { data, onClick, hideDrop } = props;

	const {
		id,
		object: {
			url,
			title,
			video,
			background_image
		},
	} = data;

	return (
		<SmartLink to={onClick} asDiv className="app-card app-card_video">
			{!hideDrop &&
			<ItemActionsDropdown item={data} isLight />
			}
			<Video
				title={title}
				video={video || url}
				poster={background_image}
				feedId={id} />
		</SmartLink>
	);

}
