import { useMemo } from 'react';

import { getPath } from '~/constants';
import { FeedScope } from '~/core';

import { CardImage } from './CardImage';
import { Label } from '../Label';
import { SmartLink } from '../SmartLink';

export interface CardSimplifiedProps {
	data: FeedScope.Item,
}

export const CardSimplified: React.FC<CardSimplifiedProps> = (props) => {

	const { data } = props;

	const to = useMemo(
		() => {

			const { id, type, object } = data;

			if (
				(type === 'external-link' || type === 'banner') &&
				!object.open_in_webview &&
				object.url
			) {
				return object.url;
			}

			return getPath('item', { id });

		},
		[ data ]
	);

	const { type, object } = data;

	return (
		<SmartLink to={to} className="app-card-simplified">
			<CardImage
				src={'cropped_image' in object ? object.cropped_image?.url : ''}
				className="app-card-simplified__image"
				hasPlayIcon={
					(type === 'article' && object.article_with_video) ||
					type === 'audio-record' ||
					type === 'video'
				} />
			<div className="app-card-simplified__content">
				{type === 'audio-record' && (
				<Label
					icon="headphones"
					text="Listen"
					isBadge />
				)}
				<h4>{object.title}</h4>
			</div>
		</SmartLink>
	);

}
