import Cookies from 'universal-cookie';
import { v4 } from 'uuid';

const cookies = new Cookies();
const cookieKey = 'UDID';

export const DeviceAdapter = {
	getUniqueId: () => {

		let uid = cookies.get(cookieKey);

		if (!uid) {
			uid = v4();
			cookies.set(cookieKey, uid, { path: '/' });
		}

		return uid;

	},
};
