import { FeedScope, FILE_TYPE_MAP, Media, stringUtils } from '~/core';

function getType ({ mime }: Media.Item): Media.Type {
	return stringUtils.parseExtension(mime)[0];
}

function item (data?: unknown): data is FeedScope.ItemDetailed {
	return (data as FeedScope.ItemDetailed).id !== '';
}

function asset (data?: unknown): data is Media.Item {
	const item = data as Media.Item;
	return data ? ('id' in item) && ('mime' in item) : false;
}

function image (asset: Media.Item): asset is Media.Image {
	return getType(asset) === 'image';
}

function audio (asset: Media.Item): asset is Media.Audio {
	return getType(asset) === 'audio';
}

function video (asset: Media.Item): asset is Media.Video {
	return getType(asset) === 'video';
}

function file (asset: Media.Item): asset is Media.File {
	return getType(asset) === 'application';
}

function supported ({ mime }: Media.Item) {
	const [ t, e ] = stringUtils.parseExtension(mime);
	return t !== 'application' ||
		t === 'application' && /pdf/i.test(e);
}

function getLabel (asset: Media.Item): [
	type: Media.Type,
	extension: string,
	label: string
] {
	let [ type, extension ] = stringUtils.parseExtension(asset.mime);
	if (!supported(asset)) {
		type = 'unknown';
	}
	return [ type, extension, FILE_TYPE_MAP[type](extension) ];
}

export const isAsset = {
	item,
	asset,
	image,
	audio,
	video,
	file,
	supported,
	getType,
	getLabel,
}
