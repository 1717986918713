import { getPath, lang } from '~/constants';
import { FeedScope, Media, Struct } from '../../types';
import { dateUtils, stringUtils } from '../../utils';
import { CardPollProps } from '~/base';
import { Calendar } from '~/core';
import { isAsset, Transform } from '../helpers';

type UseFeedItemOutput = {
	type: FeedScope.ItemType,
	image?: Media.Image,
	title?: string,
	titleLower?: boolean,
	titleHidden?: boolean,
	isLink?: string,
	isAudio?: boolean,
	subtitle?: string,
	onClick?: string | (() => void),
	dropDown?: true | 'light',
	icon?: Media.Image,
	button?: string,
	buttonColor?: string,
	buttonAction?: string | (() => void),
	bgDark?: boolean,
	bgColor?: Struct.AppearanceType,
	bgImage?: Media.Image,
	hasRatio?: 1 | 2,
	poll?: CardPollProps,
	event?: {
		date: string,
		isActive: boolean,
	},
}

function getItemPath (id: string | number) {
	return id ? getPath('item', { id: `${id}` }) : undefined;
}

export const useFeedItem = (item: FeedScope.Item | Media.Item): UseFeedItemOutput => {

	const { id, type, object } = isAsset.asset(item) ?
		Transform.assetToItem(item) :
		item;

	const common: UseFeedItemOutput = {
		type,
		title: object.title,
		bgColor: 'gradient' in object ? object.gradient : undefined,
		dropDown: 'light',
		subtitle: 'subtitle' in object ? object.subtitle : undefined,
	};

	switch (type) {

		case 'article':
		case 'audio-record':
		case 'external-link':
			return {
				...common,
				image: object.cropped_image,
				isLink: 'url' in object ?
					stringUtils.url.getName(object.url) :
					undefined,
				isAudio: type === 'audio-record',
				onClick: type === 'external-link' && !object.open_in_webview ?
					object.url :
					getItemPath(id),
				dropDown: true,
			};

		case 'poll': {
			if (object.poll_type === 'web_view_survey') {
				return {
					...common,
					button: lang.cardTakeSurvey,
					onClick: getItemPath(id),
				};
			} else {
				return {
					...common,
					poll: {
						feedId: id,
						option: object.poll_answer_id,
						options: object.poll_items,
					},
				};
			}
		}

		case 'event': {
			const onClick = object.event_active ?
				getItemPath(id) :
				undefined;
			return {
				...common,
				icon: object.icon,
				button: lang[`event${object.event_active ? 'Join' : 'AddCalendar'}`],
				onClick,
				buttonColor: object.button_color,
				hasRatio: 1,
				event: {
					date: dateUtils.formatEventDate({
						start: dateUtils.parseValid(object.event_date),
						end: dateUtils.parseValid(object.event_end_at),
					}),
					isActive: object.event_active,
				},
				buttonAction: () => {

					if (!('type' in item) || item.type !== 'event') {
						return;
					}

					Calendar.eventToCalendar(item);

				},
			};
		}

		case 'banner': {
			const onClick = object.url && !object.open_in_webview ?
				object.url :
				getItemPath(id);
			return {
				...common,
				icon: object.icon,
				onClick,
				bgImage: object.background_image,
				button: object.button_text,
				buttonColor: object.button_color,
				titleHidden: object.hide_title,
				hasRatio: 1,
			};
		}

		case 'video':
		case 'gallery':
			return {
				...common,
				bgDark: true,
				bgImage: 'images' in object ?
					object.images?.[0]?.image :
					'background_image' in object ?
						object.background_image :
						undefined,
				onClick: getItemPath(id),
				hasRatio: 2,
				titleLower: true,
			};

		default:
			return common;

	}

}
