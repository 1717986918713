import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { lang } from '../../constants';

type Details<T> = Record<
	number,
	[ message: string, field?: keyof T ]
>

type ErrorHandlerResonse<T> = {
	originalMessage?: string,
	message: string,
	field?: keyof T,
}

export function errorHandler <T>(
	error: FetchBaseQueryError | SerializedError,
	details: Details<T> = {},
	defaultMessage: string = lang.unspecifiedError,
): ErrorHandlerResonse<T> {

	if ('status' in error) {

		const { status, data } = error;

		const _defaultMessage = typeof data === 'string' ?
			data :
			defaultMessage;

		if (typeof status !== 'number' || !details[status]) {
			return {
				message: _defaultMessage,
			}
		}

		return {
			originalMessage: _defaultMessage,
			message: details[status][0],
			field: details[status]?.[1],
		}

	}

	return {
		message: defaultMessage,
	}

}
