import cn from 'classnames';
import { stringUtils } from '~/core';
import { Button } from './Button';

import { Image, ImageProps } from './Image';

export interface AvatarProps extends ImageProps {
	name?: string,
	size?: 'small' | 'big',
	isGrey?: boolean,
	onIconClick?: () => void,
	isIconDisabled?: boolean,
}

export const Avatar: React.FC<AvatarProps> = (props) => {

	const { name, size, isGrey, onIconClick, isIconDisabled, ...args } = props;

	const className = cn(
		'app-avatar',
		{
			'app-avatar_grey': isGrey,
			'app-avatar_empty': !args.src,
			[`app-avatar_${size}`]: !!size,
		},
	);

	return (
		<div className={className}>
			{args.src ?
			<Image {...args} /> :
			<p
				children={name && stringUtils.abbrevifyName(name)}
				className="app-avatar__name" />
			}
			{!!onIconClick &&
			<Button
				icon="camera"
				isRound
				onClick={onIconClick}
				disabled={isIconDisabled}
				className="app-avatar__button" />
			}
		</div>
	);

}
