import { api } from '../api';
import { Communication as C } from '../../types';

export const feedDetailed = api.injectEndpoints({
	endpoints: (builder) => ({
		readFeedDetailed: builder.query<C.ReadFeedDetailedState, C.ReadFeedDetailedInput>({
			query: (id) => `/feed/record?id=${id}`,
			transformResponse: (response: C.ReadFeedDetailedOutput) => {
				return response.feed;
			}
		}),
	}),
	overrideExisting: true,
});
