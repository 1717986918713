import { useCallback, useMemo } from 'react';
import cn from 'classnames';

import { arrayUtils, dateUtils } from '~/core';
import { IconButton } from './IconButton';

const getCalendar = (month: number, year: number) => {

	const monthName = dateUtils.months[month - 1];

	const firstDay = new Date(year, month - 1, 1);
	const lastDay = new Date(year, month, 0);

	const firstDayNum = firstDay.getDay();
	const daysCount = lastDay.getDate();

	const cal = [];

	for (let i = 0; i < firstDayNum - 1; i++) {
		cal.push(null);
	}

	for (let i = 1; i <= daysCount; i++) {
		cal.push(new Date(year, month - 1, i));
	}

	const calendar = arrayUtils.chunkify(cal, 7);
	const lastWeek = calendar[calendar.length - 1];

	if (lastWeek.length < 7) {
		lastWeek.push(
			...(new Array(7 - lastWeek.length).fill(null))
		);
	}

	return {
		year,
		calendar,
		monthName,
	};

}

export type CalendarDate = {
	cal: Date,
	sel: Date,
}

export interface CalendarProps {
	date: CalendarDate,
	takenDates?: Date[],
	onDateChange?: (date: CalendarDate) => void,
}

export const Calendar: React.FC<CalendarProps> = (props) => {

	const {
		date,
		takenDates = [],
		onDateChange,
	} = props;

	const { year, calendar, monthName } = getCalendar(
		dateUtils.parse(date.cal).month + 1,
		dateUtils.parse(date.cal).year,
	);

	const simpleDates = useMemo(
		() => takenDates.map((d) => dateUtils.stringify(d)),
		[ takenDates ]
	);

	const _onDateChange = useCallback(
		(dir: 0 | 1 | null, day?: Date) => {

			if (dir === null && day) {
				onDateChange?.({ ...date, sel: day });
				return;
			}

			const { year, month } = dateUtils.parse(date.cal);

			if (month === 0 && dir === 0) {

				date.cal.setMonth(11);
				date.cal.setFullYear(year - 1);

				return void onDateChange?.({ ...date });

			}

			if (month === 11 && dir === 1) {

				date.cal.setMonth(0);
				date.cal.setFullYear(year + 1);

				return void onDateChange?.({ ...date });

			}

			date.cal.setMonth(dir === 0 ? month - 1 : month + 1);

			onDateChange?.({ ...date });

		},
		[ date, onDateChange ]
	);

	return (
		<div className="app-calendar">
			<div className="app-calendar__head">
				<IconButton
					icon="leftChevron"
					onClick={() => _onDateChange(0)} />
				<h2>{monthName}, {year}</h2>
				<IconButton
					icon="rightChevron"
					onClick={() => _onDateChange(1)} />
			</div>
			<div className="app-calendar__body">
				<div className="app-calendar__row">
					{dateUtils.days.map((day, i) => (
					<div key={i} className="app-calendar__cell">
						<p>{day}</p>
					</div>
					))}
				</div>
				{calendar.map((week, i) => (
				<div key={i} className="app-calendar__row">
					{week.map((day, i2) => {

						const { isSelected, hasEvents, } = dateUtils.getDateState(
							day,
							date.sel,
							simpleDates,
						);

						const className = cn(
							'app-calendar__cell',
							{
								'is-selected': isSelected,
								'is-clickable': day && !isSelected,
							},
						);

						const onClick = () => day && !isSelected && _onDateChange(null, day)

						return (
							<button key={i2} onClick={onClick} className={className}>
								<p children={day?.getDate()} />
								{hasEvents && <i className="app-calendar__event-dot" />}
							</button>
						);

					})}
				</div>
				))}
			</div>
		</div>
	);

}
