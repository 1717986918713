import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { Link, useHistory } from 'react-router-dom';

import { config, getPath, lang, ROUTES } from '~/constants';
import { auth, feed } from '~/core';

import { Button, Input, Loader, AuthLogo, AuthWrapper } from '../components';
import { hasSignedOut } from '../services';

export const Login: React.FC = () => {

	const [ email, setEmail ] = useState('');

	const [ token ] = useQueryParam('token', StringParam);

	const [ redirectPath ] = useQueryParam('redirect_path', StringParam);

	const { replace } = useHistory();

	const [ authRead ] = auth.useLazyReadAuthQuery();

	const [ authAsUser, { isLoading: isUserLoading } ] = auth.useUserAuthMutation();

	const [ authAsGuest, { isLoading: isGuestLoading } ] = auth.useGuestAuthMutation();

	const [ isExchangingToken, setIsExchangingToken ] = useState(false);

	const { isLoading: isPollsLoading } = feed.endpoints.readRequiredPolls.useQueryState();

	const [ exchangeToken ] = auth.useExchangeTokenMutation();

	const isLoading = isUserLoading ||
		isGuestLoading ||
		isPollsLoading;

	const [ sent, setSent ] = useState(false);

	const handleSubmit = useCallback(
		async (e: FormEvent<HTMLFormElement>) => {

			e.preventDefault();

			if (!email) {
				return;
			}

			await authAsUser({ email, from: 'web' });

			setSent(true);

		},
		[ email, authAsUser ]
	);

	const continueAsGuest = useCallback(
		async () => {
			await authAsGuest({});
		},
		[ authAsGuest ]
	);

	const autoGuestAuth = useCallback(
		async () => {
			config.guestTenant && !(await hasSignedOut()) && authAsGuest({});
		},
		[ authAsGuest ]
	);

	useEffect(
		() => {
			autoGuestAuth();
		},
		[ autoGuestAuth ]
	);

	const applyToken = useCallback(
		async () => {

			if (!token) {
				return;
			}

			setIsExchangingToken(true);

			await exchangeToken({ code: token });

			await authRead();

			redirectPath && replace(
				Object.values(ROUTES).includes(redirectPath) ?
					redirectPath :
					ROUTES.login
			);

		},
		[ token, redirectPath, exchangeToken, replace ]
	);

	useEffect(
		() => void applyToken(),
		[ applyToken ]
	);

	return (
		<AuthWrapper
			onSubmit={handleSubmit}
			hasBackButton={!!sent}
			onBackButtonPress={setSent.bind(null, false)}>

			<Loader
				isGlobal
				isPrimary
				isLoading={isExchangingToken || isPollsLoading} />

			<div className="page-login__head">
				<AuthLogo />
				<h1>{sent ? lang.authCheckMail : `${lang.authWelcomeHeader} ${config.name}`}</h1>
				{!!sent && <p className="page-login__sent-message">{lang.authCheckMailText(email)}</p>}
			</div>

			{!sent &&
			<>
				<Input
					type="email"
					value={email}
					label={lang.authLabelEmail}
					required
					autoFocus
					className="mb42"
					placeholder={lang.authSampleEmail}
					onChangeText={setEmail} />
				<Button
					type="submit"
					disabled={isLoading}
					isLoading={isUserLoading}
					className="mb8"
					children={lang.authContinueEmail} />
				<p className="page-login__or mb8">{lang.authOr}</p>
				<Button
					onClick={continueAsGuest}
					disabled={isLoading}
					isOutline
					isLoading={isGuestLoading}
					children={lang.authContinueGuest} />
				<p className="page-login__register-prompt">
					<span>{lang.authDontHaveAccount} </span>
					<Link to={getPath('register')} children={lang.authClickHereToRegister} />
				</p>
			</>
			}

		</AuthWrapper>
	);

}
