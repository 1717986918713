import cn from 'classnames';

import { lang } from '~/constants';
import { OrderType } from '~/core';

import { DropDown } from './DropDown';
import { Icon } from './Icon';

export interface RecencyFilterProps {
	value: OrderType,
	disabled?: boolean,
	onChange: (order: OrderType) => void,
}

const recencyLabelMap = {
	recent: lang.recencyRecent,
	oldest: lang.recencyOldest,
	alphabetical: lang.recencyAlphabetical,
} as const;

export const RecencyFilter: React.FC<RecencyFilterProps> = (props) => {

	const { value, disabled, onChange } = props;

	return (
		<div className={cn('app-recency-filter', { 'app-recency-filter_disbled': disabled })}>
			<Icon name="arrowSort" />
			<p>{recencyLabelMap[value]}</p>
			<DropDown
				icon="downChevron"
				disabled={disabled}
				items={[
					{
						onClick: () => onChange('recent'),
						children: lang.recencyRecent,
					},
					{
						onClick: () => onChange('oldest'),
						children: lang.recencyOldest,
					},
					{
						onClick: () => onChange('alphabetical'),
						children: lang.recencyAlphabetical,
					},
				]}
				isSmallIcon />
		</div>
	);

}
