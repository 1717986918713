import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useLocalStorage } from '@uidotdev/usehooks';

import { lang } from '~/constants';
import { optifyStrings, Option, tenant, Communication as C } from '~/core';
import { Button, InputC, Loader, SelectC } from '~/base/components';
import { errorHandler } from '~/base/services';

import {
	STAGES,
	STAGE_STORAGE_KEY,
	TENANT_DETAILS_KEY,
} from './details';

interface CreateWorkspaceInput {
	name: string,
	categories: Option[],
}

export const FormCreate: React.FC = () => {

	const [ , setStage ] = useLocalStorage<typeof STAGES[number]>(
		STAGE_STORAGE_KEY,
		STAGES[0]
	);

	const [ , setTenantDetailsJson ] = useLocalStorage(TENANT_DETAILS_KEY, '');

	const [ createTenant, { isLoading: isCreating } ] = tenant.useCreateTenantMutation();

	const {
		control,
		formState,
		setError,
		handleSubmit
	} = useForm<CreateWorkspaceInput>({
		mode: 'onChange',
	});

	const {
		categories,
		isLoading,
		isUninitialized,
	} = tenant.useGetCategoriesQuery(
		undefined,
		{
			selectFromResult: ({ data, ...result }) => ({
				categories: optifyStrings(data?.categories || []),
				...result,
			}),
		}
	);

	const onSubmit = useCallback(
		async (form: CreateWorkspaceInput) => {

			const input: C.CreateTenantInput = {
				tenant: form.name,
				categories: form.categories.map(({ label }) => label),
			}

			const result = await createTenant(input);

			if ('error' in result) {

				const { message, field } = errorHandler<CreateWorkspaceInput>(result.error, {
					101: [ lang.tenantCreationNameError, 'name' ],
				});

				field ?
					setError(field, { message, }) :
					alert(message);

				return;
			}

			const { name, invite_code: code } = result.data.tenant;

			setTenantDetailsJson(
				JSON.stringify({ name, code, })
			);

			setStage(STAGES[1]);

		},
		[ createTenant, setError ]
	);

	return (
		<>
			<Loader
				isGlobal
				isPrimary
				isLoading={isCreating} />
			<InputC
				name="name"
				label="Your company or team's name"
				rules={{ required: lang.registerFieldRequired }}
				control={control}
				placeholder="Workspace name" />
			<SelectC
				name="categories"
				label="Choose your content categories (set at least one)"
				rules={{ required: 'Set at least one category' }}
				control={control}
				options={categories}
				isLoading={isLoading || isUninitialized}
				isDisabled={isLoading || isUninitialized}
				placeholder="Choose from preset categories or type your own" />
			<Button
				children="Continue"
				onClick={handleSubmit(onSubmit)}
				disabled={!formState.isValid || isCreating} />
		</>
	);

}
