import { Image } from '../Image';
import { config } from '../../../constants';
import logo from '~/assets/img/logo2.png';

export const AuthLogo: React.FC = () => {

	return (
		<Image
			src={logo}
			alt={`${config.name} Logo`}
			className="page-login__logo" />
	);

}
