import { lang } from '~/constants';

import { ButtonProps } from '../Button';
import { TextButton } from '../TextButton';

export interface MoreButtonProps extends ButtonProps {
	hasMore?: boolean,
	isLoading?: boolean,
	showEmptyText?: boolean | string,
}

export const MoreButton: React.FC<MoreButtonProps> = (props) => {

	const { hasMore, isLoading, showEmptyText, ...args } = props;

	if (!hasMore) {

		if (showEmptyText) {

			const text = typeof showEmptyText === 'string' ?
				showEmptyText :
				lang.showMoreCatchUpOn;

			return <p children={text} className="feed-more-empty" />

		}

		return null;

	}

	return (
		<div className="app-more-button">
			<TextButton
				disabled={isLoading}
				children={lang[isLoading ? 'loadingMore' : 'showMore']}
				isPrimary
				{...args} />
		</div>
	);

}
