import {
	useSelector,
	useDispatch,
	TypedUseSelectorHook
} from 'react-redux';

import {
	fetchBaseQuery,
	BaseQueryFn,
	FetchArgs,
	FetchBaseQueryError
} from '@reduxjs/toolkit/query';

import { WithStatus } from '../types';
import { config } from '~/constants';
import { SessionAdapter } from '~/base';
import { store } from './store';

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();

const baseFetchQuery = fetchBaseQuery({
	baseUrl: config.apiURL,
	prepareHeaders: async (headers) => {

		const session_id = await SessionAdapter.get();

		if (session_id) {
			headers.append(config.tokenKey, session_id);
		}

		return headers;

	},
});

export const baseQuery: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError
> = async (...args) => {

	const result = await baseFetchQuery(...args);

	if ('error' in result) {
		return result;
	}

	const data = result.data as WithStatus;

	if (data.status_response &&
		!data.status_response?.status) {

		const {
			response_message,
			response_internal_code
		} = data.status_response;

		return {
			error: {
				data: response_message,
				status: response_internal_code,
			},
		};

	}

	delete data.status_response;

	return result;

}
