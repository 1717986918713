import { useCallback } from 'react';

import { useDiscover, FeedScope, Struct } from '~/core';
import { lang } from '~/constants';

import {
	Page,
	Section,
	CardCategory,
	CardSimplified,
} from '../components';

export const Discover: React.FC = () => {

	const { feed, more, channels, categories, loadMore, isLoadingMore, isUninitialized, isLoading } = useDiscover();

	const renderCategories = useCallback(
		(item: Struct.Category) => <CardCategory key={item.id} data={item} />,
		[]
	);

	const renderItems = useCallback(
		(item: FeedScope.Item) => <CardSimplified key={item.id} data={item} />,
		[]
	);

	return (
		<Page
			title={lang.titleDiscover}
			search
			container
			isContentLoading={isUninitialized || isLoading}>
			<Section
				data={channels}
				title={lang.discoverChannels}
				listType="horizontal-slider"
				renderItem={renderCategories} />
			<Section
				data={categories}
				title={lang.discoverCategories}
				listType="horizontal-slider"
				renderItem={renderCategories} />
			<Section
				data={feed}
				title={lang.discoverRecents}
				renderItem={renderItems}
				hasMore={more}
				isInfinite
				onMoreClick={loadMore}
				isLoadingMore={isLoadingMore} />
		</Page>
	);

}
