import { useRef, MouseEventHandler, useState } from 'react';
import Slider from 'react-slider';
import YouTube, { YouTubePlayer } from 'react-youtube';
import cn from 'classnames';
import ReactHlsPlayer from 'react-hls-player';

import { Media } from '~/core';
import { useMedia, useMediaYT } from '../services';

import { IconButton } from './IconButton';
import { PlayerButton } from './PlayerButton';
import { CardBackground, CardText } from './ui';

const stopPropagation: MouseEventHandler<HTMLDivElement> = (e) => {
	e.stopPropagation();
	e.preventDefault();
}

export interface VideoProps {
	title?: string,
	video: Media.Video | string,
	poster?: Media.Image,
	feedId: string,
}

export interface VideoSAProps extends VideoProps {
	video: Media.Video,
}

export const VideoSA: React.FC<VideoSAProps> = (props) => {

	const { title, video, poster, feedId } = props;

	const videoRef = useRef<HTMLVideoElement>(null);

	const {
		src,
		isHsl,
		isStarted,
		isPlaying,
		isMuted,
		percentValue,
		formattedValue: [ timePassed, timeLeft ],
		dimensions,
		callbacks,
		onSeekTo,
		togglePlaying,
		toggleMuted,
		openFullScreen,
	} = useMedia({
		ref: videoRef,
		url: typeof video === 'string' ? video : video?.url,
		feedId,
	});

	const className = cn(
		'app-video',
		{
			'app-video_started': isStarted,
			'app-video_no-poster': !poster,
			'app-video_use-ratio': !!dimensions.ratio,
		}
	);

	return (
		<div className={className}>

			<PlayerButton isBig onClick={togglePlaying} />

			{!!title &&
			<CardText title={title} isLower />
			}

			<div
				onClick={stopPropagation}
				className="app-video__bottom">
				<Slider
					value={percentValue}
					onChange={onSeekTo}
					className="app-video__slider app-slider" />
				<div className="app-video__controls">
					<div className="app-video__buttons">
						<IconButton
							icon={isPlaying ? 'pause' : 'play'}
							onClick={togglePlaying}
							isSmall />
						<IconButton
							icon={isMuted ? 'speakerMute' : 'speaker'}
							onClick={toggleMuted}
							isSmall />
						<div className="app-video__timing">
							<p>{timePassed}</p>
							<p>/ {timeLeft}</p>
						</div>
					</div>
					<div className="app-video__buttons">
						<IconButton
							icon="caption"
							isSmall
							disabled />
						<IconButton
							icon="fullScreen"
							onClick={openFullScreen}
							isSmall />
					</div>
				</div>
			</div>

			{isHsl ?
			<ReactHlsPlayer
				src={src}
				playerRef={videoRef}
				{...callbacks} /> :
			<video ref={videoRef} src={src} {...callbacks}>
				<source src={src} type='video/mp4' />
			</video>
			}

			{!!poster &&
			<CardBackground src={poster?.url} />
			}

			{!!dimensions.ratio &&
			<i className="ratio" style={{ paddingTop: `${Number(dimensions.ratio * 100)}%` }} />
			}

		</div>
	);

}

export interface VideoYTProps extends VideoProps {
	video: string,
}

export const VideoYT: React.FC<VideoYTProps> = (props) => {

	const { title, video, poster, feedId } = props;

	const [ player, setPlayer ] = useState<YouTubePlayer>();

	const {
		src: videoId,
		isStarted,
		togglePlaying,
	} = useMediaYT({
		video,
		player,
		feedId,
	});

	const className = cn(
		'app-video',
		'app-video_yt',
		{ 'app-video_started': isStarted }
	);

	return (
		<div className={className}>

			<PlayerButton isBig onClick={togglePlaying} />

			{!!title &&
			<CardText title={title} isLower />
			}

			{videoId &&
			<YouTube
				videoId={videoId}
				onReady={({ target }) => setPlayer(target)}
				className="app-video__element" />
			}

			{!!poster &&
			<CardBackground src={poster?.url} />
			}

		</div>
	);

}

export const Video: React.FC<VideoProps> = (props) => {

	const { video, ...args } = props;

	if (typeof video === 'string') {
		return <VideoYT video={video} {...args} />
	}

	return <VideoSA video={video} {...args} />

}
