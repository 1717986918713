import { forwardRef } from 'react';
import { lang } from '~/constants';
import { Input, InputProps } from '../Input';

export interface InputSearchProps extends InputProps {}

export const InputSearch = forwardRef<HTMLInputElement, InputSearchProps>((props, ref) => {

	const { ...args } = props;

	return (
		<Input
			icon="magnifier"
			isFilled
			placeholder={lang.searchPlaceholder}
			{...args}
			ref={ref} />
	);

});
