import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ContextState {
	isMenuOpen: boolean,
}

const initialState: ContextState = {
	isMenuOpen: false,
};

export const contextSlice = createSlice({
	name: 'context',
	reducers: {
		toggleMenu: (state, { payload }: PayloadAction<boolean>) => {
			state.isMenuOpen = payload;
		},
	},
	initialState,
});

export const { toggleMenu } = contextSlice.actions;
