import { useCallback } from 'react';

import { useNotifications, Struct } from '~/core';
import { lang } from '~/constants';

import {
	Page,
	Section,
	Empty,
	Notification,
} from '../components';

export const Notifications: React.FC = () => {

	const {
		data,
		hasMore,
		loadMore,
		isLoadingMore,
		isContentEmpty,
		isContentLoading,
	} = useNotifications();

	const renderItem = useCallback(
		(item: Struct.Notification) => <Notification key={item.id} data={item} />,
		[]
	);

	const EmptyComponent = (
		<Empty
			icon="emptySaved"
			text={lang.emptyNotifications} />
	);

	return (
		<Page
			title={lang.titleNotifications}
			container
			isContentLoading={isContentLoading}>
			<Section
				data={data}
				hasMore={hasMore}
				renderItem={renderItem}
				onMoreClick={loadMore}
				isLoadingMore={isLoadingMore}
				isContentEmpty={isContentEmpty}
				contentEmptyComponent={EmptyComponent} />
		</Page>
	);

}
